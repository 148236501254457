import {
  CMO_SHORTFALLS_ITEMS,
  CMO_ADD_SHORTFALLS,
  CMO_FETCH_GRNRECEIPTS,
  CMO_ADD_GRNRECEIPT,
  CMO_ADD_GRNCSV,
  QUICK_VIEW,
  ADD_GRN_FETCHTABLE,
  ADD_GRN_MODIFIED_FETCHTABLE,
  UPDATE_GRN_LINE_DATA,
  FETCH_UOM,
  APPROVE_GRN_DATA,
  CMO_LIST,
  UPLOAD_GRN_FILE,
  UPDATE_GR_RECORD,
  RECORD_TIMELINE,
  RECORD_RESOLUTION,
  EXPORT_TO_EXCEL,
  DOWNLOAD_GR_ZIP,
  DELETE_GR,
  QUANTITY_TOLERANCE_ALERT,
  OFFSET_ADD_GRN_FETCHTABLE
} from '../../actions/types';

const initialState = {
  quickView: {},
  updateGRNData: {},
  fetchGRNData: {},
  fetchSASLData: {},
  fetchUOMData: {},
  cmoList: {},
  recordTimeline: {},
  recordResolution: {},
  exportExcel: {},
  downloadZip: {},
  deleteGRRecord: {},
  quantityAlert: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CMO_SHORTFALLS_ITEMS:
      return {
        ...state,
        shortFallsReports: action.payload,
        shortFallsReportAdded: false,
      };
    case CMO_ADD_SHORTFALLS:
      return {
        ...state,
        shortFallsReportAdded: action.payload,
      };
    case CMO_FETCH_GRNRECEIPTS:
      return {
        ...state,
        grnReceiptsList: action.payload,
        grnReceiptAdded: false,
      };
    case CMO_ADD_GRNRECEIPT:
      return {
        ...state,
        grnReceiptAdded: action.payload,
      };
    case CMO_ADD_GRNCSV:
      return {
        ...state,
        grnCSVAdded: action.payload,
      };
    case QUICK_VIEW:
      return {
        ...state,
        quickView: action.payload,
      };
    case ADD_GRN_FETCHTABLE:
      return {
        ...state,
        fetchGRNData: action.payload,
      };
    case ADD_GRN_MODIFIED_FETCHTABLE:
      return {
        ...state,
        fetchGRNData: action.payload,
      }
    case OFFSET_ADD_GRN_FETCHTABLE:
      return {
        ...state,
        fetchGRNData: action.payload
      }
    case UPDATE_GRN_LINE_DATA:
      return {
        ...state,
        updateGRNData: action.payload,
        quantityAlert: false
      };
    case FETCH_UOM:
      return {
        ...state,
        fetchUOMData: action.payload,
      };
    case APPROVE_GRN_DATA:
      return {
        ...state,
        approveGRNData: action.payload,
      };
    case CMO_LIST:
      return {
        ...state,
        cmoList: action.payload,
      };
    case UPLOAD_GRN_FILE:
      return {
        ...state,
        uploadGRFile: action.payload,
      };
    case UPDATE_GR_RECORD:
      return {
        ...state,
        updateGRRecords: action.payload,
      };
    case RECORD_TIMELINE:
      return {
        ...state,
        recordTimeline: action.payload,
      };
    case RECORD_RESOLUTION:
      return {
        ...state,
        recordResolution: action.payload,
      };
    case EXPORT_TO_EXCEL:
      return {
        ...state,
        exportExcel: action.payload,
      };
    case DOWNLOAD_GR_ZIP:
      return {
        ...state,
        downloadZip: action.payload,
      };
    case DELETE_GR:
      return {
        ...state,
        deleteGR: action.payload,
      };
    case QUANTITY_TOLERANCE_ALERT:
      return {
        ...state,
        quantityAlert: true,
        // updateGRNData: action.payload,
      };

    default:
      return state;
  }
}
