export const isValid = (value) => {
  if (value === '' || value === null || value === 0) {
    return false;
  }
  return true;
};

export const compareValues = (value, lowerLimit, uperLimit) => {
  if (value < lowerLimit) {
    return { status: false, type: 'lower' };
  } else if (value > uperLimit) {
    return { status: false, type: 'upper' };
  }
  return { status: true };
};

export const cookieSettings = () => {
  let cookies = JSON.parse(localStorage.getItem('cookiesConsent'));

  if (cookies !== null) {
    return {
      cookiesConsent: cookies,
    };
  } else {
    return {
      cookiesConsent: null,
    };
  }
};
//Test validity of email, checking @ and . rules
export const isValidEmail = (email) => {
  let pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

  if (pattern.test(email) === true) {
    return true;
  } else {
    return false;
  }
};
export const isValidPhoneNumber = (phoneNumber, dialCode) => {
  //Remove dialcode from phone number to check validity
  let regExp = new RegExp(dialCode, 'i');
  let replacedPhoneNumber = phoneNumber.replace(regExp, '').trim();
  if (
    replacedPhoneNumber === '+' ||
    replacedPhoneNumber === '' ||
    replacedPhoneNumber === 'undefined'
  ) {
    return false;
  } else {
    return true;
  }
};
