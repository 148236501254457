import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
// import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import {
  getCollaboratorType,
  getCollaboratorRole,
} from '../../containers/actions/ecosystemActions';
import { connect } from 'react-redux';

const useStyles = {
  text: {
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
};

class CollaboratorDetails extends Component {
  state = {
    collabType: '',
    collaboratorType: [],
    collaboratorRole: [],
  };

  componentDidMount() {
    this.props.getCollaboratorType();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.collabType !== this.props.collabType &&
      this.props.collabType.status === true
    ) {
      this.setState({
        collaboratorType: this.props.collabType.data,
      });
    }

    if (
      prevProps.collabRole !== this.props.collabRole &&
      this.props.collabRole.status === true
    ) {
      this.setState({
        collaboratorRole: this.props.collabRole.data.filter(
          (role) => role.collaboratorRoleName === 'Admin'
        ),
      });
    }
  }

  handleChange = (e) => {
    // filter Id and call roles API
    this.state.collaboratorType.forEach((item) => {
      if (item.collaboratorTypeName === e.target.value) {
        const data = {
          collaboratorTypeId: item.collaboratorTypeId,
          collaboratorTypeName: item.collaboratorTypeName,
          collaboratorRoleId: '',
          collaboratorRoleName: '',
        };
        this.props.handleChangeColloborator(data);
        this.props.getCollaboratorRole(data);
      }
    });
  };

  handleChangeRole = (e) => {
    this.state.collaboratorRole.forEach((item) => {
      if (item.collaboratorRoleName === e.target.value) {
        const data = {
          collaboratorRoleId: item.collaboratorRoleId,
          collaboratorRoleName: item.collaboratorRoleName,
        };
        this.props.handleChangeColloborator(data);
      }
    });
  };

  render() {
    const {
      classes,
      collaboratorTypeName,
      collaboratorTypeId,
      collaboratorRoleName,
      collaboratorRoleId,
      errors,
    } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.text}>
            Collaborator &#38; Role details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            variant='outlined'
            size='small'
            name='collaboratorTypeName'
            onChange={this.handleChange}
            value={collaboratorTypeName}
            label='Collaborator Type'
            error={errors.collaboratorTypeName ? true : false}
            helperText={
              errors.collaboratorTypeName ? (
                errors.collaboratorTypeName
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Select Collaborator Type
                </Typography>
              )
            }
          >
            {
              this.state.collaboratorType.length === 0 ?
             <MenuItem disabled>
              Select
           </MenuItem>
              :
              this.state.collaboratorType.map((collaboratorType, index) => (
              <MenuItem
                value={collaboratorType.collaboratorTypeName}
                key={index}
              >
                {collaboratorType.collaboratorTypeName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            disabled
            variant='outlined'
            size='small'
            name='collaboratorTypeId'
            onChange={this.handleChange}
            value={collaboratorTypeId}
            label='Collaborator Type ID'
            error={errors.collaboratorTypeId ? true : false}
            helperText={
              errors.collaboratorTypeId ? (
                errors.collaboratorTypeId
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Collaborator Type
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            variant='outlined'
            size='small'
            name='collaboratorRoleName'
            onChange={this.handleChangeRole}
            disabled={collaboratorTypeName !== '' ? false : true}
            value={collaboratorRoleName}
            label='Collaborator Role'
            error={errors.collaboratorRoleName ? true : false}
            helperText={
              errors.collaboratorRoleName ? (
                errors.collaboratorRoleName
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Select Collaborator Role
                </Typography>
              )
            }
          >
            {this.state.collaboratorRole.map((collaboratorRole, index) => (
              <MenuItem
                value={collaboratorRole.collaboratorRoleName}
                key={index}
              >
                {collaboratorRole.collaboratorRoleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            disabled
            variant='outlined'
            size='small'
            name='collaboratorRoleId'
            onChange={this.handleChangeRole}
            value={collaboratorRoleId}
            label='Collaborator Role ID'
            error={errors.collaboratorRoleId ? true : false}
            helperText={
              errors.collaboratorRoleId ? (
                errors.collaboratorRoleId
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Collaborator Role
                </Typography>
              )
            }
          />
        </Grid>
      </Grid>
    );
  }
}

// getting access to state through redux
const mapStateToProps = (state) => ({
  collabType: state.ecosystems.collaboratorType,
  collabRole: state.ecosystems.collaboratorRole,
});

export default connect(mapStateToProps, {
  getCollaboratorType,
  getCollaboratorRole,
})(withStyles(useStyles)(CollaboratorDetails));
