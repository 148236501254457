import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
// import BatchStatus from '../models/BatchStatus';

import { useStyles } from '../../supplierPoGrn/styles';
//Import custom table cell
import CustomTableCell from '../../models/table/CustomTableCell';
import {
  presentFormatedData,
  presentDate,
} from '../../functionalComponents/dataFormatFunctions';
import ViewBatchesScreen from '../common/drawer';
import ViewConsumptionInfo from './ConsumptionDetails/index';
import StatusManager from './StatusManager';
import StatusTextDispaly from '../../models/status/DeliveryCompleted';
import getColor from '../models/status/statusColors';
import { useHistory } from 'react-router';
import EventNoteIcon from '@material-ui/icons/EventNote';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Dialog } from '@material-ui/core';
// import ShowFiles from '../../models/files/ShowFiles';
import { downloadCustomUploadedFile } from '../../../../containers/actions/common/fileActions';
import getQAStatusColor from '../models/status/qaStatusColors';
import DownloadFilesDialogBox from './DownloadFilesDialogBox';

function SingleRow(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // schrockenSLBatchID,
    batchSize,
    batchStartDate,
    batchEndDate,
    batchUOM,
    fgMaterialCode,
    fgMaterialName,
    // scheduleLineItemNumber,
    // producedQuantity,
    // pendingQuantity,
    readyForDispatchQuantity,
    BOGeneratedGRNNumber,
    // statusDescription,
    // statusID,
    statusDisplayName,
    SASLBatchStatusID,
    vendorBatchNumber,
    schrockenDeliveryScheduleBatchID,
    bomConsumptionAddedIndicator,
    rejectionText,
    rejectedBy,
    scheduleAgreementNumber,
    Yield,
    coaFiles,
    eInvoiceFiles,
    eWayBillFiles,
    customFiles,
    qaStatusID,
    qaStatusText,
    qaReasonForChangeInReleaseDecision,
    qaReleaseDecisionBy,
    qaRejectionReason,
    qaStatusUpdatedBy,
    vendorMosymphonyID,
    brandOwnerMosymphonyID,
    sendReminderForCOAUpload,
    scheduleLineItemNumber,
  } = props.row;

  const { schrockenDeliveryScheduleID, batchDashboard } = props;

  const [downloadBox, setDownloadBox] = useState(false);

  const featuresAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.featuresAccess.deliverySchedulesFeatures
  );

  const qualityApprovalFlowFlag = useSelector((state) => {
    return state.pcmp.common.reports.rmpmGrnFeatures?.data?.features
      .qualityApprovalEnabled;
  });

  const openScheduleLineDetails = () => {
    history.push(
      `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/sasl/${schrockenDeliveryScheduleID}`,
      { batchDashboard: batchDashboard }
    );
  };

  const openDownloadDialogBox = () => {
    setDownloadBox(true);
  };

  const handleDownloadDialog = () => {
    setDownloadBox(false);
  };

  const handleDownloadFile = (schrockenCustomFileID) => {
    const data = {
      schrockenCustomFileID,
    };
    dispatch(downloadCustomUploadedFile(data));
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{
          backgroundColor: props.keys % 2 === 0 ? '#FFF' : '#0000000a',
        }}
        // key={GRDetails[0].schrockenSupplierPOBatchID}
      >
        {/* <TableCell
          style={{
            width: '1%',
            maxWidth: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton aria-label='expand row' size='small' />
        </TableCell> */}

        <CustomTableCell align='left'>
          {presentFormatedData(vendorBatchNumber)}
        </CustomTableCell>

        <CustomTableCell align='left'>
          {presentFormatedData(fgMaterialCode)}
          {'/'}
          <br />
          {presentFormatedData(fgMaterialName)}
        </CustomTableCell>

        {batchDashboard && (
          <CustomTableCell align='center'>
            {presentFormatedData(scheduleAgreementNumber)}
          </CustomTableCell>
        )}
        {batchDashboard && (
          <CustomTableCell align='right'>
            {presentFormatedData(batchUOM)}
          </CustomTableCell>
        )}

        <CustomTableCell align='right'>
          {presentFormatedData(batchSize)}
        </CustomTableCell>
        {/* <CustomTableCell align='right'>
          {presentFormatedData(producedQuantity)}
        </CustomTableCell>
        <CustomTableCell align='right'>
          {presentFormatedData(pendingQuantity)}
        </CustomTableCell> */}
        <CustomTableCell
          align={readyForDispatchQuantity !== undefined ? 'right' : 'center'}
        >
          {presentFormatedData(readyForDispatchQuantity)}
        </CustomTableCell>

        {batchDashboard && (
          <CustomTableCell
            align={readyForDispatchQuantity !== undefined ? 'right' : 'center'}
          >
            {presentFormatedData(Yield)}
          </CustomTableCell>
        )}
        <CustomTableCell align='center'>
          {presentDate(batchStartDate)}
        </CustomTableCell>
        <CustomTableCell align='center'>
          {presentDate(batchEndDate)}
        </CustomTableCell>
        <CustomTableCell align='center'>
          {presentFormatedData(BOGeneratedGRNNumber)}
        </CustomTableCell>
        <CustomTableCell>
          <StatusTextDispaly
            statusText={statusDisplayName}
            bgColor={getColor(SASLBatchStatusID)}
          />
        </CustomTableCell>
        {qualityApprovalFlowFlag && (
          <CustomTableCell>
            <StatusTextDispaly
              statusText={qaStatusText ? qaStatusText : '-NA-'}
              bgColor={getQAStatusColor(qaStatusID)}
            />
          </CustomTableCell>
        )}
        <CustomTableCell className={classes.changingWidth} align='left'>
          <div className={classes.actions}>
            <div>
              {StatusManager(
                schrockenDeliveryScheduleID,
                schrockenDeliveryScheduleBatchID,
                SASLBatchStatusID,
                statusDisplayName,
                rejectionText,
                rejectedBy,
                featuresAccess,
                batchDashboard,
                qualityApprovalFlowFlag,
                qaStatusID,
                qaStatusText,
                qaReasonForChangeInReleaseDecision,
                qaReleaseDecisionBy,
                qaRejectionReason,
                qaStatusUpdatedBy,
                vendorMosymphonyID,
                brandOwnerMosymphonyID,
                sendReminderForCOAUpload,
                scheduleLineItemNumber,
                scheduleAgreementNumber,
                vendorBatchNumber,
                fgMaterialCode,
                fgMaterialName
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {featuresAccess?.viewFGBatch && (
                <ViewBatchesScreen
                  className={classes.multipleLogo}
                  schrockenDeliveryScheduleBatchID={
                    schrockenDeliveryScheduleBatchID
                  }
                  qualityApprovalFlowFlag={qualityApprovalFlowFlag}
                />
              )}
              {featuresAccess?.viewFGConsumption && (
                <ViewConsumptionInfo
                  className={classes.multipleLogo}
                  schrockenDeliveryScheduleBatchID={
                    schrockenDeliveryScheduleBatchID
                  }
                  schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
                  bomConsumptionAddedIndicator={bomConsumptionAddedIndicator}
                />
              )}
              {batchDashboard && (
                <EventNoteIcon
                  fontSize='small'
                  className={classes.multipleLogo}
                  onClick={openScheduleLineDetails}
                />
              )}
              {qualityApprovalFlowFlag && (
                <GetAppIcon
                  titleAccess='Download CoA'
                  fontSize='small'
                  className={
                    coaFiles.length !== 0 ||
                    eInvoiceFiles.length !== 0 ||
                    eWayBillFiles.length !== 0 ||
                    customFiles.length !== 0
                      ? classes.multipleLogo
                      : classes.multipleLogoDisable
                  } // Spira Id: 2572 (Enable download when files are present.)
                  onClick={
                    coaFiles.length !== 0 ||
                    eInvoiceFiles.length !== 0 ||
                    eWayBillFiles.length !== 0 ||
                    customFiles.length !== 0
                      ? openDownloadDialogBox
                      : null
                  } // Spira Id: 2572 (one dialog box to download files when files are present.)
                />
              )}
            </div>
          </div>
        </CustomTableCell>
      </TableRow>
      <Dialog
        open={downloadBox}
        onClose={handleDownloadDialog}
        fullWidth
        maxWidth={'md'}
      >
        <DownloadFilesDialogBox
          handleDownloadDialog={handleDownloadDialog}
          handleDownloadFile={handleDownloadFile}
          coaFiles={coaFiles}
          eInvoiceFiles={eInvoiceFiles}
          eWayBillFiles={eWayBillFiles}
          customFiles={customFiles}
          qualityApprovalFlowFlag={qualityApprovalFlowFlag}
        />
        {/* <DialogContent>
          <Grid container justifyContent='flex-end'>
            <Grid item sm={6}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  fontWeight: 500,
                }}
              >
                Download CoA File(s)
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography
                className={classes.closeText}
                variant='body2'
                onClick={handleDownloadDialog}
              >
                Close <CloseIcon fontSize='medium' />
              </Typography>
            </Grid>
          </Grid>
          {coaFiles.length > 0 && (
            <React.Fragment>
              <Grid
                container
                style={{ justifyContent: 'center', padding: '2rem' }}
              >
                {coaFiles.length > 0
                  ? coaFiles.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant={'body2'}
                          // className={classes.link}
                          style={{
                            color: 'blue',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            padding: '5px 0px',
                          }}
                          onClick={() =>
                            handleDownloadFile(file.schrockenCustomFileID)
                          }
                        >
                          {file.fileName || file.name || file.customFileName}
                        </Typography>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </React.Fragment>
          )}
        </DialogContent> */}
      </Dialog>
    </React.Fragment>
  );
}
export default SingleRow;
