import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useEffect } from 'react';
import StyledButton from './models/Button';

import StyledSwitch from './models/Switch';

const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    postion: 'relative',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  titleText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
}));

const CookieDrawer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [analyticsToggle, setAnalyticsToggle] = useState(false);
  const [hasCookieSettings, setHasCookieSettings] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setAnalyticsToggle(!analyticsToggle);
  };
  const handleCookies = () => {
    props.setCookies(analyticsToggle);
    setOpen(false);
  };

  useEffect(() => {
    if (props.hasCookieSettings) {
      setHasCookieSettings(props.hasCookieSettings);
    }
    if (props.cookiesConsent === null) {
      setOpen(true);
    } else {
      setAnalyticsToggle(props.cookiesConsent);
    }
  }, [props.hasCookieSettings, props.cookiesConsent]);

  return (
    <React.Fragment>
      {hasCookieSettings && (
        <Grid
          item
          xs={12}
          style={{ position: 'fixed', left: 0, bottom: 0, zIndex: 1 }}
        >
          <Button
            size='medium'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            style={{ padding: 0 }}
          >
            <Box
              component='span'
              p={1}
              style={{ border: 'solid 1px black', backgroundColor: 'black' }}
            >
              <i className='fas fa-cog' style={{ color: 'white' }}></i>
            </Box>
            <Box component='span' p={1} style={{ border: 'solid 1px black' }}>
              COOKIE SETTINGS
            </Box>
          </Button>
        </Grid>
      )}
      <Drawer
        className={classes.drawer}
        // variant="persistent"
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid container justify='center'>
          <Grid
            item
            xs={10}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '2rem',
            }}
          >
            <Typography gutterBottom variant='h6' className={classes.titleText}>
              Our use of Cookies
            </Typography>

            <Typography variant='body2' style={{ paddingBottom: '1.5rem' }}>
              We use necessary cookies to make our site work. We would also like
              to set optional analytics cookies to help us improve it. We won’t
              set optional cookies unless you enable them. Using this tool will
              set a cookie on your device to remember your preferences. For more
              detailed information about the cookies we use, see our&nbsp;
              <span>
                <a
                  href='https://www.schrocken.com/cookie-policy'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#e36e39',
                  }}
                >
                  Cookies Page
                </a>
              </span>
              &nbsp;(link open in new tab)
            </Typography>

            <Typography variant='h6' className={classes.titleText}>
              Necessary Cookies
            </Typography>

            <Typography variant='body2' style={{ padding: '0.75rem 0' }}>
              Necessary cookies enable core functionality such as security,
              network management, and accessibility. You may disable these by
              changing your browser settings, but this may affect how the
              website functions.
            </Typography>

            <Typography variant='h6' className={classes.titleText}>
              Analytics Cookies
            </Typography>

            <Grid
              container
              style={{
                padding: '0.75rem 0',
                alignItems: 'center',
              }}
            >
              <Grid item xs={8}>
                <Typography variant='body2'>Analytics Cookie Toggle</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {/** SWitch was inside Typography? */}
                <StyledSwitch
                  checked={analyticsToggle}
                  onChange={handleChange}
                  color='primary'
                  name='checked'
                />
              </Grid>
            </Grid>
            <Typography variant='body2' style={{ padding: '0.75rem 0' }}>
              We’d like to set Google Analytics cookies to help us to improve
              our website by collecting and reporting information on how you use
              it. The cookies collect information in a way that does not
              directly identify anyone. For more information on how these
              cookies work, please see our ‘Cookies page’.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <br />
            <StyledButton
              onClick={handleCookies}
              size='small'
              style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
            >
              Save and Continue
            </StyledButton>
            <br />
            <br />
            <br />
            <br />
          </Grid>
          {props.cookiesConsent !== null && (
            <Grid item xs={12}>
              <Button
                size='small'
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerClose}
                edge='start'
                style={{ position: 'fixed', bottom: 0 }}
              >
                <Box
                  component='span'
                  p={1}
                  style={{
                    border: 'solid 1px #FF0000',
                    background: '#FF0000 0% 0% no-repeat padding-box',
                    textAlign: 'center',
                  }}
                >
                  <i className='fas fa-cog' style={{ color: 'white' }}></i>
                </Box>
                <Box
                  component='span'
                  p={1}
                  style={{ border: 'solid 1px #FF0000' }}
                >
                  CLOSE COOKIE SETTINGS
                </Box>
              </Button>
            </Grid>
          )}
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};

export default CookieDrawer;
