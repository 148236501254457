import React from 'react';
import ContactAdminCard from './ContactAdminCard';
import MediaHeader from '../common/openPages/openPageHeaderwithMedia';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CaptchaSiteKey } from '../../utils/config';

const ContactAdmin = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={CaptchaSiteKey}>
      <MediaHeader>
        <ContactAdminCard />
      </MediaHeader>
    </GoogleReCaptchaProvider>
  );
};

export default ContactAdmin;
