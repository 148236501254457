import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const useStyles = {
  text: {
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
};

class UserDetails extends Component {
  state = {
    email: '',
    fullName: '',
  };

  render() {
    const {
      classes,
      cmsInvite,
      email,
      fullName,
      phoneNumber,
      country,
      changePhoneNumber,
      handleChangeEmailandName,
      errors,
    } = this.props;
    return (
      <Grid container spacing={2}>
        {cmsInvite && (
          <Grid item xs={12}>
            <Typography variant='body2' className={classes.text}>
              Admin Details
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            type='email'
            required
            autoComplete='off'
            name='email'
            onChange={handleChangeEmailandName}
            value={email}
            label='Email Address'
            error={errors.email ? true : false}
            helperText={
              errors.email ? (
                errors.email
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Enter valid Email address to which Invitation will be sent.
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            name='fullName'
            autoComplete='off'
            onChange={handleChangeEmailandName}
            value={fullName}
            label='Full Name'
            error={errors.fullName ? true : false}
            helperText={
              errors.fullName ? (
                errors.fullName
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Full Name of the Person
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            country={country ? country : 'in'}
            value={phoneNumber}
            onChange={(phone, country) => changePhoneNumber(phone, country)}
            // enableSearch
            defaultErrorMessage='error'
            // isValid={false}
            inputStyle={{
              width: 'inherit',
              height: '2.5rem',
            }}
          />
          <Typography
            variant='caption'
            // color={changePhoneNumber ? 'error' : 'inherit'}
            className={classes.helperTextFontSize}
            style={{
              margin: errors.phoneNumber ? '4px 0 4px 0' : '4px 14px 0 14px',
              color: errors.phoneNumber ? '#f44336' : '#0000008a',
            }}
          >
            {errors.phoneNumber
              ? errors.phoneNumber
              : 'Enter user Mobile Number'}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(UserDetails);
