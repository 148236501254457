import {
  //   CMO_UPLOAD_QC_FILE,
  //   CMO_UPLOAD_INVOICE_FILE,
  CMO_DOWNLOAD_GRN_FILE
} from "../../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case CMO_DOWNLOAD_GRN_FILE:
      return {
        ...state,
        grnDownloaded: action.payload
      };

    default:
      return state;
  }
}
