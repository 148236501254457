import React, { Component } from 'react';
import { connect } from 'react-redux';

import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import StyledButton from './models/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
// Icons
import CloseIcon from '@material-ui/icons/Close';
//action
import { isValid } from './helperFunctions/helper';
import { addOrganization } from '../../containers/actions/ecosystemActions';
import PropTypes from 'prop-types';

const useStyles = {
  closeText: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
  },
  gridAlign: {
    padding: '1rem 2rem 1rem 2rem',
  },
  paddingGrid: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  headerText: {
    textAlign: 'center',
    fontWeight: 500,
  },
  desText: {
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
};

class AddOrganization extends Component {
  state = {
    organizationName: '',
    LLCode: null,
    TPCode: null,
    responseMessage: null,
    disableAddOrg: false,
    errors: {},
  };

  // recieving the state from reducer as props
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.addOrg !== this.props.addOrg &&
      this.props.addOrg.status === true
    ) {
      this.props.handleClose();
      this.props.handleOpenAddedDialog({
        organizationName: this.state.organizationName.trim(),
        LLCode: this.state.LLCode,
        TPCode: this.state.TPCode,
      });
    }
    if (
      prevProps.addOrg !== this.props.addOrg &&
      this.props.addOrg.status === false
    ) {
      this.setState({
        disableAddOrg: false,
        errors: {
          ...this.props.addOrg.errorDescription,
        },
      });
    }
  }

  validate = () => {
    let error = false;
    const { organizationName, LLCode, TPCode } = this.state;
    if (organizationName === '') {
      error = true;
      this.setState({
        errors: {
          organizationName: 'Please enter Organization name',
        },
      });
    }
    // conditions for CMO organization invite
    else if (
      !this.props.brandOwnerOrganization &&
      !isValid(LLCode) &&
      !isValid(TPCode)
    ) {
      error = true;
      this.setState({
        errors: {
          LLCode: 'Please enter vendor code',
          TPCode: 'Please enter vendor code',
        },
      });
    } else if (LLCode === '') {
      this.setState({
        LLCode: null,
      });
    } else if (TPCode === '') {
      this.setState({
        TPCode: null,
      });
    }
    return error;
  };

  addOrganisation = (event) => {
    //stop default action
    event.preventDefault();
    // stop propagation
    event.stopPropagation();

    // there should be a validation function here
    let isError = this.validate();
    if (!isError) {
      let { organizationName, LLCode, TPCode } = this.state;

      let organizationData = {
        organizationName: organizationName.trim(),
        LLCode: LLCode !== null && LLCode.trim() === '' ? null : LLCode,
        TPCode: TPCode !== null && TPCode.trim() === '' ? null : TPCode,
      };
      this.props.addOrganization(organizationData);
      this.setState({ disableAddOrg: true });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errors: {},
    });
  };

  render() {
    const { classes, handleClose, brandOwnerOrganization } = this.props;
    const { disableAddOrg, errors } = this.state;
    return (
      <React.Fragment>
        <DialogContent className={classes.paddingGrid}>
          <Typography
            variant='body2'
            className={classes.closeText}
            onClick={handleClose}
          >
            Close <CloseIcon fontSize='large' />
          </Typography>

          <form onSubmit={this.addOrganisation}>
            <Grid container className={classes.gridAlign}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant='h5'
                  className={`${classes.headerText} ${classes.paddingGrid}`}
                >
                  Add New Organization
                </Typography>

                <Typography
                  variant='body2'
                  className={`${classes.desText} ${classes.paddingGrid}`}
                >
                  Please enter details of the new Organization.
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} className={classes.paddingGrid}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  size='small'
                  id='Org_name'
                  name='organizationName'
                  label='Organization Name'
                  error={errors.organizationName ? true : false}
                  helperText={
                    errors.organizationName
                      ? errors.organizationName
                      : 'Enter full name of the Organization'
                  }
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12} className={classes.paddingGrid}>
                <Typography variant='subtitle1' className={classes.headerText}>
                  {brandOwnerOrganization
                    ? 'Other Information (optional)'
                    : 'Enter one of the codes to uniquely identify this Organization'}
                </Typography>
              </Grid>

              <Grid container spacing={3} className={classes.paddingGrid}>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    size='small'
                    id='LL_Code'
                    name='LLCode'
                    error={errors.LLCode ? true : false}
                    label='Loan Licensing Code (LL) '
                    helperText={
                      errors.LLCode ? errors.LLCode : 'Enter LL Vendor Code'
                    }
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={8} md={6}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    size='small'
                    id='TP_Code'
                    name='TPCode'
                    error={errors.TPCode ? true : false}
                    label='Third Party Code (TP) '
                    helperText={
                      errors.TPCode ? errors.TPCode : 'Enter TP Vendor Code'
                    }
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container justify='center' className={classes.paddingGrid}>
                <Grid item md={5}>
                  <StyledButton
                    onClick={this.addOrganisation}
                    type='submit'
                    disabled={disableAddOrg}
                  >
                    Save &#38; Continue
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </React.Fragment>
    );
  }
}

AddOrganization.propTypes = {
  addOrganization: PropTypes.func.isRequired,
};

// getting access to state through redux
const mapStateToProps = (state) => ({
  addOrg: state.ecosystems.addOrg,
});

export default connect(mapStateToProps, { addOrganization })(
  withStyles(useStyles)(AddOrganization)
);
