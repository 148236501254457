import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmoMembersList } from '../../../../containers/actions/pharmaCo/purchaseOrderActions';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './saslStyles';

const CMOFilter = (props) => {
  const { handleSelectCMO, CMOMember, filterByCMOName } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  // let cmoMembers = useSelector((state) =>
  //   state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
  //     ? state.pcmp.pharmaCo.purchaseOrder.cmoMembersList
  //     : []
  // );

  let cmoMemberWithId = useSelector((state) =>
    state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      ? state.pcmp.pharmaCo.purchaseOrder.cmoMemberWithId
      : []
  );

  useEffect(() => {
    // dispatch(
    //   cmoMembersList({
    //     collabType: 'Pharma Co',
    //     roleName: 'Admin',
    //     organizationName: 'Lupin',
    //     ecosystemId: 1,
    //   })
    // );
    let { collabType, ecosystemId, organizationName, roleName } = localStorage;
    dispatch(
      cmoMembersList({
        collabType: collabType,
        roleName: roleName,
        organizationName: organizationName,
        ecosystemId: ecosystemId,
      })
    );
  }, [dispatch]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      {/* <InputLabel id='demo-simple-select-label'>CMO</InputLabel> */}
      <Select
        labelId='cmo-filter'
        id='cmo-filter-id'
        value={CMOMember}
        onChange={handleSelectCMO}
        variant='outlined'
        classes={{
          outlined: classes.outlined,
        }}
      >
        <MenuItem value='all'>All CMOs</MenuItem>
        {cmoMemberWithId.map((cmoMember, i) => (
          <MenuItem
            key={i}
            value={
              filterByCMOName
                ? cmoMember.organization_name
                : cmoMember.organization_id
            }
            disabled={
              cmoMember.organization_id === null ||
              cmoMember.organization_name === null
                ? true
                : false
            }
          >
            {cmoMember.organization_name}
            {cmoMember.vendorPlant && ' - ' + cmoMember.vendorPlant}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CMOFilter;
