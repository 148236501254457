import React, { Component } from 'react';
import ErrorPage404Card from './ErrorPage404Card';
import OpenPageMediaHeader from '../common/openPages/HeaderFooterwithCookies';
import Grid from '@material-ui/core/Grid';
class ErrorPage404 extends Component {
  render() {
    return (
      <OpenPageMediaHeader>
        <Grid
          container
          justify='center'
        >
          <ErrorPage404Card />
        </Grid>
      </OpenPageMediaHeader>
    );
  }
}

export default ErrorPage404;
