import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
//components
import SettingsInputTextField from './SettingsInputTextField';

const useStyles = makeStyles({
  gridTop: {
    marginTop: '1rem',
  },
  headerText: {
    fontSize: '1rem',
  },
  gridBottom: {
    padding: '0.75rem 0',
  },
  helperTextFontSize: {
    fontSize: '0.75rem',
  },
  textFieldMinWidth: {
    minWidth: '14rem',
  },
});

const TwoStepAuthSettings = (props) => {
  const classes = useStyles();
  const {
    defaultTwoStepAuthentication,
    maxOTPAttemptsAllowed,
    handleChange,
    handleChangeAuthProcess,
    errors,
  } = props;
  return (
    <Grid item xs={12} className={classes.gridTop}>
      <Typography
        variant='h6'
        className={`${classes.headerText} ${classes.gridBottom}`}
      >
        2-Step Authentication Settings
      </Typography>

      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>Default 2-Step Authentication</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <TextField
            variant='outlined'
            size='small'
            select
            name='defaultTwoStepAuthentication'
            id='settings_defaultTwoStepAuthentication'
            value={defaultTwoStepAuthentication}
            onChange={handleChangeAuthProcess}
            className={classes.textFieldMinWidth}
            label='Default 2-Step Authentication'
            helperText={
              <Typography
                variant='caption'
                className={classes.helperTextFontSize}
              >
                {props.error
                  ? `${props.error}`
                  : 'Select default 2-step authentication'}
              </Typography>
            }
          >
            <MenuItem value={undefined} disabled>
              Select Default 2-Step Authentication
            </MenuItem>
            <MenuItem value='Mobile Number'>Mobile Number</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>
            Number of Verification Attempts
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='maxOTPAttemptsAllowed'
            id='Settings_maxOTPAttemptsAllowed'
            obj='twoStepAuthentication'
            value={maxOTPAttemptsAllowed}
            minValue={1}
            maxValue={99}
            handleChange={handleChange}
            label='No. of OTP attempts'
            description='Enter number of OTP attempts'
            error={errors.maxOTPAttemptsAllowed}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TwoStepAuthSettings;
