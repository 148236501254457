import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

//password strength
import zxcvbn from 'zxcvbn';
import { isValid } from '../../../common/helperFunctions/helper';

import {
  getResetPasswordTokenDetails,
  userConfirmResetPassword,
} from '../../../../containers/actions/userActions';
import StyledButton from '../../../common/models/Button';

const Styles = (theme) => ({
  backgroundGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
  bottomgrid: {
    padding: '2rem 0',
  },
  textPadding: {
    padding: '0.75rem 0',
  },
  root: {
    backgroundColor: '#F8F8F8',
    border: '2px solid #E2E2E2',
    padding: '1rem',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  strength: {
    border: '1px solid #E2E2E2',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
});
class ResetConfirmPassword extends Component {
  state = {
    loading: true,
    passwordCriteria: {},
    showPassword: false,
    showConfirmPassword: false,
    password: '',
    confirmPassword: '',
    errors: {},
    disableReset: false,
  };

  componentDidMount() {
    const { resetPasswordToken } = this.props.match.params;
    this.props.getResetPasswordTokenDetails(
      { resetPasswordToken },
      this.props.history
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.resetPasswordTokenDetails !==
      this.props.resetPasswordTokenDetails &&
      this.props.resetPasswordTokenDetails.status === true
    ) {
      this.setState({
        maskedEmailAddress: this.props.resetPasswordTokenDetails.data
          .maskedEmailAddress,
        passwordCriteria: this.props.resetPasswordTokenDetails.data
          .passwordCriteria,
        loading: false,
      });
    }
    if (
      prevProps.resetPassword !== this.props.resetPassword &&
      this.props.resetPassword.status === false
    ) {
      this.setState({
        errors: {
          passwordCriteria:
            this.props.resetPassword.errorDescription.isDeactivatedUser ? this.props.resetPassword.errorDescription.isDeactivatedUser : 'Password criteria does not match. Please check the password criteria given below',
        },
        disableReset: false,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      errors: {},
    });
  };

  // to show the visibility icon
  handleClickPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  //validate details
  validate = () => {
    let error = false;
    let errors = {};
    const { password, confirmPassword } = this.state;
    if (!isValid(password)) {
      error = true;
      errors.password = 'Please enter password';
    } else if (!isValid(confirmPassword)) {
      error = true;
      errors.confirmPassword = 'Please confirm password';
    } else if (password !== confirmPassword) {
      error = true;
      errors.password = 'Passwords do not match';
      errors.confirmPassword = 'Passwords do not match';
    }
    this.setState({ errors });
    return error;
  };

  // confirm reset password
  handleResetPassword = (event) => {
    event.preventDefault();
    let isError = this.validate();
    if (!isError) {
      const { resetPasswordToken } = this.props.match.params;
      const { password, confirmPassword } = this.state;
      const data = {
        password,
        confirmPassword,
        resetPasswordToken,
      };
      this.props.userConfirmResetPassword(data, this.props.history);
      this.setState({ disableReset: true });
    }
  };

  //disable copy paste
  onCopyPaste = (event) => {
    event.preventDefault();
    return false;
  };

  render() {
    const { classes, passwordExpired } = this.props;
    const {
      loading,
      maskedEmailAddress,
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      passwordCriteria,
      errors,
      disableReset,
    } = this.state;

    const passwordStrength = zxcvbn(password).score;

    const PasswordStrengthMeter = (passwordStrength) => {
      switch (passwordStrength) {
        case 0:
        case 1:
          return (
            <span
              //mention styles in makestyles
              className={classes.span}
              style={{ color: 'red' }}
            >
              Weak Password
            </span>
          );
        case 2:
        case 3:
          return (
            <span className={classes.span} style={{ color: '#aaad04' }}>
              Good
            </span>
          );
        case 4:
          return (
            <span className={classes.span} style={{ color: 'green' }}>
              Very Good
            </span>
          );
        default:
          return <span className={classes.span}>--</span>;
      }
    };
    return (
      <Grid style={{ backgroundColor: '#F2F2F2' }}>
        {loading === true ? (
          <div style={{ textAlign: 'center', marginTop: '25%' }}>
            <CircularProgress color='primary' thickness={7} />
          </div>
        ) : (
          <Grid container justify='center'>
            <Grid item xs={11} sm={9} md={7} className={classes.backgroundGrid}>
              <Grid container justify='center'>
                <Grid item xs={11} md={10}>
                  {/**This Grid acts as wrapper */}
                  <Grid container direction='column' justify='center'>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'center', marginTop: '1.5rem' }}
                    >
                      <Typography variant='h4' className={classes.textPadding}>
                        {passwordExpired
                          ? 'Your password has expired'
                          : 'Reset your password'}
                      </Typography>
                      <Typography
                        variant='body1'
                        style={{ paddingBottom: '2rem' }}
                      >
                        {passwordExpired
                          ? 'Your account password has expired. For security please create a new password for your Mosymphony account to login.'
                          : 'You are about to reset your Mosymphony account password'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.root}>
                      <Typography variant='subtitle1'>
                        Your login email address &#160;
                      </Typography>

                      <Typography variant='body1' style={{ fontWeight: '600' }}>
                        {maskedEmailAddress}
                      </Typography>
                    </Grid>
                    {errors.passwordCriteria && (
                      <Grid item xs={12} className={classes.textPadding}>
                        <Typography
                          variant='h6'
                          style={{
                            border: '1px solid #FF0000',
                            backgroundColor: '#FFF0F0',
                            opacity: '1',
                            textAlign: 'center',
                            padding: '0.75rem',
                            fontSize: '0.75rem',
                          }}
                        >
                          {errors.passwordCriteria}
                        </Typography>
                      </Grid>
                    )}
                    <form
                      id='confirmPassword'
                      onSubmit={this.handleResetPassword}
                    >
                      <Grid item xs={12} className={classes.textPadding}>
                        <Grid container spacing={4}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.textPadding}
                          >
                            <TextField
                              variant='outlined'
                              required
                              name='password'
                              value={password}
                              id='password'
                              onChange={this.handleChange}
                              //no copy paste allowed
                              onPaste={this.onCopyPaste}
                              onCopy={this.onCopyPaste}
                              fullWidth
                              size='small'
                              label='Enter Password'
                              type={
                                showPassword === false ? 'password' : 'text'
                              }
                              autoComplete='password'
                              placeholder='Enter password'
                              error={errors.password ? true : false}
                              helperText={
                                <Typography
                                  variant='caption'
                                  style={{ fontSize: '0.615rem' }}
                                >
                                  {errors.password
                                    ? errors.password
                                    : 'Please enter your password'}
                                </Typography>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='Toggle password visibility'
                                      onClick={this.handleClickPassword}
                                    >
                                      {showPassword === false ? (
                                        <VisibilityOffIcon fontSize='small' />
                                      ) : (
                                        <VisibilityIcon fontSize='small' />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.textPadding}
                          >
                            <TextField
                              variant='outlined'
                              label='Confirm Password'
                              required
                              name='confirmPassword'
                              value={confirmPassword}
                              onChange={this.handleChange}
                              //no copy paste allowed
                              onPaste={this.onCopyPaste}
                              onCopy={this.onCopyPaste}
                              fullWidth
                              size='small'
                              id='confirm_password'
                              type={
                                showConfirmPassword === false
                                  ? 'password'
                                  : 'text'
                              }
                              placeholder='Re-enter your password'
                              error={errors.confirmPassword ? true : false}
                              helperText={
                                <Typography
                                  variant='caption'
                                  style={{ fontSize: '0.615rem' }}
                                >
                                  {errors.confirmPassword
                                    ? errors.confirmPassword
                                    : 'Please re-enter your password to confirm'}
                                </Typography>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='Toggle password visibility'
                                      onClick={this.handleClickConfirmPassword}
                                    >
                                      {showConfirmPassword === false ? (
                                        <VisibilityOffIcon fontSize='small' />
                                      ) : (
                                        <VisibilityIcon fontSize='small' />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          //mention styles in makestyles
                          className={classes.strength}
                        >
                          Password Strength&#160;:&#160;
                          {password !== ''
                            ? PasswordStrengthMeter(passwordStrength)
                            : ''}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} className={classes.textPadding}>
                        <Typography variant='h6' className={classes.text}>
                          Password Criteria :
                        </Typography>
                        <ul style={{ fontSize: '1rem', margin: '0.5rem' }}>
                          {passwordCriteria.isAlphanumeric === true && (
                            <li>
                              <Typography variant='body2'>
                                Should be alphanumeric
                              </Typography>
                            </li>
                          )}
                          {passwordCriteria.mustHaveSpecialCharacters ===
                            true && (
                              <li>
                                <Typography variant='body2'>
                                  Should contain symbols (like &#64;, &#163;,
                                  &#37;, &#33; or &amp;)
                              </Typography>
                              </li>
                            )}
                          {passwordCriteria.isOneSmallLetter === true && (
                            <li>
                              <Typography variant='body2'>
                                Should contain atleast one small letter
                              </Typography>
                            </li>
                          )}
                          {passwordCriteria.isOneCapitalLetter === true && (
                            <li>
                              <Typography variant='body2'>
                                Should contain atleast one capital letter
                              </Typography>
                            </li>
                          )}
                          <li>
                            <Typography variant='body2'>
                              Minimum {passwordCriteria.minCharacters}{' '}
                              characters - Maximum
                              {passwordCriteria.maxCharacters} characters
                            </Typography>
                          </li>
                          {passwordCriteria.isOneNumber === true && (
                            <li>
                              <Typography variant='body2'>
                                Should have atleast one number
                              </Typography>
                            </li>
                          )}
                          {passwordCriteria.shouldNotHaveNumbers === true && (
                            <li>
                              <Typography variant='body2'>
                                Should not have numbers
                              </Typography>
                            </li>
                          )}
                        </ul>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <StyledButton
                          // size='small'
                          type='submit'
                          style={{
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                          }}
                          disabled={disableReset}
                        >
                          <Typography variant='caption'>
                            Reset Password
                          </Typography>
                        </StyledButton>
                      </Grid>
                    </form>
                    <br />
                    <br />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  resetPasswordTokenDetails: state.user.resetPasswordTokenDetails,
  resetPassword: state.user.resetPassword,
});

export default withStyles(Styles)(
  connect(mapStateToProps, {
    userConfirmResetPassword,
    getResetPasswordTokenDetails,
  })(withRouter(ResetConfirmPassword))
);
