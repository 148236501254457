import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../common/models/Button';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  media: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
  },
  headerText: {
    textAlign: 'center',
    paddingBottom: '12px',
  },
  textDesGrid: {
    minHeight: 75,
    //to make text come in vertically centered
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 90,
    },
  },

  separate: {
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
      marginRight: 0,
    },
  },
  checkPadding: {
    padding: '1.5rem',
  },
}));

const DashboardCard = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={10} sm={8} md={4} className={classes.separate}>
      <Grid
        item
        component={Card}
        xs={12}
        style={{ ...props.style, boxShadow: 'none' }}
      >
        {/* <Card className={classes.root}> */}
        <Grid container justify='center'>
          <Grid item xs={12} className={classes.checkPadding}>
            <Typography variant='h5' className={classes.headerText}>
              {props.header}
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: 'center' }}
            >
              <img
                className={classes.media}
                src={props.ImageUrl}
                alt={props.imgTitle}
              />
            </Grid>
            {/* You are using grid item inside a grid item */}
            <Grid className={classes.textDesGrid}>
              <Typography
                style={{ textAlign: 'center' }}
                className={classes.textPadding}
              >
                {props.description}
              </Typography>
            </Grid>

            <Grid style={{ marginTop: '1rem', textAlign: 'center' }}>
              <StyledButton
                size='small'
                component={Link}
                to={props.whereTo}
                style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
              >
                {props.buttonText}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default DashboardCard;
