import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './updateBatchStyles';
// import MandatorySpan from '../../models/MandatorySpan';
import DropFilesAttach from '../../../models/files/DropFilesAttach';
import ShowFiles from '../../../models/files/ShowFiles';
import MandatorySpan from '../../models/MandatorySpan';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllConfigurationFlags } from '../../../../../containers/actions/common/reportsActions';

const FileUploads = (props) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const { collabType } = localStorage;
  const {
    title,
    fileType,
    batchDetails,
    handleUploadFiles,
    newFiles,
    existingFiles,
    handleDelete,
    handleDownloadFile,
    uploadFeatureAccess,
  } = props;

  // FG features and validation flags
  // const QAValidations = useSelector((state) => {
  //   if (state.pcmp.common.reports.rmpmGrnFeatures?.status) {
  //     return state.pcmp.common.reports.rmpmGrnFeatures.data.validations.FG
  //       .DSBatch.QAInfo;
  //   }
  //   return {};
  // });

  // const getFeatures = useCallback(() => {
  //   if (Object.keys(QAValidations).length === 0) {
  //     dispatch(getAllConfigurationFlags());
  //   }
  // }, [dispatch, QAValidations]);
  // useEffect(() => {
  //   getFeatures();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const uploadFileFG = useSelector(
  //   (state) =>
  //     state.pcmp.common.accessControl.featuresAccess.fgBatchFeatures
  //       ?.fgBatchUploadDocuments
  // );
  const isEditable = (type) => {
    let edit;
    switch (type) {
      case 'E-InvoiceUpload':
        if (batchDetails.SASLBatchStatusID === 7 && uploadFeatureAccess) {
          edit = true;
        }
        break;
      case 'E-WayBill':
        if (batchDetails.SASLBatchStatusID === 19 && uploadFeatureAccess) {
          edit = true;
        }
        break;
      default:
        edit = false;
    }
    return edit;
  };
  return (
    <React.Fragment>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h4' className={classes.title}>
            {title}
            {isEditable(fileType) && <MandatorySpan />}
          </Typography>
          <span className={classes.borders}></span>
          <br />
        </Grid>

        <Grid item xs={12}>
          {isEditable(fileType) && (
            <React.Fragment>
              <DropFilesAttach
                // title={title}
                handleUploadFiles={handleUploadFiles}
                // required={QAValidations.coaFile?.required}
              />
              <ShowFiles
                files={newFiles}
                clearable
                clearFile={handleDelete}
                removeNoRecordsMsg
              />
            </React.Fragment>
          )}

          {!isEditable(fileType) && ( // Spira ID: 2573, Will Display files after its uploaded.
            <>
              <Typography
                variant='body2'
                style={{ fontWeight: 500, paddingTop: '1rem' }}
              >
                View{' '}
                {fileType === 'E-InvoiceUpload'
                  ? 'E-Invoice'
                  : fileType === 'E-WayBill'
                  ? 'E-Way Bill'
                  : ''}{' '}
                files
              </Typography>
              <ShowFiles
                files={existingFiles}
                downloadable
                downloadFile={handleDownloadFile}
              />
            </>
          )}
        </Grid>
      </Grid>
      <hr />
    </React.Fragment>
  );
};

export default FileUploads;
