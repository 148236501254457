import axios from 'axios';
import { appUrl } from '../../../../../utils/config';

import {
  BATCH_SUMMERY,
  LINE_SUMMERY,
  SUMMERY,
  BATCH_SUMMERY_DETAILS,
  LINE_SUMMERY_DETAILS,
  ALL_PURCHASE_ORDERS,
  ALL_LINE_ITEMS,
  ALL_BATCH_ITEMS,
  PO_BATCH_ITEMS,
  DATE_FORMAT_UPDATE
} from '../types';

export let getBatchSummarySource;
export const getBatchSummery = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  getBatchSummarySource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/batchSummary`,
      data, { cancelToken: getBatchSummarySource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BATCH_SUMMERY,
          payload: res.data.batchSummary,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let lineSummarySource;
export const lineSummery = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  lineSummarySource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/lineItemSummary`,
      data, { cancelToken: lineSummarySource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: LINE_SUMMERY,
          payload: res.data.lineItemSummary,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let summarySource;
export const summery = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  summarySource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/summary`,
      data, { cancelToken: summarySource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SUMMERY,
          payload: res.data.summary,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let batchSummarySource;
export const viewBatchSummaryDetails = (data) => (dispatch) => {
  const CancelToken = axios.CancelToken;
  batchSummarySource = CancelToken.source();


  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/viewBatchSummaryDetails`,
      data, { cancelToken: batchSummarySource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: BATCH_SUMMERY_DETAILS,
          payload: res.data.batchSummaryDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const viewLineSummaryDetails = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/${data.APIName}`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: LINE_SUMMERY_DETAILS,
          payload: res.data.lineItemSummaryDetails,
        });
      }
    })
    .catch((err) => console.log(err));
};
export let viewPurchaseOrderSource;
export const viewAllPurchaseOrders = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  viewPurchaseOrderSource = CancelToken.source();

  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/viewAllPurchaseOrders`,
      data, { cancelToken: viewPurchaseOrderSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_PURCHASE_ORDERS,
          payload: res.data.purchaseOrderList,
        });
      }
    })
    .catch((err) => console.log(err));
};

export let viewLineItemSource;
export const viewAllLineItems = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  viewLineItemSource = CancelToken.source();


  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/viewAllLineItems`,
      data, { cancelToken: viewLineItemSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_LINE_ITEMS,
          payload: res.data.lineItemList,
        });
      }
    })
    .catch((err) => console.log(err));
};
export let viewBatchItemSource;
export const viewAllBatchItems = (data) => (dispatch) => {

  const CancelToken = axios.CancelToken;
  viewBatchItemSource = CancelToken.source();


  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/viewAllBatchItems`,
      data, { cancelToken: viewBatchItemSource.token }
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ALL_BATCH_ITEMS,
          payload: res.data.batchItemList,
        });
      }
    })
    .catch((err) => console.log(err));
};
export const viewPOBatchItems = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/viewAllBatchesInPO`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: PO_BATCH_ITEMS,
          payload: res.data.batchItemList,
        });
      }
    })
    .catch((err) => console.log(err));
};

// settings action
export const viewApplicationSettings = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/viewApplicationSettings`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        if (
          res.data.selectedDateFormat === null ||
          res.data.selectedDateFormat === undefined
        ) {
          localStorage.setItem('selectedDateFormat', 'DD/MMM/YYYY');
        } else {
          localStorage.setItem(
            'selectedDateFormat',
            res.data.selectedDateFormat
          );
        }
      }
    })
    .catch((err) => console.log(err));
};

export const updateApplicationSettings = (data) => (dispatch) => {
  axios
    .post(
      `${appUrl}/${localStorage.appName}/${localStorage.network}/${data.collabType}/${data.roleName}/updateApplicationSettings`,
      data
    )
    .then((res) => {
      if (res.data.status) {
        localStorage.setItem('selectedDateFormat', data.selectedDateFormat);
        dispatch({
          type: DATE_FORMAT_UPDATE,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};