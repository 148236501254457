import React from 'react';
import TwoStepAuthCards from './TwoStepAuthCards';
import Grid from '@material-ui/core/Grid';
import HeaderFooterwithCookies from '../../common/openPages/HeaderFooterwithCookies';

function TwoStepAuthIndex() {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderFooterwithCookies cookiesEnabled={false}>
        <TwoStepAuthCards />
      </HeaderFooterwithCookies>
    </Grid>
  );
}

export default TwoStepAuthIndex;
