import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import StyledButton from '../../../common/models/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.5rem',
  },
  root: {
    minWidth: 275,
    boxShadow: 'none',
  },
  btns: {
    textAlign: 'center',
  },
});

const ResetPasswordCompletedCard = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      // alignItems='center'
      justify='center'
    >
      <Grid item xs={11} sm={8} md={5}>
        <Card className={classes.root}>
          <CardContent>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h4' className={classes.text}>
                Password Reset Complete
              </Typography>
              <Typography
                variant='body2'
                style={{ fontSize: '1rem', padding: '0.75rem' }}
              >
                You have successfully reset your password for your account.
                Please use the below link to sign in to your Mosymphony account.
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12} className={classes.btns}>
              <StyledButton
                component={Link}
                to='/login'
                variant='contained'
                color='primary'
                size='medium'
                style={{ padding: '0.5rem 3rem' }}
              >
                Sign In
              </StyledButton>
              <br />
              <br />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid
        container
        direction='row'
        justify='center'
        style={{ marginBottom: 'auto' }}
      >
        <Typography style={{ fontWeight: 500 }}>
          Remembered your details?&nbsp;
        </Typography>
        <Typography color='primary' component={Link} to='/login'>
          Sign In using your login details
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default ResetPasswordCompletedCard;
