import {
  CUSTOM_FILES,
  CUSTOM_DOWNLOADED_FILE,
  OPEN_BACKDROP,
  DESTROY_BACKDROP,
  DOWNLOAD_EXCEL_FILE,
  TABLE_LOAD,
  DESTROY_TABLE_LOAD,
} from '../../actions/types';

const intialState = { backdropText: 'Please Wait...' };

export default function (state = intialState, action) {
  switch (action.type) {
    case CUSTOM_FILES:
      return {
        ...state,
        customFiles: action.payload,
      };
    case CUSTOM_DOWNLOADED_FILE:
      return {
        ...state,
        customFileDescription: action.payload,
      };
    //test backdrop;
    case OPEN_BACKDROP:
      return {
        ...state,
        backdropLoading: true,
        backdropText: action.backdropText
          ? action.backdropText
          : 'Please Wait...',
      };

    case DESTROY_BACKDROP:
      return {
        ...state,
        backdropLoading: false,
      };
    case DOWNLOAD_EXCEL_FILE:
      return {
        ...state,
        downloadSelectedExcelFile: action.payload,
      };

    //table load indiactor for RM/PM GRN Table
    case TABLE_LOAD:
      return {
        ...state,
        tableLoading: true,
      };
    case DESTROY_TABLE_LOAD:
      return {
        ...state,
        tableLoading: false,
      };
    default:
      return state;
  }
}
