import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F8F8F8',
    border: '2px solid #E2E2E2',
    padding: '2rem',
    paddingBottom: 0,
    marginBottom: '2rem',
  },
  text: {
    fontWeight: 500,
    marginBottom: '1rem',
    overflow: 'auto',
  },
  bottomgrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '2rem',
    marginTop: '1rem',
  },
  textPaddingRight: {
    paddingRight: '1rem',
  },
}));

const ConfirmDetails = (props) => {
  const {
    fullName,
    maskedEmailAddress,
    organizationName,
    collaboratorTypeName,
    collaboratorRoleName,
    designation,
    handleChange,
    errors,
  } = props;

  //styles should be in makestyles
  const classes = useStyles();
  return (
    <Grid container justify='space-around'>
      <Grid item xs={10} sm={10} md={11} className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={8} md={6} className={classes.textPaddingRight}>
            <Typography variant='body1'> Email &#160;</Typography>
            <Typography
              variant='subtitle1'
              className={classes.text}
              style={{ maxWidth: '20rem', overflow: 'auto' }}
            >
              {maskedEmailAddress}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <Typography variant='body1'>Company Name</Typography>
            <Typography variant='subtitle1' className={classes.text}>
              {organizationName}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <Typography variant='body1'>Collabrator Type &#160;</Typography>
            <Typography variant='subtitle1' className={classes.text}>
              {collaboratorTypeName}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <Typography variant='body1'>Role</Typography>
            <Typography variant='subtitle1' className={classes.text}>
              {collaboratorRoleName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={10} sm={4} md={5}>
        <TextField
          variant='outlined'
          name='fullName'
          value={fullName}
          onChange={handleChange}
          fullWidth
          size='small'
          label={<Typography variant='caption'>Full Name</Typography>}
          placeholder='Full Name'
          error={errors.fullName ? true : false}
          helperText={
            errors.fullName ? errors.fullName : 'Enter your Full Name'
          }
        />
      </Grid>

      <Grid item xs={10} sm={4} md={5}>
        <TextField
          name='designation'
          variant='outlined'
          label={
            <Typography variant='caption'>Designation (Optional)</Typography>
          }
          fullWidth
          size='small'
          id='confirm_password'
          helperText='Enter Designation'
          type='text'
          style={{ marginBottom: '2rem' }}
          onChange={handleChange}
          value={designation}
        />
      </Grid>
    </Grid>
  );
};

export default ConfirmDetails;
