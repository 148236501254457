import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { StyledAppBar } from './models/index';
import Logo from '../../resources/images/mosymphony@2x-1.png';
import StyledButton from '../common/models/Button';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Link as RouterLink } from 'react-router-dom';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: 'transparent',
    },
    textTransform: 'none',
    justifyContent: 'space-evenly',
    marginRight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '1rem',
      marginRight: 0,
      fontSize: '0.6rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '0.5rem',
      marginRight: 0,
      fontSize: '0.5em',
    },
  },
  mobileHeaderImage: {
    maxHeight: '3rem',
    maxWidth: '62rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10rem',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
  },
}));

export default function ButtonAppBar(props) {
  const [drawerOpen, setMobileOpen] = React.useState(false);

  const classes = useStyles(props);

  const handleDrawerToggle = () => {
    setMobileOpen(!drawerOpen);
  };

  const handleHamToggle = () => {
    setMobileOpen(true);
  };

  const handleCloseHam = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <List style={{ bottom: '1rem' }}>
        <ListItem>
          <ListItemIcon>
            <RouterLink to='/'>
              <img src={Logo} alt='Logo icon' style={{ width: '10rem' }} />
            </RouterLink>
          </ListItemIcon>

          <IconButton edge='end' onClick={handleCloseHam}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </ListItem>
        <Divider />

        <ListItem style={{ justifyContent: 'center' }}>
          <StyledButton
            size='small'
            fullWidth
            id='login_Who'
            href='https://www.schrocken.com/about-us/'
            target='_blank'
            style={{
              marginTop: '1rem',
              backgroundImage: 'linear-gradient(to right,#374761,#2058A3)',
            }}
          >
            Who we are
          </StyledButton>
        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          <Button
            size='small'
            id='login_Features'
            href='https://www.schrocken.com/mosymphony/#mosymphony-benefits'
            target='_blank'
            style={{ textTransform: 'none' }}
          >
            Features
          </Button>
        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          <Button
            size='small'
            id='login_Support'
            href='https://www.schrocken.com/create-a-support-request/'
            target='_blank'
            style={{ textTransform: 'none' }}
          >
            Support
          </Button>
        </ListItem>
        <ListItem style={{ justifyContent: 'center' }}>
          <StyledButton
            size='small'
            fullWidth
            id='login_request'
            variant='contained'
            color='primary'
            href='https://www.schrocken.com/mosymphony/#request-a-demo'
            target='_blank'
          >
            Request Demo
          </StyledButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <StyledAppBar position='static' backgroundColor={props.backgroundColor}>
      <Toolbar>
        <Hidden smUp implementation='css'>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={handleHamToggle}
          >
            <MenuIcon style={{ color: 'black' }} />
          </IconButton>
        </Hidden>

        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Grid container alignItems='center' justify='space-between'>
          <Grid item xs={3}>
            <RouterLink to='/'>
              <img
                src={Logo}
                alt='final'
                className={classes.mobileHeaderImage}
              />
            </RouterLink>
          </Grid>

          <Grid>
            <Hidden xsDown implementation='css'>
              <Button
                size='small'
                id='login_Who'
                className={classes.button}
                href='https://www.schrocken.com/about-us/'
                target='_blank'
              >
                Who we are
              </Button>
              <Button
                size='small'
                id='login_Features'
                className={classes.button}
                href='https://www.schrocken.com/mosymphony/#mosymphony-benefits'
                target='_blank'
              >
                Features
              </Button>
              <Button
                size='small'
                id='login_Support'
                className={classes.button}
                href='https://www.schrocken.com/create-a-support-request/'
                target='_blank'
              >
                Support
              </Button>
              <StyledButton
                size='small'
                id='login_request'
                variant='contained'
                color='primary'
                style={{
                  fontSize: '0.8125rem',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                }}
                href='https://www.schrocken.com/mosymphony/#request-a-demo'
                target='_blank'
              >
                Request Demo
              </StyledButton>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}
