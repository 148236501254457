import {
  PHARMA_DOWNLOAD_COA_FILE,
  PHARMA_DOWNLOAD_RconSheet_FILE,
  PHARMA_DOWNLOAD_PIR_FILE,
  PHARMA_DOWNLOAD_ShipperRecord_FILE,
  PHARMA_DOWNLOAD_INVOICE_FILE,
  PHARMA_DOWNLOAD_EWAY_FILE,
  PHARMA_DOWNLOAD_LR_FILE
} from "../../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case PHARMA_DOWNLOAD_COA_FILE:
      return {
        ...state,
        COADownload: action.payload
      };
    case PHARMA_DOWNLOAD_RconSheet_FILE:
      return {
        ...state,
        reconciliationSheetDownload: action.payload
      };
    case PHARMA_DOWNLOAD_PIR_FILE:
      return {
        ...state,
        packInspectionRecordDownload: action.payload
      };
    case PHARMA_DOWNLOAD_ShipperRecord_FILE:
      return {
        ...state,
        shipperRecordDownload: action.payload
      };
    case PHARMA_DOWNLOAD_INVOICE_FILE:
      return {
        ...state,
        invoiceDownloaded: action.payload
      };
    case PHARMA_DOWNLOAD_EWAY_FILE:
      return {
        ...state,
        EWayBillDownload: action.payload
      };
    case PHARMA_DOWNLOAD_LR_FILE:
      return {
        ...state,
        LRDownload: action.payload
      };
    default:
      return state;
  }
}
