export const BatchAuditheader = (qaApprovalFlag) => {
  let header = [
    {
      id: 'lastUpdatedDate',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'Date & Time',
      varName: 'lastUpdatedDate',
    },
    {
      id: 'eventType',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'Event Type',
      varName: 'eventType',
    },
    {
      id: 'eventByUser',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'Event By',
      varName: 'eventByUser',
    },
    {
      id: 'organization',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'Organization',
      varName: 'organization',
    },
    {
      id: 'batchStatus',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'Batch Status',
      varName: 'batchStatus',
    },

    {
      id: 'batchUOM',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'UOM',
      varName: 'batchUOM',
    },
    {
      id: 'batchSize',
      align: 'center',
      numeric: true,
      disablePadding: false,
      label: 'Batch Size',
      varName: 'batchSize',
    },
    {
      id: 'producedQuantity',
      align: 'right',
      numeric: true,
      disablePadding: false,
      label: 'Produced Quantity',
      varName: 'producedQuantity',
    },
    {
      id: 'readyForDispatchQuantity',
      align: 'right',
      numeric: true,
      disablePadding: false,
      label: 'Ready for Dispatch Qty',
      varName: 'readyForDispatchQuantity',
    },
    {
      id: 'controlSampleQuantity',
      align: 'right',
      numeric: true,
      disablePadding: false,
      label: 'Control Sample Qty',
      varName: 'controlSampleQuantity',
    },
    // {
    //   id: 'batchStartDate',
    //   align: 'center',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Start Date',
    //   varName: 'batchStartDate',
    // },
    // {
    //   id: 'batchEndDate',
    //   align: 'center',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'End Date',
    //   varName: 'batchEndDate',
    // },
    // {
    //   id: 'qcReleaseDate',
    //   align: 'center',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'QC Release Date',
    //   varName: 'qcReleaseDate',
    // },
    // {
    //   id: 'dispatchDate',
    //   align: 'center',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Dispatch Date',
    //   varName: 'dispatchDate',
    // },
  ];

  if (qaApprovalFlag) {
    header.splice(5, 0, {
      id: 'batchQAStatus',
      align: 'center',
      numeric: false,
      disablePadding: false,
      label: 'QA Status',
      varName: 'qaStatus',
    });
  }
  return header;
};

export const DSauditHeader = [
  {
    id: 'lastUpdatedDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Date & Time',
    varName: 'lastUpdatedDate',
  },
  {
    id: 'eventByUser',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Event By',
    varName: 'eventByUser',
  },
  {
    id: 'eventType',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Event Type',
    varName: 'eventType',
  },
  {
    id: 'status',
    align: 'center',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    varName: 'status',
  },

  {
    id: 'deliveryDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Delivery Date',
    varName: 'deliveryDate',
  },
  {
    id: 'uom',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'UOM',
    varName: 'uom',
  },

  {
    id: 'scheduledQuantity',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Scheduled Quantity',
    varName: 'scheduledQuantity',
  },

  {
    id: 'openQuantity',
    align: 'right',
    numeric: true,
    disablePadding: false,
    label: 'Open Quantity',
    varName: 'openQuantity',
  },
];

export const SAauditHeader = [
  {
    id: 'dateTime',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Date & Time',
    varName: 'dateTime',
  },
  {
    id: 'eventByUser',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Event By',
    varName: 'eventByUser',
  },
  {
    id: 'eventType',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Event Type',
    varName: 'eventType',
  },
  {
    id: 'saStatus',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'SA Status',
    varName: 'saStatus',
  },
  {
    id: 'agreementDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Agreement Date',
    varName: 'agreementDate',
  },
  {
    id: 'agreementType',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Agreement Type',
    varName: 'agreementType',
  },
  {
    id: 'validityStartDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Validity Start Date',
    varName: 'validityStartDate',
  },
  {
    id: 'validityEndDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Validity End Date',
    varName: 'validityEndDate',
  },
  {
    id: 'currency',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Currency',
    varName: 'currency',
  },
];

export const SLauditHeader = [
  {
    id: 'plant',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Plant',
    varName: 'plant',
  },
  {
    id: 'lastUpdatedDate',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Date & Time',
    varName: 'lastUpdatedDate',
  },
  {
    id: 'eventByUser',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Event By',
    varName: 'eventByUser',
  },
  {
    id: 'eventType',
    align: 'left',
    numeric: true,
    disablePadding: false,
    label: 'Event Type',
    varName: 'eventType',
  },
  {
    id: 'materialCode',
    align: 'right',
    numeric: true,
    disablePadding: false,
    label: 'Material Code',
    varName: 'materialCode',
  },
  {
    id: 'materialName',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Material Description',
    varName: 'materialName',
  },
  {
    id: 'materialGroup',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'Material Group',
    varName: 'materialGroup',
  },
  {
    id: 'uom',
    align: 'center',
    numeric: true,
    disablePadding: false,
    label: 'UOM',
    varName: 'uom',
  },
  {
    id: 'targetQuantity',
    align: 'right',
    numeric: true,
    disablePadding: false,
    label: 'Target Quantity',
    varName: 'targetQuantity',
  },
  {
    id: 'openTargetQuantity',
    align: 'right',
    numeric: true,
    disablePadding: false,
    label: 'Open Target Quantity',
    varName: 'openTargetQuantity',
  },
];
