import React from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderLayout from '../common/HeaderLayout';
import LoginFooter from '../common/Footer';
import { makeStyles } from '@material-ui/core/styles';
import CookieDrawer from '../common/CookieDrawer';
import Media from '../common/Media';
import ValidationFailedCard from './ValidationFailedCard';
import { cookieSettings } from '../common/helperFunctions/helper';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  media: {
    position: 'fixed',
    bottom: '2.68rem',
    width: '100%',
  },
});

const AuthFailed = (props) => {
  const classes = useStyles();
  const [cookiesConsent, setCookiesConsent] = React.useState(
    cookieSettings().cookiesConsent
  );
  const setCookies = (value) => {
    localStorage.setItem('cookiesConsent', value);
    setCookiesConsent(value);
  };
  return (
    <Grid container>
      <HeaderLayout headerLink={true} style={{ position: 'static' }} />
      <ValidationFailedCard />
      <Grid item xs={12} className={classes.media}>
        <Media />
      </Grid>
      <CookieDrawer
        cookiesConsent={cookiesConsent}
        hasCookieSettings={true}
        setCookies={setCookies}
      />
      <LoginFooter
        hasLinks={true}
        hasCookieSettings={true}
        cookiesAccepted={false}
        style={{ position: 'fixed', bottom: 0 }}
      />
    </Grid>
  );
};

export default AuthFailed;
