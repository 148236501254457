import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import InviteToEcosystem from './InviteToEcosystem';
import StyledButton from '../../../common/models/Button';
import EcosystemTable from '../ecosystems/ecosystemTable';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

//import GA
import { trackEvent } from '../../../../tracking/index';

const useStyles = (theme) => ({
  alignButton: {
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      marginTop: '1rem',
    },
  },
});

class Ecosystems extends Component {
  state = { openInvite: false };

  handleOpen = () => {
    trackEvent('Admin Settings', 'Invite CMS', 'Ecosystems');
    this.setState({ openInvite: true });
  };
  handleClose = () => {
    this.setState({ openInvite: false });
  };
  render() {
    const { classes } = this.props;
    const { openInvite } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              variant='h5'
              style={{ fontWeight: 500, fontSize: '2rem' }}
            >
              Ecosystems
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.alignButton}>
            <StyledButton onClick={this.handleOpen}>
              Invite to Create New Ecosystem
            </StyledButton>
          </Grid>
        </Grid>
        <Dialog fullScreen open={openInvite} onClose={this.handleClose}>
          <InviteToEcosystem handleClose={this.handleClose} />
        </Dialog>
        <EcosystemTable />
      </div>
    );
  }
}

export default withStyles(useStyles)(Ecosystems);
