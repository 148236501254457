import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../common/models/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import querystring from 'query-string';
import { withRouter } from 'react-router-dom';

import MediaHeader from '../common/openPages/openPageHeaderwithMedia';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: '#00000000 0% 0% no-repeat padding-box',
    border: '0.125rem solid #374761',
  },
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.8rem',
    fontSize: '2.5rem',
  },
  root: {
    minWidth: 275,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flexGrow: 1,
    boxShadow: 'none',
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
}));

function RequestMailSent(props) {
  const classes = useStyles();
  const { search } = props.location;
  const parsed = querystring.parse(search);
  const email = parsed.email;

  return (
    <MediaHeader>
      <Grid container justify='center'>
        <Grid item xs={11} sm={10} md={6}>
          <Card className={classes.root}>
            <CardContent>
              <Grid item xs={12} className={classes.header}>
                <Typography variant='h4' className={classes.text}>
                  Email Sent!
                </Typography>
                <Typography
                  variant='body2'
                  style={{ fontSize: '1.2rem', padding: '0.75rem' }}
                >
                  Check your&nbsp;
                  <Typography
                    variant='inherit'
                    style={{
                      fontWeight: 500,
                      textDecoration: 'underline',
                      fontSize: '1.2rem',
                      display: 'inline',
                    }}
                  >
                    {email}
                  </Typography>
                  &nbsp;inbox for confirmation
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12} className={classes.btns}>
                <StyledButton
                  component={Link}
                  to='/login'
                  variant='contained'
                  color='primary'
                  size='medium'
                  style={{ padding: '0.5rem 3rem' }}
                >
                  Sign In
                </StyledButton>
              </Grid>
              <br />
            </CardContent>
          </Card>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </MediaHeader>
  );
}
export default withRouter(RequestMailSent);
