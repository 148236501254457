import ReactGA from 'react-ga';
import { AnalyticsKey } from '../utils/config';

//Initialise Google analytics
export const initiliseGoogleAnalytics = () => {
  ReactGA.initialize(AnalyticsKey);
};

//Page view
export const pageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// track events eg. button clicks
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
