import { DialogContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../batch/updateBatch/updateBatchStyles';
import CloseIcon from '@material-ui/icons/Close';
import ShowFiles from '../../models/files/ShowFiles';

const DownloadFilesDialogBox = (props) => {
  const classes = useStyles();
  const {
    handleDownloadDialog,
    handleDownloadFile,
    coaFiles,
    eInvoiceFiles,
    eWayBillFiles,
    customFiles,
    qualityApprovalFlowFlag,
  } = props;
  return (
    <React.Fragment>
      <Grid container justifyContent='flex-end' style={{ padding: '1rem' }}>
        <Grid item sm={3}>
          <Typography
            className={classes.closeIconSty}
            variant='body2'
            onClick={handleDownloadDialog}
          >
            Cancel <CloseIcon fontSize='medium' />
          </Typography>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container>
          {Array.isArray(coaFiles) && coaFiles.length > 0 && (
            <Grid item xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
              <Typography variant='h4' className={classes.title}>
                Download CoA
              </Typography>
              <span className={classes.borders}></span>

              <React.Fragment>
                <Typography
                  variant='body2'
                  style={{ fontWeight: 500, paddingTop: '1rem' }}
                >
                  View COA files
                </Typography>
                <ShowFiles
                  files={coaFiles}
                  downloadable
                  downloadFile={handleDownloadFile}
                  coaFile={true}
                  qualityApprovalFlowFlag={qualityApprovalFlowFlag}
                />
              </React.Fragment>
            </Grid>
          )}

          {qualityApprovalFlowFlag &&
            Array.isArray(eInvoiceFiles) &&
            eInvoiceFiles.length > 0 && (
              <>
                <hr style={{ width: '100%' }} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ margin: '16px 0px' }}
                >
                  <Typography variant='h4' className={classes.title}>
                    Download E-Invoice
                  </Typography>
                  <span className={classes.borders}></span>

                  <React.Fragment>
                    <Typography
                      variant='body2'
                      style={{ fontWeight: 500, paddingTop: '1rem' }}
                    >
                      View E-Invoice
                    </Typography>
                    <ShowFiles
                      files={eInvoiceFiles}
                      downloadable
                      downloadFile={handleDownloadFile}
                    />
                  </React.Fragment>
                </Grid>
              </>
            )}
          {qualityApprovalFlowFlag &&
            Array.isArray(eWayBillFiles) &&
            eWayBillFiles.length > 0 && (
              <>
                <hr style={{ width: '100%' }} />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ margin: '16px 0px' }}
                >
                  <Typography variant='h4' className={classes.title}>
                    Download E-Way Bill
                  </Typography>
                  <span className={classes.borders}></span>

                  <React.Fragment>
                    <Typography
                      variant='body2'
                      style={{ fontWeight: 500, paddingTop: '1rem' }}
                    >
                      View E-Way Bill
                    </Typography>
                    <ShowFiles
                      files={eWayBillFiles}
                      downloadable
                      downloadFile={handleDownloadFile}
                    />
                  </React.Fragment>
                </Grid>
              </>
            )}
          {Array.isArray(customFiles) && customFiles.length > 0 && (
            <>
              {(coaFiles.length > 0 ||
                eInvoiceFiles.length > 0 ||
                eWayBillFiles.length > 0) && <hr style={{ width: '100%' }} />}
              <Grid item xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
                <Typography variant='h4' className={classes.title}>
                  Download Custom Files
                </Typography>
                <span className={classes.borders}></span>

                <React.Fragment>
                  <Typography
                    variant='body2'
                    style={{ fontWeight: 500, paddingTop: '1rem' }}
                  >
                    View Custom Files
                  </Typography>
                  <ShowFiles
                    files={customFiles}
                    downloadable
                    downloadFile={handleDownloadFile}
                  />
                </React.Fragment>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </React.Fragment>
  );
};

export default DownloadFilesDialogBox;
