import React from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderLayout from '../HeaderLayout';
import LoginFooter from '../Footer';
import { makeStyles } from '@material-ui/core/styles';
import CookieDrawer from '../CookieDrawer';
import Media from '../Media';
import { cookieSettings } from '../helperFunctions/helper';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const OpenPages = ({ children, ...props }) => {
  const classes = useStyles();
  const [cookiesConsent, setCookiesConsent] = React.useState(
    cookieSettings().cookiesConsent
  );
  const setCookies = (value) => {
    localStorage.setItem('cookiesConsent', value);
    setCookiesConsent(value);
  };
  return (
    <Grid
      container
      id='headerFooterIndex'
      className={classes.root}
      style={{
        height: '100vh',
        backgroundColor: '#F2F2F2',
      }}
    >
      <HeaderLayout headerLink={true} style={{ position: 'static' }} />
      {children}
      <Media />
      <CookieDrawer
        cookiesConsent={cookiesConsent}
        hasCookieSettings={true}
        setCookies={setCookies}
      />
      <LoginFooter
        hasLinks={true}
        hasCookieSettings={true}
        cookiesAccepted={false}
      />
    </Grid>
  );
};

export default OpenPages;
