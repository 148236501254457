import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import StyledButton from '../../../../../../common/models/Button';
//files
import General from './General';
import BatchInformation from './BatchInformation';
import QualityAssuranceInformation from './QCAndShipping/QualityAssuranceInformation';
import ShippingInformation from './QCAndShipping/ShippingInformation';
import ConsumptionInformation from './Consumption/ConsumptionInformation';
// import OtherDocuments from './OtherDocuments';
import StyledDialog from '../../../../../../common/models/Dialog';
import {
  Typography,
  Grid,
  // TextField,
  DialogContent,
  Button,
  TextField,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowStatus from './ShowStatus';
import VarianceAlertDialog from './Consumption/VarianceAlertDialog';
//actions
import {
  fetchDSBatchDetails,
  updateDSBatch,
  fetchBatchDetails,
  approveDSBatch,
  fetchNextStatusList,
  sendDSBatchForApproval,
  rejectDSBatchApproval,
  dsBatchSource,
  nextBatchSASLSource,
  batchUpdated,
  requestQAApproval,
} from '../../../../../containers/actions/common/saslAction';
import {
  downloadCustomUploadedFile,
  loadTableData,
} from '../../../../../containers/actions/common/fileActions';

import statusMapper from '../../models/statusMapper';
// Backdrop while updating
import Backdrop from '../../../models/BackDrop';
import DropFilesAttach from '../../../models/files/DropFilesAttach';
import ShowFiles from '../../../models/files/ShowFiles';
import { RejectionRemarks } from '../../../models/dialogs/CommonDialogs';
import { getFeaturesAccess } from '../../../../../containers/actions/common/accessControl';
import { getAllConfigurationFlags } from '../../../../../containers/actions/common/reportsActions';
import GenerateBD from './GenerateBD';
import varianceAlertWrapper from '../../common/varianceAlertWrapper';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import MandatorySpan from '../../models/MandatorySpan';
import FileUploads from './FileUploads';

const Styles = (theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  grid: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
    // paddingRight: '1.5rem',
    // paddingLeft: '2rem',
  },

  gridEnd: {
    paddingBottom: '1rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
    paddingLeft: '2rem',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'end',
    },
  },

  font: {
    fontWeight: 500,
    fontSize: '1rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

class ViewEditBatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reasonForRejection: '',
      reasonForCoARejection: '',
      qaReasonForChangeInReleaseDecision: '',
      uploadCoAFileDecision: false,
      recordSaved: false,
      statusUpdate: false,
      saveBatch: false,
      controlWarning: false,
      approveBatch: false,
      sendForApprovalBatch: false,
      GRNApproved: false,
      GRNSentForApproval: false,
      loading: true,
      resError: null,
      disableSaveButton: false,
      disableRecordRejected: false,
      batchDetails: {},
      newFiles: [],
      existingFiles: [],
      newCOAFiles: [],
      newEInvoiceFiles: [],
      newEWayBillFiles: [],
      batchStatusList: [],
      isTextfieldError: false,
      qaRejectedReasonError: false,
      recordAlternative: false,
      consumptionScreen: false,
      varianceThresholdAlertScreen: false,
      filesSize: 0,
      bomData: {},
      thresholdData: {},
      approveQA: false,
      rejectQA: false,
      qaStatusDecision: false,
      QAStatusUpdated: false,
      coaDownloadedCheck: false,
      sendForCoAApproval: false,
      // data: [],
      errors: {
        readyForDispatchQuantity: '',
        controlSampleQuantity: '',
        manufacturingDate: '',
        expiryDate: '',
        batchStartDate: '',
        batchEndDate: '',
      },
    };
  }

  componentDidMount() {
    const data = {
      schrockenDeliveryScheduleBatchID:
        this.props.schrockenDeliveryScheduleBatchID,
      schrockenDeliveryScheduleID: this.props.schrockenDeliveryScheduleID,
    };

    let statusList = {
      SASLBatchStatusID: this.props.SASLBatchStatusID,
      statusDisplayName: this.props.statusDisplayName,
    };
    if (this.props.fgBatchScreenAccess)
      this.props.fetchNextStatusList(statusList);
    if (this.props.fgBatchScreenAccess) this.props.fetchDSBatchDetails(data);
    if (this.props.fgBatchScreenAccess && !this.props.fgBatchFeatures)
      this.props.getFeaturesAccess(11, 'fgBatchFeatures');
    if (!this.props.featuresAndValidations)
      this.props.getAllConfigurationFlags();
  }

  componentWillUnmount() {
    if (dsBatchSource) {
      dsBatchSource.cancel('cancelled');
    }
    if (nextBatchSASLSource) {
      nextBatchSASLSource.cancel('cancelled');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.nextStatus !== this.props.nextStatus) {
      this.setState({
        batchStatusList: this.props.nextStatus.data,
        // loading: false,
      });
    }
    if (this.props.batchItemDetails !== prevProps.batchItemDetails) {
      this.setState({
        loading: false,
        batchDetails: this.props.batchItemDetails.data,
      });
    }
    if (
      this.props.approveBatchItem !== prevProps.approveBatchItem &&
      this.props.approveBatchItem.status === true
    ) {
      this.handleConfirmation();
    }

    if (
      this.props.approveBatchItem !== prevProps.approveBatchItem &&
      this.props.approveBatchItem.status === false &&
      this.props.approveBatchItem.grnGenerated === true
    ) {
      const data = {
        schrockenDeliveryScheduleBatchID:
          this.props.schrockenDeliveryScheduleBatchID,
      };
      this.props.fetchDSBatchDetails(data);

      if (typeof this.props.approveBatchItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.approveBatchItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.approveBatchItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.approveBatchItem.errorDescription,
        });
      }
      this.scrollToTop();
    }

    if (
      this.props.approveBatchItem !== prevProps.approveBatchItem &&
      this.props.approveBatchItem.status === false &&
      this.props.approveBatchItem.grnGenerated === false
    ) {
      if (typeof this.props.approveBatchItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.approveBatchItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.approveBatchItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.approveBatchItem.errorDescription,
        });
      }
      this.scrollToTop();
    }

    if (
      this.props.updateBatchItem !== prevProps.updateBatchItem &&
      this.props.updateBatchItem.status === true
    ) {
      this.handleSave();
    }

    if (
      this.props.updateBatchItem !== prevProps.updateBatchItem &&
      this.props.updateBatchItem.status === false
    ) {
      if (typeof this.props.updateBatchItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.updateBatchItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.updateBatchItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.updateBatchItem.errorDescription,
        });
      }
      this.scrollToTop();
    }
    // rejectApprovalItem

    if (
      this.props.rejectApprovalItem !== prevProps.rejectApprovalItem &&
      this.props.rejectApprovalItem.status === true
    ) {
      // this.handleSendApproval();
      this.setState({
        resError: null,
        recordAlternative: true,
      });
    }

    if (
      this.props.rejectApprovalItem !== prevProps.rejectApprovalItem &&
      this.props.rejectApprovalItem.status === false
    ) {
      if (typeof this.props.rejectApprovalItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.rejectApprovalItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.rejectApprovalItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.rejectApprovalItem.errorDescription,
        });
      }

      this.scrollToTop();
    }

    if (
      this.props.sendApprovalItem !== prevProps.sendApprovalItem &&
      this.props.sendApprovalItem.status === true
    ) {
      let status = this.props.batchItemDetails.data.SASLBatchStatusID;
      if (
        this.props.featuresAndValidations.features?.FG.qualityInspectionEnabled
      ) {
        this.setState({
          title:
            status === 15
              ? 'Record resubmitted for QC/QA approval'
              : status === 5
              ? 'Record sent for QC/QA approval'
              : 'Record sent for GRN approval',
        });
      } else if (
        this.props.featuresAndValidations?.features?.qualityApprovalEnabled &&
        ![5, 13, 15].includes(status)
      ) {
        this.setState({
          title: 'Sent for Approval',
        });
      } else {
        this.setState({ title: 'Record sent for GRN approval' });
      }
      this.handleSendApproval();
    }

    if (
      this.props.sendApprovalItem !== prevProps.sendApprovalItem &&
      this.props.sendApprovalItem.status === false
    ) {
      if (typeof this.props.sendApprovalItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.sendApprovalItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.sendApprovalItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.sendApprovalItem.errorDescription,
        });
      }

      this.scrollToTop();
    }

    if (
      this.props.qaApprovalItem !== prevProps.qaApprovalItem &&
      this.props.qaApprovalItem.status === true
    ) {
      this.setState({
        resError: null,
        QAStatusUpdated: true,
      });
    }

    if (
      this.props.qaApprovalItem !== prevProps.qaApprovalItem &&
      this.props.qaApprovalItem.status === false
    ) {
      if (typeof this.props.qaApprovalItem.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.qaApprovalItem.errorDescription
        )[0];
        this.setState({
          resError: this.props.qaApprovalItem.errorDescription[errorKey],
        });
      } else {
        this.setState({
          resError: this.props.qaApprovalItem.errorDescription,
        });
      }

      this.scrollToTop();
    }
  }

  // Edit input fields
  handleChange = (e) => {
    // const { batchSize } = this.state.batchDetails;
    const { name, value } = e.target;

    if (value < 0) {
      alert('Invalid produced quantity');
      this.setState((prevState) => ({
        resError: null,
        batchDetails: {
          ...prevState.batchDetails,
          [name]: 0,
        },
        errors: {},
      }));
    } else {
      this.setState((prevState) => ({
        resError: null,
        batchDetails: {
          ...prevState.batchDetails,
          [name]: parseFloat(value),
        },
        errors: {},
      }));
    }
  };

  // Edit Date Fields
  handleDateChange = (date, name) => {
    this.setState((prevState) => ({
      resError: null,
      batchDetails: {
        ...prevState.batchDetails,
        [name]: date != null ? date.toISOString() : date,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  fileHandling = (file, name) => {
    this.setState({
      newFiles: [...this.state.newFiles, file],
      resError: null,
    });
  };

  //Custom file upload, download and remove

  handleDownloadFile = (schrockenCustomFileID, type) => {
    const data = {
      schrockenCustomFileID,
    };
    this.props.downloadCustomUploadedFile(data, type);
    //console.log("Download files")
  };

  handleUploadFiles = (files, type) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        if (this.state.filesSize + file.size > 20000000) {
          this.setState({
            resError:
              'File(s) size limit exceeded. Please attach file(s) size less than 20MB',
          });
          setTimeout(() => this.scrollToTop(), 100);
        } else {
          let fileObject = {
            name: file.name,
            data: reader.result,
            schrockenFileID: '',
            customFileType: type || 'buffer',
            schrockenCustomFileID: '',
            customFileHash: '',
            size: file.size,
          };
          this.setState((prevState) => ({
            [type]: [...prevState[type], fileObject],
            filesSize: prevState.filesSize + file.size,
          }));
          if (this.state.resError?.includes('file'))
            this.setState({ resError: null });
        }
      };
    });
  };

  handleDelete = (id, fileName, type) => {
    const newFiles = this.state[type];
    const edit_newFiles = newFiles.filter((file, i) => {
      if (file.name === fileName) {
        this.setState((prevState) => ({
          filesSize: prevState.filesSize - file.size,
        }));
        return false;
      } else {
        return true;
      }
    });
    this.setState({
      [type]: edit_newFiles,
    });
    if (this.state.resError?.includes('size limit exceeded'))
      this.setState({ resError: null });
  };

  removeFile = (file, name) => {
    this.setState({
      [name]: file,
      resError: null,
    });
  };

  handleSave = () => {
    this.setState({
      saveBatch: true,
      resError: null,
    });
  };

  handleConfirmation = () => {
    this.setState({
      GRNApproved: true,
      resError: null,
    });
  };
  handleSendApproval = () => {
    this.setState({
      GRNSentForApproval: true,
      resError: null,
    });
  };

  handleText = (e) => {
    if (e.target.value.length < 256) {
      this.setState({
        reasonForRejection: e.target.value,
        isTextfieldError: false,
      });
    }
  };

  handleConsumptionDialog = () => {
    this.setState({
      consumptionScreen: !this.state.consumptionScreen,
    });
  };
  handleCloseApprove = () => {
    this.setState({
      approveBatch: false,
      sendForApprovalBatch: false,
      disableSaveButton: false,
      varianceThresholdAlertScreen: false,
      resError: null,
    });
  };

  handleCloseApproveWarning = () => {
    this.setState({
      controlWarning: false,
      disableSaveButton: false,
      resError: null,
    });
  };
  handleCloseSave = () => {
    this.setState({
      saveBatch: false,
      disableSaveButton: false,
      GRNApproved: false,
      GRNSentForApproval: false,
      resError: null,
      recordAlternative: false,
      QAStatusUpdated: false,
    });
    //
    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
    };

    if (!this.props.batchDashboard) {
      // If it's not from the batch dashboard

      this.props.handleClose();
      this.props.fetchBatchDetails(data);
      // To trigger loading indicator on the table
      this.props.loadTableData();
    } else {
      // If it's from the batch dashboard
      this.props.handleClose();
      this.props.batchUpdated();
      this.props.loadTableData();
    }
  };

  scrollToTop = () => {
    setTimeout(
      () => this.el.scrollIntoView({ behavior: 'smooth', block: 'start' }),
      100
    );
  };

  // control sample check
  checkControlSample = () => {
    let err = false;
    let { batchDetails } = this.state;
    if (
      batchDetails.controlSampleQuantity === 0 ||
      isNaN(batchDetails.controlSampleQuantity)
    ) {
      if (batchDetails.controlSampleQuantity === 0) {
        this.setState({
          sendForApprovalBatch: false,
          controlWarning: true,
          resError: 'Control Sample Quantity is 0',
        });
      } else {
        this.setState({
          resError: 'Please Enter Control Sample Quantity.',
        });
        this.scrollToTop();
      }
      err = true;
    }
    return err;
  };
  // Form data validation
  validate = () => {
    let isError = false;

    let { batchDetails } = this.state;
    let errors = {};
    if (batchDetails.SASLBatchStatusID >= 1 && !batchDetails.batchSize) {
      isError = true;
      this.setState({ resError: 'Please enter Batch Size' });
    }
    // This is for the produced quantity validation in the FrontEnd. Uncomment if needed.
    // else if (
    //   batchDetails.producedQuantity !== null &&
    //   !isNaN(batchDetails.producedQuantity) &&
    //   batchDetails.producedQuantity !== 0 &&
    //   batchDetails.producedQuantity > batchDetails.batchSize &&
    //   batchDetails.SASLBatchStatusID === 5
    // ) {
    //   isError = true;
    //   errors.producedQuantity =
    //     'Produced quantity should be less than or equal to batch size';
    //   this.setState({
    //     resError:
    //       'Produced quantity should be less than or equal to batch size',
    //   });
    // } else if (
    //   batchDetails.producedQuantity !== null &&
    //   !isNaN(batchDetails.producedQuantity) &&
    //   batchDetails.producedQuantity !== 0 &&
    //   batchDetails.producedQuantity !==
    //     batchDetails.readyForDispatchQuantity +
    //       batchDetails.controlSampleQuantity &&
    //   batchDetails.SASLBatchStatusID === 5
    // ) {
    //   isError = true;
    //   errors.producedQuantity =
    //     'Produced quantity should be equal to the sum of Ready for Dispatch Quantity and Control Sample Quantity';
    //   this.setState({
    //     resError:
    //       'Produced quantity should be equal to the sum of Ready for Dispatch Quantity and Control Sample Quantity',
    //   });
    // }
    else if (
      batchDetails.SASLBatchStatusID >= 2 &&
      batchDetails.batchStartDate === null
    ) {
      isError = true;
      errors.batchStartDate = 'Please Enter Start Date';
      this.setState({ resError: 'Please Enter Start Date' });
    } else if (
      batchDetails.SASLBatchStatusID >= 3 &&
      batchDetails.batchEndDate === null
    ) {
      isError = true;
      errors.batchEndDate = 'Please Enter End Date';
      this.setState({ resError: 'Please Enter End Date' });
    } else if (batchDetails.SASLBatchStatusID >= 5) {
      if (
        isNaN(batchDetails.readyForDispatchQuantity) ||
        batchDetails.readyForDispatchQuantity <= 0
      ) {
        isError = true;
        errors.readyForDispatchQuantity =
          'Please Enter ready for dispatch Quantity';

        this.setState({
          resError: 'Please Enter ready for dispatch Quantity.',
        });
      } else if (batchDetails.manufacturingDate === null) {
        isError = true;
        errors.manufacturingDate = 'Please Enter manufacturing Date';
        this.setState({
          resError: 'Please Enter manufacturing Date.',
        });
      } else if (batchDetails.expiryDate === null) {
        isError = true;
        errors.expiryDate = 'Please Enter Expiry Date';
        this.setState({
          resError: 'Please Enter Expiry Date.',
        });
      } else if (
        batchDetails.SASLBatchStatusID ===
          this.props.featuresAndValidations.validations?.FG.DSBatch.QAInfo
            .coaFile.status &&
        batchDetails.existingCOAFiles.length < 1 &&
        this.state.newCOAFiles.length < 1 &&
        this.props.featuresAndValidations.validations?.FG.DSBatch.QAInfo.coaFile
          .required &&
        !this.props.featuresAndValidations?.features?.qualityApprovalEnabled
      ) {
        isError = true;
        this.setState({
          resError: 'Please upload COA file.',
        });
      }
      // Check if the new COA file is uploaded in Repeat QC/QA status
      else if (batchDetails.bomConsumptionAddedIndicator === false) {
        isError = true;
        this.setState({
          resError: 'Please Update Consumption Details',
        });
      } else if (
        batchDetails.SASLBatchStatusID === 15 &&
        this.state.newCOAFiles.length === 0
      ) {
        isError = true;
        this.setState({
          resError: 'Please Re-upload COA file.',
        });
      } else if (
        this.props.featuresAndValidations?.features?.qualityApprovalEnabled
      ) {
        if (
          batchDetails.SASLBatchStatusID ===
            this.props.featuresAndValidations.validations?.FG.DSBatch.QAInfo
              .coaFile.status &&
          batchDetails.existingCOAFiles.length < 1 &&
          this.state.newCOAFiles.length < 1 &&
          this.props.featuresAndValidations.validations?.FG.DSBatch.QAInfo
            .coaFile.required
        ) {
          isError = true;
          this.setState({
            resError: 'Please upload COA file.',
          });
        }
      }
    }
    if (
      !this.props.featuresAndValidations?.features?.qualityApprovalEnabled &&
      (batchDetails.SASLBatchStatusID === 7 || //GRN or BD Gen statuses
        batchDetails.SASLBatchStatusID === 18) &&
      localStorage.collabType !== 'CMO' &&
      this.state.newFiles.length === 0
    ) {
      isError = true;
      this.setState({
        resError: 'No data/files updated',
      });
    } else if (
      this.props.featuresAndValidations?.features?.qualityApprovalEnabled &&
      batchDetails.SASLBatchStatusID === 7 &&
      localStorage.collabType !== 'CMO' &&
      this.state.newFiles.length === 0 &&
      this.state.newEInvoiceFiles.length === 0
    ) {
      isError = true;
      this.setState({
        resError: 'No data/files updated',
      });
    }
    this.setState({ errors });
    return isError;
  };

  onSubmit = () => {
    let isError = this.validate();
    let {
      readyForDispatchQuantity,
      controlSampleQuantity,
      MRP,
      dispatchQuantity,
      batchSize,
      producedQuantity,
    } = this.state.batchDetails;
    if (!isError) {
      let data = {
        schrockenDeliveryScheduleID: parseInt(
          this.props.schrockenDeliveryScheduleID
        ),
        batchData: {
          ...this.state.batchDetails,
          addBatchFlag: false,
          // schrockenDeliveryScheduleBatchID:
          //   this.props.schrockenDeliveryScheduleBatchID,
          statusDisplayName:
            statusMapper[this.state.batchDetails.SASLBatchStatusID],
          producedQuantity: isNaN(producedQuantity) ? 0 : producedQuantity,
          readyForDispatchQuantity: isNaN(readyForDispatchQuantity)
            ? 0
            : readyForDispatchQuantity,
          controlSampleQuantity: isNaN(controlSampleQuantity)
            ? null
            : controlSampleQuantity,
          MRP: isNaN(MRP) ? null : MRP,
          dispatchQuantity: isNaN(dispatchQuantity) ? 0 : dispatchQuantity,
          batchSize: isNaN(batchSize) ? 0 : batchSize,
          newCustomFiles: this.state.newFiles,
          // new coa files
          newCOAFiles: this.state.newCOAFiles,
          newEInvoiceFiles: this.state.newEInvoiceFiles,
          newEWayBillFiles: this.state.newEWayBillFiles,
        },
      };

      //When CQA Flow is enabled it enters if block
      // if (this.props.featuresAndValidations?.features?.qualityApprovalEnabled) {
      //   // The flow enters if block when batch status is GRN Generated and E-Invoice file is uploaded or batch status is E-Invoice Uploaded and E-Way Bill file is uploaded
      //   if (
      //     (this.state.batchDetails.SASLBatchStatusID === 7 &&
      //       this.state.newEInvoiceFiles.length > 0) ||
      //     (this.state.batchDetails.SASLBatchStatusID === 19 &&
      //       this.state.newEWayBillFiles.length > 0)
      //   ) {
      //     // Here the status is moved to 'E-Invoice Uploaded' or 'E-Way Bill Uploaded'.
      //     data = {
      //       ...data,
      //       batchData: {
      //         ...data.batchData,
      //         SASLBatchStatusID:
      //           this.state.batchStatusList[0].nextSASLBatchStatusID,
      //         statusDisplayName:
      //           this.state.batchStatusList[0].nextStatusDisplayName,
      //       },
      //     };
      //   }
      // }
      this.props.updateDSBatch(data);
    } else {
      this.scrollToTop();
    }
  };
  openAproveBatch = () => {
    this.setState({
      varianceThresholdAlertScreen: false,
      approveBatch: true,
      backDropLoading: true,
      resError: null,
    });
  };
  onClickApprove = () => {
    this.varianceCheck();
    this.setState({
      approveBatch: false,
      //loading: true,
    });
  };

  handleRejectGRN = () => {
    this.setState({
      recordSaved: true,
    });
  };

  onClickClose = () => {
    this.setState({
      recordSaved: false,
      isTextfieldError: false,
    });
  };

  handleRejectRecord = () => {
    this.setState({
      resError: null,
      disableRecordRejected: false,
    });

    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
      batchData: {
        ...this.state.batchDetails,
        // schrockenDeliveryScheduleBatchID:
        //   this.props.schrockenDeliveryScheduleBatchID,
        newCOAFiles: this.state.newCOAFiles,
        newFiles: this.state.newFiles,
        existingFiles: this.state.existingFiles,
        rejectionText: this.state.reasonForRejection,
        action: 'resubmit',
      },
    };

    if (this.state.reasonForRejection === '') {
      this.setState({
        isTextfieldError: true,
        disableButtons: {
          recordRejected: false,
        },
      });
    } else {
      this.props.rejectDSBatchApproval(data);
      this.setState({
        recordSaved: false,
      });
    }
  };

  varianceCheck = () => {
    let { schrockenDeliveryScheduleID } = this.props;
    this.props
      .varianceAlertWrapper({
        schrockenDeliveryScheduleID,
        schrockenDeliveryScheduleBatchID:
          this.state.batchDetails.schrockenDeliveryScheduleBatchID,
        bomConsumptionAddedIndicator:
          this.state.batchDetails.bomConsumptionAddedIndicator,
        batchSize: this.state.batchDetails.batchSize,
      })
      .then((res) => {
        if (res.exceed === true) {
          console.log('variance Exceed');
          this.setState({
            varianceThresholdAlertScreen: true,
          });
        } else if (localStorage.collabType === 'CMO') {
          this.setState({
            sendForApprovalBatch: true,
          });
        } else {
          this.setState({
            approveBatch: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          resError:
            err?.errMessage || 'Something went wrong. Please try again later',
        });
        this.scrollToTop();
      });
  };
  onClickSendForApproval = () => {
    let isError = this.validate();
    let { batchDetails } = this.state;
    // if (
    //   localStorage.collabType === 'CMO' &&
    //   this.props.featuresAndValidations?.features?.qualityApprovalEnabled &&
    //   batchDetails.SASLBatchStatusID === 5 &&
    //   batchDetails.existingCOAFiles.length < 1 &&
    //   this.state.newCOAFiles.length < 1 &&
    //   this.props.featuresAndValidations.validations?.FG.DSBatch.QAInfo.coaFile
    //     .required
    // ) {
    //   isError = true;
    //   this.setState({
    //     resError: 'Please upload COA file.',
    //   });
    // }
    if (
      this.props.featuresAndValidations?.features?.qualityApprovalEnabled &&
      batchDetails.qaStatusID === '3' &&
      this.state.newCOAFiles.length === 0 &&
      this.props.batchItemDetails.data.existingCOAFiles.filter(
        (val) => val.markedForDelete === null || !val.markedForDelete
      ).length === 0
    ) {
      isError = true;
      this.setState({
        resError: 'Please Re-upload COA file.',
      });
    }

    if (
      !isError &&
      [5, 13].includes(batchDetails.SASLBatchStatusID) &&
      this.props.featuresAndValidations?.features?.qualityApprovalEnabled
    ) {
      if (
        this.state.newCOAFiles.length === 0 &&
        this.state.batchDetails.existingCOAFiles.length === 0 &&
        !this.state.uploadCoAFileDecision
      ) {
        this.setState({
          uploadCoAFileDecision: true,
        });
      } else {
        this.setState({
          uploadCoAFileDecision: false,
        });
        let controlCheck = this.checkControlSample();
        if (!controlCheck) {
          this.varianceCheck();
        }
      }
    } else if (
      !isError &&
      !this.props.featuresAndValidations?.features?.qualityApprovalEnabled
    ) {
      let controlCheck = this.checkControlSample();
      if (!controlCheck) {
        this.varianceCheck();
      }
    } else if (
      !isError &&
      ![5, 13, 15].includes(batchDetails.SASLBatchStatusID) &&
      this.props.featuresAndValidations?.features?.qualityApprovalEnabled
    ) {
      this.setState({
        sendForCoAApproval: true,
      });
    } else {
      this.scrollToTop();
      this.setState({ sendForApprovalBatch: false });
    }
  };
  onApprove = () => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
      batchData: {
        ...this.state.batchDetails,
        // schrockenDeliveryScheduleBatchID:
        //   this.props.schrockenDeliveryScheduleBatchID,
        newCOAFiles: this.state.newCOAFiles,
        newFiles: this.state.newFiles,
        existingFiles: this.state.existingFiles,
        action: 'approve',
      },
    };
    this.props.approveDSBatch(data);
    this.setState({
      varianceThresholdAlertScreen: false,
      approveBatch: false,
    });
  };

  onSendForApproval = () => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
      batchData: {
        ...this.state.batchDetails,
        // schrockenDeliveryScheduleBatchID:
        //   this.props.schrockenDeliveryScheduleBatchID,
        newCOAFiles: this.state.newCOAFiles,
        newFiles: this.state.newFiles,
        existingFiles: this.state.existingFiles,
      },
    };
    console.log(data);
    this.props.sendDSBatchForApproval(data);
    this.setState({
      varianceThresholdAlertScreen: false,
      sendForApprovalBatch: false,
      sendForCoAApproval: false,
    });
  };

  onhandleControlWarning = () => {
    this.varianceCheck();
    this.setState({ controlWarning: false });
  };

  handleFetchCall = () => {
    this.setState({
      // loading: true,
      batchDetails: {
        ...this.state.batchDetails,
        bomConsumptionAddedIndicator: true,
      },
    });
  };
  stoError = (error) => {
    this.setState({
      resError: error,
    });
    this.scrollToTop();
  };

  handleQAApproveDialog = () => {
    this.setState({
      approveQA: !this.state.approveQA,
    });
  };

  handleRejectQADialog = () => {
    this.setState({
      rejectQA: !this.state.rejectQA,
      qaRejectedReasonError: false,
    });
  };

  handleQADecisionChange = () => {
    this.setState({
      qaStatusDecision: !this.state.qaStatusDecision,
      qaRejectedReasonError: false,
    });
  };

  handleQAApprovalRequest = (isApproved, isReversed) => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(
        this.props.schrockenDeliveryScheduleID
      ),
      batchData: {
        ...this.state.batchDetails,
        // schrockenDeliveryScheduleBatchID:
        //   this.props.schrockenDeliveryScheduleBatchID,
        newCOAFiles: this.state.newCOAFiles,
        newFiles: this.state.newFiles,
        existingFiles: this.state.existingFiles,
        qaRejectionReason: this.state.reasonForCoARejection,
        qaReasonForChangeInReleaseDecision:
          this.state.qaReasonForChangeInReleaseDecision,
      },
      isApproved: isApproved,
      isReversed: isReversed,
    };

    if (
      (!isApproved &&
        !isReversed &&
        this.state.reasonForCoARejection === '' &&
        this.state.batchDetails.qaStatusID === '1') ||
      (!isApproved &&
        isReversed &&
        this.state.qaReasonForChangeInReleaseDecision === '' &&
        this.state.batchDetails.qaStatusID === '2')
    ) {
      this.setState({
        qaRejectedReasonError: true,
      });
    } else {
      this.props.requestQAApproval(data, isApproved, isReversed);
      this.setState({
        approveQA: false,
        rejectQA: false,
        qaStatusDecision: false,
      });
    }
  };

  onRejectCoATextChange = (e) => {
    if (e.target.value.length < 256) {
      this.setState({
        reasonForCoARejection: e.target.value,
        qaRejectedReasonError: false,
      });
    }
  };

  onReleaseDecisionTextChange = (e) => {
    if (e.target.value.length < 256) {
      this.setState({
        qaReasonForChangeInReleaseDecision: e.target.value,
        qaRejectedReasonError: false,
      });
    }
  };

  handleCoACheck = (e) => {
    this.setState({
      coaDownloadedCheck: e.target.checked,
    });
  };

  handleCloseCoAApproval = () => {
    this.setState({
      sendForCoAApproval: false,
    });
  };

  render() {
    const { classes, qaApproval, qaStatusText } = this.props;
    const { loading, errors, batchDetails, resError, batchStatusList } =
      this.state;

    return (
      <React.Fragment>
        {loading === false ? (
          <React.Fragment>
            <Backdrop />
            <Grid
              container
              justifyContent='center'
              style={{ paddingLeft: '2rem' }}
            >
              <Grid item xs={12} sm={11}>
                <Grid item xs={12} sm={10} md={11}>
                  <Grid container>
                    <Grid
                      ref={(el) => {
                        this.el = el;
                      }}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ backgroundColor: '#FAFAFA', padding: '1rem' }}
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{ justifyContent: 'start' }}
                      >
                        <Grid item xs={6} sm={3}>
                          <Typography variant='body2' className={classes.font}>
                            Current Status
                          </Typography>
                          <ShowStatus
                            batchDetails={batchDetails}
                            handleChange={this.handleChange}
                            classes={classes}
                            previousStatusID={this.props.SASLBatchStatusID}
                            previousStatusDisplayName={
                              this.props.statusDisplayName
                            }
                            batchStatusList={batchStatusList}
                            qualityApprovalEnabled={
                              this.props.featuresAndValidations?.features
                                ?.qualityApprovalEnabled
                            }
                            deliverySchedulesFeatures={
                              this.props.deliverySchedulesFeatures
                            }
                          />
                        </Grid>
                        {this.props.featuresAndValidations?.features
                          ?.qualityApprovalEnabled && (
                          <Grid item xs={6} sm={3}>
                            <Typography
                              variant='body2'
                              className={classes.font}
                            >
                              QA Status
                            </Typography>
                            <Typography
                              variant='body2'
                              className={classes.fontPadding}
                              style={{ fontSize: '1rem' }}
                            >
                              {qaStatusText ? qaStatusText : '-NA-'}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent='center'
                      style={{
                        padding: '1rem 0',
                        display: resError === null ? 'none' : 'inherit',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{
                          border: '1px solid #FF0000',
                          backgroundColor: '#FFF0F0',
                          opacity: '1',
                          padding: '0.8rem',
                          fontSize: '0.875rem',
                          textAlign: 'center',
                        }}
                      >
                        {/* <Typography
                          variant='h6'
                          style={{
                            border: '1px solid #FF0000',
                            backgroundColor: '#FFF0F0',
                            opacity: '1',
                            textAlign: 'center',
                            padding: '0.8rem',
                            fontSize: '0.875rem',
                          }}
                        >
                          {resError}
                        </Typography> */}

                        {resError?.split(';').map((part, index) => (
                          <Typography
                            key={index}
                            variant='h6'
                            style={{
                              fontSize: '0.875rem',
                            }}
                          >
                            {part}
                            <br />
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>

                  {this.props.fgBatchFeatures?.fgBatchGRInfo && (
                    <Grid container justifyContent='center'>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.grid}
                      >
                        <General data={batchDetails} />
                        <hr />
                      </Grid>
                    </Grid>
                  )}

                  {this.props.fgBatchFeatures?.fgBatchInfo && (
                    <BatchInformation
                      batchDetails={batchDetails}
                      handleChange={this.handleChange}
                      errors={errors}
                      handleDateChange={this.handleDateChange}
                    />
                  )}

                  {batchDetails.SASLBatchStatusID >= 5 && (
                    <QualityAssuranceInformation
                      batchDetails={batchDetails}
                      errors={errors}
                      handleDateChange={this.handleDateChange}
                      handleChange={this.handleChange}
                      handleUploadFiles={(files) =>
                        this.handleUploadFiles(files, 'newCOAFiles')
                      }
                      newFiles={this.state.newCOAFiles}
                      existingFiles={
                        this.state.batchDetails.existingCOAFiles || []
                      }
                      handleDelete={(id, file) =>
                        this.handleDelete(id, file, 'newCOAFiles')
                      }
                      handleDownloadFile={this.handleDownloadFile}
                      coaDownloadedCheck={this.state.coaDownloadedCheck}
                      handleCoACheck={this.handleCoACheck}
                      qaApproval={qaApproval}
                    />
                  )}

                  {batchDetails.SASLBatchStatusID > 7 &&
                    batchDetails.SASLBatchStatusID < 10 &&
                    this.props.fgBatchFeatures?.fgBatchShippingInfo && (
                      <ShippingInformation
                        batchItemDetails={this.props.batchItemDetails}
                        batchDetails={batchDetails}
                        errors={errors}
                        handleDateChange={this.handleDateChange}
                        handleChange={this.handleChange}
                      />
                    )}

                  {batchDetails.SASLBatchStatusID > 1 && (
                    <Grid container justifyContent='center'>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.grid}
                      >
                        <Typography variant='h4' className={classes.title}>
                          Consumption Information
                        </Typography>
                        <span className={classes.borders}></span>
                        <ConsumptionInformation
                          batchDetails={batchDetails}
                          handleChange={this.handleChange}
                          errors={errors}
                          schrockenDeliveryScheduleBatchID={
                            this.state.batchDetails
                              .schrockenDeliveryScheduleBatchID
                          }
                          schrockenDeliveryScheduleID={parseInt(
                            this.props.schrockenDeliveryScheduleID
                          )}
                          handleFetchCall={this.handleFetchCall}
                          consumptionScreen={this.state.consumptionScreen}
                          handleConsumptionDialog={this.handleConsumptionDialog}
                          featuresAccess={this.props.featuresAccess}
                          fgBatchFeatures={this.props.fgBatchFeatures}
                        />
                        <hr />
                      </Grid>
                    </Grid>
                  )}

                  {[7, 8, 9, 19, 20].includes(batchDetails.SASLBatchStatusID) &&
                    Array.isArray(
                      this.props.batchItemDetails.data.existingCOAFiles
                    ) &&
                    this.props.batchItemDetails.data.existingCOAFiles.length >
                      0 &&
                    this.props.featuresAndValidations?.features
                      ?.qualityApprovalEnabled &&
                    ((this.props.deliverySchedulesFeatures?.updateToEinvoice && //Added condition that the user with upload access can view section when status is in 'GRN Generated'. Other user can view it after E-Invoice is uploaded.
                      batchDetails.SASLBatchStatusID === 7) ||
                      batchDetails.SASLBatchStatusID !== 7) && (
                      <FileUploads
                        title={'Upload E-Invoice'}
                        fileType={'E-InvoiceUpload'}
                        batchDetails={batchDetails}
                        handleUploadFiles={(files) =>
                          this.handleUploadFiles(files, 'newEInvoiceFiles')
                        }
                        newFiles={this.state.newEInvoiceFiles}
                        existingFiles={
                          this.state.batchDetails.existingEInvoiceFiles || []
                        }
                        handleDelete={(id, file) =>
                          this.handleDelete(id, file, 'newEInvoiceFiles')
                        }
                        handleDownloadFile={this.handleDownloadFile}
                        uploadFeatureAccess={
                          this.props.deliverySchedulesFeatures?.updateToEinvoice
                        }
                      />
                    )}

                  {[8, 9, 19, 20].includes(batchDetails.SASLBatchStatusID) &&
                    Array.isArray(
                      this.props.batchItemDetails.data.existingEInvoiceFiles
                    ) &&
                    this.props.batchItemDetails.data.existingEInvoiceFiles
                      .length > 0 &&
                    this.props.featuresAndValidations?.features
                      ?.qualityApprovalEnabled &&
                    ((this.props.deliverySchedulesFeatures?.updateToEwayBill && // Spira ID: 2582, Batch details page does not display Upload E-Way Bill section for CMO for FG Batch in E-Way Bill Uploaded status
                      batchDetails.SASLBatchStatusID === 19) || //Resolved by removing localStorage.collabType !== 'CMO' condition and adding condition that the user with upload access can view section when status is in 'E-Invoice Uploaded'. Other user can view it after E-way bill is uploaded.
                      batchDetails.SASLBatchStatusID !== 19) && (
                      <FileUploads
                        title={'Upload E-Way Bill'}
                        fileType={'E-WayBill'}
                        batchDetails={batchDetails}
                        handleUploadFiles={(files) =>
                          this.handleUploadFiles(files, 'newEWayBillFiles')
                        }
                        newFiles={this.state.newEWayBillFiles}
                        existingFiles={
                          this.state.batchDetails.existingEWayBillFiles || []
                        }
                        handleDelete={(id, file) =>
                          this.handleDelete(id, file, 'newEWayBillFiles')
                        }
                        handleDownloadFile={this.handleDownloadFile}
                        uploadFeatureAccess={
                          this.props.deliverySchedulesFeatures?.updateToEwayBill
                        }
                      />
                    )}

                  {/*  Custom file feature: Upload, view and download custom files. */}
                  {this.props.fgBatchFeatures?.fgBatchUploadDocuments && (
                    <Grid container justifyContent='center'>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.grid}
                        style={{ paddingBottom: '0.7rem' }}
                      >
                        <DropFilesAttach
                          titleComponent={
                            <React.Fragment>
                              <Typography
                                variant='h4'
                                className={classes.title}
                              >
                                Upload Custom Files
                              </Typography>
                              <span className={classes.borders}></span>
                              <br />
                            </React.Fragment>
                          }
                          handleUploadFiles={(files) =>
                            this.handleUploadFiles(files, 'newFiles')
                          }
                        />
                      </Grid>
                      <ShowFiles
                        files={this.state.newFiles}
                        clearable
                        clearFile={(id, file) =>
                          this.handleDelete(id, file, 'newFiles')
                        }
                        removeNoRecordsMsg
                      />
                    </Grid>
                  )}

                  <Grid
                    container
                    // spacing={10}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-start'
                  >
                    <Grid item xs={3}>
                      <Typography variant='h4' className={classes.title}>
                        Download Custom Files
                      </Typography>
                      <span className={classes.borders}></span>
                      <br />
                      <ShowFiles
                        files={this.state.batchDetails.existingCustomFiles}
                        downloadable={
                          this.props.fgBatchFeatures?.downloadFGDocuments
                        }
                        downloadFile={this.handleDownloadFile}
                      />
                    </Grid>
                  </Grid>

                  {qaApproval ? (
                    <Grid container>
                      {localStorage.collabType !== 'CMO' &&
                        this.props.featuresAndValidations?.features
                          ?.qualityApprovalEnabled &&
                        batchDetails.qaStatusID === '1' &&
                        [6, 7, 8, 9, 13, 19, 20].includes(
                          batchDetails.SASLBatchStatusID
                        ) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.grid}
                          >
                            <StyledButton
                              onClick={this.handleQAApproveDialog}
                              disabled={!this.state.coaDownloadedCheck}
                              color='primary'
                            >
                              {'Approve for Release'}
                            </StyledButton>
                          </Grid>
                        )}

                      {localStorage.collabType !== 'CMO' &&
                        this.props.featuresAndValidations?.features
                          ?.qualityApprovalEnabled &&
                        batchDetails.qaStatusID === '1' &&
                        [6, 7, 8, 9, 13, 19, 20].includes(
                          batchDetails.SASLBatchStatusID
                        ) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.gridEnd}
                          >
                            <StyledButton
                              onClick={this.handleRejectQADialog}
                              disabled={!this.state.coaDownloadedCheck}
                              color='secondary'
                            >
                              {'Reject for Release'}
                            </StyledButton>
                          </Grid>
                        )}
                      {localStorage.collabType !== 'CMO' &&
                        this.props.featuresAndValidations?.features
                          ?.qualityApprovalEnabled &&
                        (batchDetails.qaStatusID === '2' ||
                          batchDetails.qaStatusID === '3') && (
                          <Grid container item justifyContent='center'>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              className={classes.grid}
                              style={{ textAlign: 'center' }}
                            >
                              <StyledButton
                                onClick={this.handleQADecisionChange}
                                disabled={!this.state.coaDownloadedCheck}
                                color='secondary'
                              >
                                {'Change Release Decision'}
                              </StyledButton>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  ) : (
                    <Grid container>
                      {localStorage.collabType === 'CMO' &&
                        // Disable Save option on Repeat QC/QA
                        batchDetails.SASLBatchStatusID !== 15 && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.grid}
                          >
                            <StyledButton
                              onClick={this.onSubmit}
                              disabled={this.state.disableSaveButton}
                            >
                              Save changes
                            </StyledButton>
                          </Grid>
                        )}

                      {localStorage.collabType !== 'CMO' &&
                        (batchDetails.SASLBatchStatusID === 9 ||
                          batchDetails.SASLBatchStatusID === 7 ||
                          batchDetails.SASLBatchStatusID === 18 ||
                          batchDetails.SASLBatchStatusID === 19) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.grid}
                          >
                            <StyledButton
                              onClick={this.onSubmit}
                              disabled={this.state.disableSaveButton}
                            >
                              Save changes
                            </StyledButton>
                          </Grid>
                        )}
                      {localStorage.collabType !== 'CMO' &&
                        batchDetails.SASLBatchStatusID === 7 &&
                        this.props.featuresAndValidations.features?.FG
                          .BDTriggerEnabled && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.gridEnd}
                          >
                            <GenerateBD
                              data={[
                                {
                                  scheduleAgreementNumber:
                                    batchDetails.scheduleAgreementNumber,
                                  scheduleLineItemNumber:
                                    batchDetails.scheduleLineItemNumber,
                                  vendorBatchNumber:
                                    batchDetails.vendorBatchNumber,
                                  batchUOM: batchDetails.batchUOM,
                                  schrockenDeliveryScheduleBatchID:
                                    this.state.batchDetails
                                      .schrockenDeliveryScheduleBatchID,
                                  deliverableQuantity: parseFloat(
                                    Number(
                                      batchDetails.readyForDispatchQuantity
                                    ).toFixed(3)
                                  ),
                                  fgMaterialCode: batchDetails.fgMaterialCode,
                                },
                              ]}
                              onSuccess={this.handleCloseSave}
                              onError={(data) => this.stoError(data)}
                            />
                          </Grid>
                        )}

                      {localStorage.collabType === 'CMO' &&
                        (this.props.batchItemDetails.data.SASLBatchStatusID ===
                          5 ||
                          this.props.batchItemDetails.data.SASLBatchStatusID ===
                            13 ||
                          this.props.batchItemDetails.data.SASLBatchStatusID ===
                            15 ||
                          ([5, 6, 7, 8, 13, 19, 20].includes(
                            this.props.batchItemDetails.data.SASLBatchStatusID
                          ) &&
                            (this.props.batchItemDetails.data.qaStatusID ===
                              '3' ||
                              !this.props.batchItemDetails.data.qaStatusID) &&
                            this.props.featuresAndValidations?.features
                              ?.qualityApprovalEnabled)) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={
                              batchDetails.SASLBatchStatusID === 15
                                ? classes.grid
                                : classes.gridEnd
                            }
                          >
                            <StyledButton
                              onClick={this.onClickSendForApproval}
                              disabled={
                                this.props.featuresAndValidations?.features
                                  ?.qualityApprovalEnabled &&
                                this.props.batchItemDetails.data.qaStatusID ===
                                  '3'
                                  ? this.state.newCOAFiles.length === 0 &&
                                    this.props.batchItemDetails.data.existingCOAFiles.filter(
                                      (val) =>
                                        val.markedForDelete === null ||
                                        !val.markedForDelete
                                    ).length === 0
                                  : this.state.disableSaveButton
                              }
                              color='secondary'
                            >
                              Send for Approval
                            </StyledButton>
                          </Grid>
                        )}
                      {localStorage.collabType !== 'CMO' &&
                        (batchDetails.SASLBatchStatusID === 6 ||
                          // Provide Approve option for QC/QA
                          batchDetails.SASLBatchStatusID === 14) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.grid}
                          >
                            <StyledButton
                              onClick={this.onClickApprove}
                              disabled={this.state.disableSaveButton}
                              color='primary'
                            >
                              {batchDetails.SASLBatchStatusID === 6
                                ? 'Approve GRN'
                                : 'Approve QC/QA'}
                            </StyledButton>
                          </Grid>
                        )}

                      {localStorage.collabType !== 'CMO' &&
                        (batchDetails.SASLBatchStatusID === 6 ||
                          batchDetails.SASLBatchStatusID === 14) && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={classes.gridEnd}
                          >
                            <StyledButton
                              onClick={this.handleRejectGRN}
                              disabled={this.state.disableSaveButton}
                              color='secondary'
                            >
                              {batchDetails.SASLBatchStatusID === 6
                                ? 'Reject GRN'
                                : 'Repeat QC/QA'}
                            </StyledButton>
                          </Grid>
                        )}
                    </Grid>
                  )}

                  <RejectionRemarks
                    open={this.state.recordSaved}
                    title={
                      batchDetails.SASLBatchStatusID === 14
                        ? 'Send for Repeat QC/QA'
                        : 'Record Rejected'
                    }
                    textLabel={
                      batchDetails.SASLBatchStatusID === 14
                        ? 'Add remarks for repeating QC/QA'
                        : 'Add remarks for rejection'
                    }
                    subtitle={
                      batchDetails.SASLBatchStatusID === 14
                        ? 'Record will be sent to CMO for repeating QC/QA'
                        : 'Add remarks for rejection'
                    }
                    helperText={
                      batchDetails.SASLBatchStatusID === 14
                        ? 'Please add reasons for repeating QC/QA'
                        : 'Please add your reason for rejecting the Record'
                    }
                    handleAddReason={this.handleRejectRecord}
                    onClose={this.onClickClose}
                    onTextChange={this.handleText}
                    textValue={this.state.reasonForRejection}
                    disabled={this.state.disableRecordRejected}
                    error={this.state.isTextfieldError}
                  />
                  <Dialog
                    open={this.state.recordAlternative}
                    onClose={this.handleCloseSave}
                    fullWidth
                  >
                    <StyledDialog
                      icons={true}
                      handleClose={this.handleCloseSave}
                      title='Response has been sent to the CMO to resubmit record'
                    />
                  </Dialog>

                  {/* BO approve GRN */}

                  <Dialog open={this.state.GRNApproved} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={this.handleCloseSave}
                      title={
                        batchDetails.SASLBatchStatusID === 14
                          ? 'QC/QA Approved'
                          : this.props.featuresAndValidations.features
                              ?.erpIntegration === 'SFTP'
                          ? 'Your record has been added in queue for GRN generation'
                          : 'GRN Generation Success'
                      }
                    />
                  </Dialog>
                  <Dialog open={this.state.approveBatch} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={this.onApprove}
                      handleNoButton={this.handleCloseApprove}
                      title={`Approving for ${
                        batchDetails.SASLBatchStatusID === 14
                          ? 'Quality'
                          : 'GRN'
                      }`}
                      subtitle={`Confirm approval of ${
                        batchDetails.SASLBatchStatusID === 14 ? 'QC/QA' : 'GRN'
                      }.`}
                      hasMultipleButton
                    />
                  </Dialog>

                  {/* CMO Send for Approval  */}
                  <Dialog
                    open={this.state.GRNSentForApproval || this.state.saveBatch}
                    fullWidth
                  >
                    <StyledDialog
                      icons={true}
                      handleClose={this.handleCloseSave}
                      title={
                        this.state.GRNSentForApproval
                          ? this.state.title
                          : this.state.newEInvoiceFiles.length > 0
                          ? 'E-Invoice Attached Successfully'
                          : this.state.newEWayBillFiles.length > 0
                          ? 'E-Way Bill Attached Successfully'
                          : 'Batch details have been saved.'
                      }
                    />
                  </Dialog>
                  {/* controlWarning */}
                  <Dialog
                    open={this.state.controlWarning}
                    onClose={this.handleCloseApproveWarning}
                    fullWidth
                  >
                    <StyledDialog
                      icons={true}
                      handleClose={this.onhandleControlWarning}
                      handleNoButton={this.handleCloseApproveWarning}
                      title='Control Sample Quantity is 0'
                      subtitle='Are you sure you want to send for approval'
                      hasMultipleButton
                    />
                  </Dialog>

                  <Dialog open={this.state.sendForApprovalBatch} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={this.onSendForApproval}
                      handleNoButton={this.handleCloseApprove}
                      title='Sending for approval'
                      subtitle={
                        this.props.featuresAndValidations.features?.FG
                          .qualityInspectionEnabled &&
                        (batchDetails.SASLBatchStatusID === 5 ||
                          batchDetails.SASLBatchStatusID === 15)
                          ? 'Confirm sending for QC/QA Approval'
                          : 'Confirm sending for approval of GRN.'
                      }
                      hasMultipleButton
                    />
                  </Dialog>

                  <Dialog open={this.state.sendForCoAApproval} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={this.onSendForApproval}
                      handleNoButton={this.handleCloseCoAApproval}
                      title='Sending for approval'
                      subtitle={
                        this.props.featuresAndValidations?.features
                          ?.qualityApprovalEnabled &&
                        'Confirm sending for CoA Approval'
                      }
                      hasMultipleButton
                    />
                  </Dialog>

                  <Dialog open={this.state.uploadCoAFileDecision} fullWidth>
                    <DialogContent style={{ padding: '2rem' }}>
                      <Typography
                        variant='h5'
                        style={{
                          textAlign: 'center',
                          padding: '1rem',
                          paddingBottom: '0px',
                          fontWeight: 500,
                          fontSize: '1.5rem',
                        }}
                      >
                        CoA has not been attached.
                      </Typography>
                      <Typography
                        variant='h5'
                        style={{
                          textAlign: 'center',
                          padding: '1rem',
                          paddingTop: '0px',
                          fontWeight: 500,
                          fontSize: '1.5rem',
                        }}
                      >
                        Do you want to attach it?
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          textAlign: 'center',
                          padding: '1rem',
                        }}
                      >
                        <Button
                          variant='contained'
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          className={classes.noButton}
                          onClick={() => {
                            this.onClickSendForApproval();
                          }}
                        >
                          No, I will do it later
                        </Button>
                        <StyledButton
                          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                          onClick={() =>
                            this.setState({
                              uploadCoAFileDecision: false,
                            })
                          }
                        >
                          Yes, attach it now
                        </StyledButton>
                      </Grid>
                    </DialogContent>
                  </Dialog>

                  <Dialog open={this.state.QAStatusUpdated} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={this.handleCloseSave}
                      title={'CoA Decision Updated Successfully'}
                    />
                  </Dialog>

                  <Dialog open={this.state.approveQA} fullWidth>
                    <StyledDialog
                      icons={true}
                      handleClose={() =>
                        this.handleQAApprovalRequest(true, false)
                      }
                      handleNoButton={this.handleQAApproveDialog}
                      title='Approve the CoA'
                      // subtitle={'Confirm approve for release'}
                      subtitle={'Do you want to approve the CoA?'}
                      hasMultipleButton
                    />
                  </Dialog>

                  <Dialog
                    open={this.state.rejectQA}
                    onClose={this.handleRejectQADialog}
                    fullWidth
                  >
                    <DialogContent style={{ padding: '2rem' }}>
                      <Grid container justifyContent='flex-end'>
                        <Typography
                          variant='body2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={this.handleRejectQADialog}
                        >
                          Close <CloseIcon fontSize='large' />
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <WarningIcon
                            style={{
                              fontSize: '5rem',
                              color: '#F00',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        variant='h5'
                        style={{
                          textAlign: 'center',
                          padding: '0.5rem',
                          fontWeight: 500,
                          fontSize: '1.5rem',
                        }}
                      >
                        Reject the CoA
                      </Typography>

                      <Typography
                        variant='body2'
                        style={{
                          textAlign: 'center',
                          fontSize: '1rem',
                          margin: '1rem',
                        }}
                      >
                        {/* Confirm reject for release */}
                        Do you want to reject the CoA?
                      </Typography>
                      <form noValidate autoComplete='off'>
                        <Typography
                          style={{
                            fontWeight: 500,
                            paddingTop: '0.5rem',
                            paddingBottom: '1rem',
                          }}
                        >
                          Add remarks for rejection
                          <MandatorySpan />
                        </Typography>
                        <TextField
                          // id='outlined-multiline-static'
                          value={this.state.reasonForCoARejection}
                          label={'Add remarks for rejection'}
                          multiline
                          error={this.state.qaRejectedReasonError}
                          helperText={
                            this.state.qaRejectedReasonError &&
                            'Please add your reason for rejecting.'
                          }
                          minRows={4}
                          onChange={this.onRejectCoATextChange}
                          variant='outlined'
                          fullWidth
                          style={{
                            paddingBottom: '1rem',
                          }}
                        />
                      </form>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          padding: '0.5rem',
                        }}
                      >
                        <StyledButton
                          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                          onClick={() =>
                            this.handleQAApprovalRequest(false, false)
                          }
                        >
                          Ok
                        </StyledButton>
                      </Grid>
                    </DialogContent>
                  </Dialog>

                  {/* <RejectionRemarks
                    open={this.state.rejectQA}
                    // open={true}
                    title={'Reject for Release'}
                    textLabel={'Sponsor QA Remark'}
                    subtitle={'Add remarks for rejection'}
                    helperText={
                      'Please add your reason for rejecting the Record'
                    }
                    handleAddReason={this.handleQAReject}
                    onClose={this.onClickClose}
                    onTextChange={this.handleText}
                    disabled={this.state.disableRecordRejected}
                    error={this.state.isTextfieldError}
                  /> */}

                  <Dialog open={this.state.qaStatusDecision} fullWidth>
                    <DialogContent style={{ padding: '2rem' }}>
                      <Grid container justifyContent='flex-end'>
                        <Typography
                          variant='body2'
                          style={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={this.handleQADecisionChange}
                        >
                          Close <CloseIcon fontSize='medium' />
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <WarningIcon
                            style={{
                              fontSize: '5rem',
                              color: '#F00',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '1rem',
                          fontSize: '1.2rem',
                          fontWeight: 500,
                          color: '#000',
                        }}
                      >
                        Do you want to change QA decision?
                      </Typography>
                      <Grid
                        container
                        style={{
                          justifyContent: 'space-between',
                          padding: '1rem',
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{ fontWeight: 500, paddingBottom: '5px' }}
                          >
                            Current QA Status
                          </Typography>
                          <Typography>{batchDetails?.qaStatusText}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ fontWeight: 500, paddingBottom: '5px' }}
                          >
                            Updated QA Status
                          </Typography>
                          {batchDetails.qaStatusID === '2' && (
                            <Typography>Rejected</Typography>
                          )}
                          {batchDetails.qaStatusID === '3' && (
                            <Typography>Approved</Typography>
                          )}
                        </Grid>
                      </Grid>
                      {batchDetails.qaStatusID === '2' && (
                        <form noValidate autoComplete='off'>
                          <Typography
                            style={{
                              fontWeight: 500,
                              paddingTop: '0.5rem',
                              paddingBottom: '1rem',
                            }}
                          >
                            Add remarks for change in decision <MandatorySpan />
                          </Typography>
                          <TextField
                            // id='outlined-multiline-static'
                            value={
                              this.state.qaReasonForChangeInReleaseDecision
                            }
                            label={'Add remarks for change in decision'}
                            multiline
                            error={this.state.qaRejectedReasonError}
                            helperText={
                              this.state.qaRejectedReasonError &&
                              'Enter remarks here.'
                            }
                            minRows={4}
                            onChange={this.onReleaseDecisionTextChange}
                            variant='outlined'
                            fullWidth
                            style={{
                              paddingBottom: '1rem',
                            }}
                          />
                        </form>
                      )}

                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: 'center', padding: '1rem' }}
                      >
                        <StyledButton
                          style={{ padding: '0.5rem 3rem' }}
                          onClick={() =>
                            this.handleQAApprovalRequest(false, true)
                          }
                          // disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
                        >
                          Confirm
                        </StyledButton>
                      </Grid>
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    open={this.state.varianceThresholdAlertScreen}
                    fullWidth
                  >
                    <VarianceAlertDialog
                      icons={true}
                      handleYesButton={
                        localStorage.collabType === 'CMO'
                          ? this.onSendForApproval
                          : this.openAproveBatch
                      }
                      handleClose={this.handleCloseApprove}
                      handleNoButton={this.handleConsumptionDialog}
                      errors={errors}
                      title='Variance Alert'
                      subtitle='There are materials that have crossed the allow variance limit , confirm if you want to proceed to GRN approval'
                    />
                  </Dialog>
                </Grid>
              </Grid>

              {/*
              <Grid item xs={12} sm={3}>
                {/* Chat Box here 
                <ChatBox
                  batchItemDetails={this.props.batchItemDetails}
                  item='POBatchItem'
                  schrockenDeliveryScheduleBatchID={this.props.schrockenDeliveryScheduleBatchID}
                />
              </Grid>
              */}
            </Grid>
          </React.Fragment>
        ) : (
          <Grid container justifyContent='center'>
            <div
              style={{
                textAlign: 'center',
                marginTop: '25%',
                minWidth: '60vw',
              }}
            >
              <CircularProgress color='primary' thickness={7} />
            </div>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  batchItemDetails: state.pcmp.common.sasl.DsDetails,
  updateBatchItem: state.pcmp.common.sasl.updateBatch,
  approveBatchItem: state.pcmp.common.sasl.approveBatch,
  sendApprovalItem: state.pcmp.common.sasl.sendApproval,
  backDropLoading: state.pcmp.common.files.backdropLoading,
  nextStatus: state.pcmp.common.sasl.nextStatus,
  rejectApprovalItem: state.pcmp.common.sasl.rejectApproval,
  // Features and validations
  featuresAndValidations: state.pcmp.common.reports.rmpmGrnFeatures?.data,
  // screen & feature Access
  fgBatchScreenAccess:
    state.pcmp.common.accessControl.screensAccess?.data.fgBatch.screenAccess,
  fgBatchFeatures:
    state.pcmp.common.accessControl.featuresAccess?.fgBatchFeatures,
  qaApprovalItem: state.pcmp.common.sasl.qaApproval,
  deliverySchedulesFeatures:
    state.pcmp.common.accessControl.featuresAccess?.deliverySchedulesFeatures,
});
export default connect(mapStateToProps, {
  fetchDSBatchDetails,
  updateDSBatch,
  fetchBatchDetails,
  loadTableData,
  approveDSBatch,
  fetchNextStatusList,
  sendDSBatchForApproval,
  rejectDSBatchApproval,
  downloadCustomUploadedFile,
  getFeaturesAccess,
  getAllConfigurationFlags,
  varianceAlertWrapper,
  batchUpdated,
  requestQAApproval,
})(withStyles(Styles)(ViewEditBatch));
