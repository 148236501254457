import React from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderFooterwithCookies from '../common/openPages/HeaderFooterwithCookies';
import SessionTimeoutCard from './SessionTimeoutCard';
import BottomLink from '../common/BottomLink';

const SessionTimeoutPage = ({ children, ...props }) => {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderFooterwithCookies cookiesEnabled={true}>
        <Grid container>
          <SessionTimeoutCard history={props.history} />
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <br />
            <br />
            <BottomLink
              typo='Not this user?'
              link='Sign In using your login details'
              to='/login'
            />
          </Grid>
        </Grid>
      </HeaderFooterwithCookies>
    </Grid>
  );
};

export default SessionTimeoutPage;
