import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorImage from '../../resources/images/multidashboard.svg';
import BottomLink from '../common/BottomLink';
import MainCards from '../common/MainCards';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.75rem',
  },
  root: {
    minWidth: 275,
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
  cardContent: {
    padding: '2rem',
  },
});
const MultiEcosystemCards = (props) => {
  const classes = useStyles();
  return (
    <MainCards>
      <Grid item xs={12} className={classes.mediaGrid}>
        <img src={ErrorImage} alt='dashboardImage' className={classes.media} />
      </Grid>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h4' className={classes.text}>
          Looks like something is not right
        </Typography>
        <Typography style={{ padding: '0.75rem' }}>
          There are lots of reasons you must be seeing this page. For example,
          your access to ecosystem(s) must’ve been revoked or we are unable to
          securely verify your identity or you don’t have any ecosystems with
          this ID to quote a few. But don’t worry we got you, you can do the
          following from this page :)
        </Typography>
      </Grid>
      <br />
      <BottomLink
        typo='Sign in?'
        link='Click here to go to sign In page'
        to='/login'
      />
      <br />
      <BottomLink
        typo='Change your password?'
        link='Request to change your password'
        to='/resetpassword'
      />
      <br />
      <BottomLink
        typo='Do you think you have issues?'
        link='Contact the ecosystem administrator'
        to='/contactadmin'
      />
    </MainCards>
  );
};

export default MultiEcosystemCards;
