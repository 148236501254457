import React, { useEffect, useState } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from '../../models/buttons/buttonStyles';
import UpdateScreen from '../batch/updateBatch';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import StyledButton from '../../../../../common/models/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { sendCoAReminder } from '../../../../containers/actions/common/saslAction';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_COA_FILE_REMINDER } from '../../../../containers/actions/types';

const Update = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    schrockenDeliveryScheduleBatchID,
    schrockenDeliveryScheduleID,
    SASLBatchStatusID,
    statusDisplayName,
    batchDashboard,
    qaApproval,
    featuresAccess,
    qaStatusID,
    qaStatusText,
    sendReminderForCoA,
  } = props;
  const [open, setOpen] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [reminderSuccess, setReminderSuccess] = useState(false);
  const [reminderFailed, setReminderFailed] = useState(false);
  const [sendReminderError, setError] = useState('');

  const coaReminder = useSelector((state) => {
    if (state.pcmp.common.sasl.coaReminder?.status) {
      return true;
    } else if (
      state.pcmp.common.sasl.coaReminder?.status === false &&
      state.pcmp.common.sasl.coaReminder?.errorMessage
    ) {
      setReminderFailed(true);
      setError(state.pcmp.common.sasl.coaReminder?.errorMessage);
    }
    return false;
  });

  const handleOpen = () => {
    if (sendReminderForCoA) {
      setReminderDialog(true);
    } else {
      setOpen(!open);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleCloseReminder = () => {
    setOpen(!open);
    setReminderDialog(!reminderDialog);
  };

  const handleCloseReminderSuccess = () => {
    setReminderDialog(false);
    setReminderSuccess(false);
  };

  const handleSendCoAFileReminder = () => {
    let data = {
      ...sendReminderForCoA,
    };
    console.log(data);
    dispatch(sendCoAReminder(data));
  };

  const handleCloseError = () => {
    setReminderFailed(false);
    setError('');
    dispatch({
      type: SEND_COA_FILE_REMINDER,
      payload: undefined,
    });
  };

  useEffect(() => {
    if (coaReminder && reminderDialog) {
      setReminderDialog(false);
      setReminderSuccess(true);
      dispatch({
        type: SEND_COA_FILE_REMINDER,
        payload: undefined,
      });
    }
  }, [dispatch, coaReminder, reminderDialog]);

  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        // onClick={handleClickRecieved}
      >
        <div className={classes.logo} onClick={handleOpen}>
          <AutorenewIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Update</Typography>
        </div>
      </Button>

      <UpdateScreen
        open={open}
        handleClose={handleClose}
        schrockenDeliveryScheduleBatchID={schrockenDeliveryScheduleBatchID}
        schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
        SASLBatchStatusID={SASLBatchStatusID}
        statusDisplayName={statusDisplayName}
        batchDashboard={batchDashboard}
        qaApproval={qaApproval}
        featuresAccess={featuresAccess}
        qaStatusID={qaStatusID}
        qaStatusText={qaStatusText}
      />
      <Dialog open={reminderDialog} fullWidth>
        <DialogContent style={{ padding: '1rem' }}>
          <Grid container justifyContent='flex-end'>
            <Typography
              variant='body2'
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleCloseReminder}
            >
              Cancel <CloseIcon fontSize='large' style={{ color: '#F00' }} />
            </Typography>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <WarningIcon
                style={{
                  fontSize: '5rem',
                  color: '#F00',
                }}
              />
            </Grid>
          </Grid>
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
              paddingBottom: '0px',
              fontSize: '1.5rem',
              fontWeight: 500,
              color: '#000',
            }}
          >
            E-Invoice cannot be attached.
          </Typography>
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
              paddingTop: '0px',
              fontSize: '1.5rem',
              fontWeight: 500,
              color: '#000',
            }}
          >
            CoA is not present in the system.
          </Typography>

          <Grid item xs={12} style={{ textAlign: 'center', padding: '1rem' }}>
            <StyledButton
              style={{ padding: '0.5rem 3rem' }}
              onClick={handleSendCoAFileReminder}
              // disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
            >
              Send Reminder for CoA Upload
            </StyledButton>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={reminderSuccess} fullWidth>
        <DialogContent style={{ padding: '1rem' }}>
          <Grid container justifyContent='flex-end'>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CheckCircleIcon
                style={{
                  fontSize: '5rem',
                  color: '#39811D',
                }}
              />
            </Grid>
          </Grid>
          <Typography
            variant='h5'
            style={{
              textAlign: 'center',
              padding: '0.5rem',
              fontWeight: 500,
              fontSize: '1.5rem',
            }}
          >
            Reminder Sent Successfully
          </Typography>

          <Typography
            variant='body2'
            style={{
              textAlign: 'center',
              fontSize: '1rem',
              margin: '1rem',
            }}
          >
            Reminder has been sent to CMO to upload CoA
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              padding: '0.5rem',
            }}
          >
            <StyledButton
              style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
              onClick={handleCloseReminderSuccess}
            >
              OK
            </StyledButton>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={reminderFailed} fullWidth>
        <DialogContent style={{ padding: '1rem' }}>
          <Grid container justifyContent='flex-end'>
            <Typography
              variant='body2'
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleCloseError}
            >
              Cancel <CloseIcon fontSize='large' style={{ color: '#F00' }} />
            </Typography>
          </Grid>
          <Typography
            variant='h5'
            style={{
              textAlign: 'center',
              padding: '0.5rem',
              fontWeight: 500,
              fontSize: '1.5rem',
            }}
          >
            {sendReminderError}
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Update;
