import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    backgroundColor: '#f5f9ff',
    position: 'fixed',
    width: '100%',
  },
  closeText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
    '&:hover': {
      borderBottom: '2px solid red',
    },
  },
    icon: {
      display: 'flex',
      alignItems:'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      padding:'0.3rem',
    },
    title : {
      fontWeight:500,
      [theme.breakpoints.down('xs')]: {
        fontSize:'0.875rem'
      },
    },
    subtitle : {
      fontWeight:500,
      [theme.breakpoints.down('xs')]: {
        fontSize:'0.875rem'
      },
    },
    main:{
        textAlign:'center',
        alignItems:'center',
        justifyContent:'center',
    },
    children:{
        paddingTop:40,
      // [theme.breakpoints.down('xs')]: {
      //   paddingTop:60,
      // },
    }
  }));

const Header = ({ children, ...props }) => {
  const classes = useStyles();
  const { handleClose, title, subtitle } = props;
  return (
    <React.Fragment>
      <Grid
        container
        justify='center'
        className={classes.titleGrid}
        style={{ position: props.isDrawer === true ? 'inherit' : 'fixed' }}
      >
        <Grid item xs={11} sm={11} md={11} className={classes.main}>
          <Typography variant='h4' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='body1' className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={1}>
          <Hidden xsDown implementation='css'>
            <Typography
              variant='body2'
              onClick={handleClose}
              className={classes.closeText}
            >
              Cancel{' '}
              <CloseIcon
                fontSize='large'
                color='error'
                className={classes.icon}
              />
            </Typography>
          </Hidden>
          <div className={classes.icon} style={{ paddingLeft:'2rem'}}>
            <Hidden smUp implementation='css'>
              <CloseIcon onClick={handleClose} fontSize='large' color='error' />
            </Hidden>
          </div>
        </Grid>
      </Grid>

      <Grid className={classes.children}
       style={{ paddingTop: props.padding === true ? 100 : 40 }}
      >{children}</Grid>
    </React.Fragment>
  );
};

export default Header;
