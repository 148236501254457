import React from 'react';
import Logo from '../../resources/images/Schrocken-Logo-Dark.png';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
//Analytics
import { trackEvent } from '../../tracking';
import GridMdWrapper from './wrapper/GridMdWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    padding: '0.5rem',
  },
  invisible: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    cursor: 'pointer',
    color: '#000000',
    textDecoration: 'none',
  },
}));

const LoginFooter = (props) => {
  const classes = useStyles();

  const { hasLinks } = props;

  return (
    <GridMdWrapper style={{ padding: '0.5rem', backgroundColor: '#ffffff' }}>
      <Grid container alignItems='center' justify='center'>
        <Grid item xs={12} sm={4} md={4}>
          <Link
            className={classes.link}
            href='https://www.schrocken.com'
            target='_blank'
          >
            <img
              src={Logo}
              alt=''
              style={{
                height: '2.125rem',
                opacity: '1',
              }}
            />
          </Link>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          {hasLinks && (
            <React.Fragment>
              <IconButton
                size='small'
                href='https://www.linkedin.com/company/schrocken/'
                target='_blank'
                onClick={() => trackEvent('Media', 'Linkdin page Visit')}
                style={{ marginRight: '1rem' }}
              >
                <i className='fab fa-linkedin-in' />
              </IconButton>
              <IconButton
                size='small'
                href='https://www.youtube.com/channel/UCHccTTk1Zb3Q7oz5zRJC_pw/videos'
                target='_blank'
                onClick={() => trackEvent('Media', 'Youtube channel Visit')}
              >
                <i className='fab fa-youtube'></i>
              </IconButton>
            </React.Fragment>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <Typography variant='body2'>
            &#169;2020 Schrocken Inc. <br></br>
            All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </GridMdWrapper>
  );
};

export default LoginFooter;
