import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import AddOrganisation from './AddOrganisation';
import OrganisationAdded from './OrganisationAdded';
import { getOrganization } from '../../containers/actions/ecosystemActions';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

const useStyles = {
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  inline: {
    display: 'inline',
    fontWeight: 500,
  },
  addOrgText: {
    display: 'inline',
    fontSize: '0.75rem',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#EC6535',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
};

class OrganisationDetails extends Component {
  state = {
    organisationName: '',
    addOrgDialogOpen: false,
    orgAddedDialogOpen: false,
    organization: [],
    collaboratorType: [],
    organizationName: '',
    LLCode: null,
    TPCode: null,
  };

  componentDidMount() {
    this.props.getOrganization();
  }

  // recieving the state from reducer as props
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.getOrg.status === true) {
      this.setState({
        organization: nextProps.getOrg.data,
      });
    }
  }

  handleOpen = () => {
    this.setState({
      addOrgDialogOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      addOrgDialogOpen: false,
    });
  };

  handleOpenAddedDialog = (data) => {
    this.setState({
      ...data,
      orgAddedDialogOpen: true,
    });
  };

  handleCloseAddedDialog = () => {
    this.setState({
      orgAddedDialogOpen: false,
    });
  };

  render() {
    const {
      classes,
      handleChange,
      organizationName,
      errors,
      brandOwnerOrganization,
    } = this.props;
    const { addOrgDialogOpen, orgAddedDialogOpen, LLCode, TPCode } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} className={classes.titleGrid}>
          <div>
            <Typography variant='body2' className={classes.inline}>
              Organization details
            </Typography>
          </div>
          <div>
            <Typography
              variant='body2'
              className={classes.inline}
              style={{ fontSize: '0.75rem' }}
            >
              New Organization?&nbsp;
            </Typography>

            <Typography
              variant='body2'
              className={classes.addOrgText}
              onClick={this.handleOpen}
            >
              Add new organization details
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            variant='outlined'
            size='small'
            margin='normal'
            name='organizationName'
            label='Select Organization'
            error={errors.organizationName ? true : false}
            onChange={handleChange}
            value={organizationName}
            helperText={
              errors.organizationName ? (
                errors.organizationName
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Select Organization
                </Typography>
              )
            }
            inputRef={
              errors.organizationName ? (input) => input && input.focus() : null
            }
          >
            {this.state.organization.length === 0 ? (
              <MenuItem disabled>No Organization Added</MenuItem>
            ) : (
              this.state.organization.map((organization, index) => {
                return (
                  <MenuItem value={organization.organizationName} key={index}>
                    {organization.organizationName}
                  </MenuItem>
                );
              })
            )}
          </TextField>
          <Dialog open={addOrgDialogOpen} onClose={this.handleClose}>
            <AddOrganisation
              brandOwnerOrganization={brandOwnerOrganization}
              handleClose={this.handleClose}
              handleOpenAddedDialog={this.handleOpenAddedDialog}
            />
          </Dialog>
          <Dialog
            open={orgAddedDialogOpen}
            onClose={this.handleCloseAddedDialog}
          >
            <OrganisationAdded
              handleClose={this.handleCloseAddedDialog}
              organizationName={this.state.organizationName}
              LLCode={LLCode}
              TPCode={TPCode}
            />
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

// OrganisationDetails.propTypes = {
//   getOrg: PropTypes.func.isRequired,
//   collabType: PropTypes.object.isRequired,
// };

// getting access to state through redux
const mapStateToProps = (state) => ({
  getOrg: state.ecosystems.Organization,
});

export default connect(mapStateToProps, { getOrganization })(
  withStyles(useStyles)(OrganisationDetails)
);
