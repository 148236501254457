import axios from 'axios';

export const setAuthToken = (token) => {
  // Apply auth header to every request
  axios.defaults.headers.common.authToken = token;
};
export const deleteAuthToken = () => {
  // Delete auth header
  delete axios.defaults.headers.common.authToken;
};
