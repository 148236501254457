import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  styledBtn: {
    textTransform: 'none',
    borderRadius: '2rem',
  },
});

const StyledButton = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      variant='contained'
      color='primary'
      disableElevation
      disableRipple
      disableFocusRipple
      className={classes.styledBtn}
      disabled={props.isCommitDisabled ? props.isCommitDisabled : false}
      endIcon={
        <ArrowForwardIosIcon
          style={{ fontSize: props.size === 'small' ? 12 : 15 }}
        />
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
