import React from 'react';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import StyledButton from './models/Button';
import { appUrl } from '../../utils/config.js';
import { azureADClientID, azureADTenantID } from '../../utils/config';

export default function SignIn() {
  return (
    <Grid container justify='center'>
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
        style={{ textAlign: 'center', marginTop: '2rem' }}
      >
        <StyledButton
          variant='contained'
          color='secondary'
          size='medium'
          id='login_AzureAD'
          startIcon={
            <Icon style={{ paddingRight: '2rem' }}>
              <i className='fab fa-windows' style={{ fontSize: '1.5rem' }}></i>
            </Icon>
          }
          style={{
            paddingLeft: '3rem',
            paddingRight: '3rem',
          }}
        >
          <a
            href={`https://login.microsoftonline.com/${azureADTenantID}/oauth2/v2.0/authorize?client_id=${azureADClientID}&response_type=code&redirect_uri=${appUrl}/azurelogin&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&state=12345`}
            target='_self'
            style={{ color: '#ffffff', textDecoration: 'none' }}
          >
            Sign In using Azure AD
          </a>
        </StyledButton>
      </Grid>
    </Grid>
  );
}
