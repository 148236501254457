import React from 'react';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import StyledButton from '../../../models/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'classnames';

import { useStyles } from '../../scheduleAgreements/saslStyles';

const Search = (props) => {
  const {
    searchQuery,
    handleSearchQuery,
    handleClearSearch,
    submitSearch,
    placeholder,
  } = props;
  const classes = useStyles();

  return (
    <form noValidate onSubmit={submitSearch}>
      <Grid container alignItems='center'>
        <Grid item xs={9}>
          <Input
            fullWidth
            placeholder={placeholder}
            value={searchQuery}
            name='searchQuery'
            onChange={handleSearchQuery}
            startAdornment={<SearchIcon />}
            style={{ fontSize: '0.875rem' }}
            endAdornment={
              searchQuery && (
                <Tooltip title='Clear search' aria-label='clear'>
                  <ClearIcon
                    fontSize='small'
                    onClick={handleClearSearch}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              )
            }
          />
        </Grid>
        <Grid item xs={2} className={clsx(classes.pdL10, classes.pdTponLr)}>
          <StyledButton type='submit' className={classes.search}>
            Search
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default Search;
