import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from 'clsx';

//files
import Filters from './Filter';
import UploadExport from '../../grnReceipts/UploadExport';
import Search from './Search';
import ScheduleAgreementTable from '../scheduleAgreementTable';
import {
  // fetchSASLDetails,
  offSetfetchSASLDetails,
  getAllDSBatchStatusSequence,
  saslSource,
  // batchTableSource,
} from '../../../../containers/actions/common/saslAction';
import { useStyles } from './saslStyles';
import { loadTableData } from '../../../../containers/actions/common/fileActions';
import { getFeaturesAccess } from '../../../../containers/actions/common/accessControl';
import featuresAndValidations from '../../models/featuresAccess/FeaturesAndValidations';
import {
  dsFilters,
  saveSASLFilters,
} from '../../../../containers/actions/common/filtersAction';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const SAIndex = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { collabType, organizationName } = localStorage;
  const [CMOMember, selectCMO] = useState(
    collabType === 'Pharma Co' ? 'all' : organizationName
  );
  const [status, selectStatus] = useState('all');
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQury] = useState('');

  const [searchFilterFlag, setSearchFilterFlag] = useState(false);

  let loading = true;

  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('lastUpdatedDate');
  const [limit, setLimit] = useState(5);
  const [hasBeenSearched, setHasBeenSearched] = useState(false);
  const [tableOrder, setTableOrder] = useState({
    noRowsPerPage: 5,
    currentPage: 1,
  });

  const saslFilters = useSelector((state) => state.pcmp.common.filters);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (
      saslFilters?.saslFilters &&
      firstLoad.current === true &&
      location.state?.scheduleAgreements
    ) {
      firstLoad.current = false;

      setOffset(saslFilters.saslFilters.offset);
      setLimit(saslFilters.saslFilters.limit);
      setOrder(saslFilters.saslFilters.sort.order);
      setOrderBy(saslFilters.saslFilters.sort.orderBy);
      searchQueryInput(saslFilters.saslFilters.filter.searchQuery);
      tempSearchQury(saslFilters.saslFilters.filter.tempSearch);
      selectStatus(saslFilters.saslFilters.filter.status);
      if (saslFilters.saslFilters.filter.status !== 'all') {
        setSearchFilterFlag(true);
      }
      selectCMO(saslFilters.saslFilters.filter.CMOMember);
      dispatch(dsFilters());
    }
  }, [dispatch, saslFilters, location.state]);

  useEffect(() => {
    dispatch(
      saveSASLFilters({
        offset: offset,
        limit: limit,
        sort: {
          order: order,
          orderBy: orderBy,
        },
        filter: {
          searchQuery: searchQuery,
          tempSearch: tempSearch,
          status: status,
          CMOMember: CMOMember,
        },
        tableOrder: {
          noRowsPerPage: tableOrder.noRowsPerPage,
          currentPage: tableOrder.currentPage,
        },
      })
    );
  }, [
    dispatch,
    offset,
    limit,
    order,
    orderBy,
    searchQuery,
    tempSearch,
    status,
    CMOMember,
    tableOrder,
  ]);

  useEffect(() => {
    dispatch(loadTableData());
    dispatch(
      // fetchSASLDetails({
      //   vendorName: CMOMember,
      // })

      offSetfetchSASLDetails({
        offset: offset,
        limit: limit,
        sort: {
          orderByField: order,
          orderBy: orderBy,
        },
        filter: {
          searchText: searchQuery,
          viewByValue: status,
          filterByValue: CMOMember,
        },
      })
    );

    return () => {
      if (saslSource) {
        saslSource.cancel('leaving page!');
      }
      // if (batchTableSource) {
      //   batchTableSource.cancel('leaving page!');
      // }
    };
  }, [
    dispatch,
    CMOMember,
    status,
    searchQuery,
    collabType,
    limit,
    offset,
    order,
    orderBy,
  ]);

  useEffect(() => {
    dispatch(getAllDSBatchStatusSequence());
  }, [dispatch]);

  const handleTableOrder = (data) => {
    setTableOrder(data);
  };

  const getLimitPerPage = (data) => {
    setLimit(data);
  };
  const getOrderAndColumnFinal = (data, dataBy) => {
    setOrder(data);
    setOrderBy(dataBy);
    setOffset(0);
  };

  let statusArray = [];
  statusArray = useSelector((state) => {
    return state.pcmp.common.sasl.allDSStatus;
  });

  let scheduleAgreements = useSelector((state) => {
    if (
      state.pcmp.common.sasl.fetchSASLData.data &&
      state.pcmp.common.sasl.fetchSASLData.status === true &&
      state.pcmp.common.sasl.fetchSASLData.paging
    ) {
      loading = false;
      return state.pcmp.common.sasl.fetchSASLData.data;
    }

    if (
      state.pcmp.common.sasl.fetchSASLData.data &&
      state.pcmp.common.sasl.fetchSASLData.status === true
    ) {
      loading = false;
      return state.pcmp.common.sasl.fetchSASLData.data;
    }

    if (
      state.pcmp.common.sasl.fetchSASLData.data &&
      state.pcmp.common.sasl.fetchSASLData.status === false
    ) {
      loading = true;
    }
    return [];
  });

  let newTotalRecord = useSelector((state) => {
    if (
      state.pcmp.common.sasl.fetchSASLData.data &&
      state.pcmp.common.sasl.fetchSASLData.status === true &&
      state.pcmp.common.sasl.fetchSASLData.paging
    ) {
      return state.pcmp.common.sasl.fetchSASLData.paging.totalRecords;
    }
    return 0;
  });

  const access = useSelector(
    (state) => state.pcmp.common.accessControl.featuresAccess.fgFeatures
  );

  useEffect(() => {
    if (!access) {
      dispatch(getFeaturesAccess(9, 'fgFeatures'));
    }
  }, [access, dispatch]);
  const handleSelectCMO = (event) => {
    selectCMO(event.target.value);
    loading = true;
    setOffset(0);
  };

  const handleSelectStatus = (event) => {
    selectStatus(event.target.value);
    setOffset(0);
    if (event.target.value !== 'all') {
      setSearchFilterFlag(true);
    } else {
      setSearchFilterFlag(false);
    }
  };

  const handleSearchQuery = (event) => {
    let { value } = event.target;
    // tempSearchQury(event.target.value);
    if (value === '' || value === null) {
      tempSearchQury(value);
      searchQueryInput('');
    } else {
      tempSearchQury(value);
    }
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    tempSearchQury('');
    searchQueryInput('');
    if (hasBeenSearched) {
      setOffset(0);
    }
    setHasBeenSearched(false);
  };

  const submitSearch = (event) => {
    event.preventDefault();
    if (tempSearch.trim() !== '') {
      searchQueryInput(tempSearch);
      setHasBeenSearched(true);
      setOffset(0);
    }
  };

  //debounce
  const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

  const debouncedLog = debounce((data) => {
    setOffset((data - 1) * limit);
  }, 600);

  const getCurrentPageNumber = (data) => {
    // setOffset((data - 1) * limit);
    debouncedLog(data);
  };

  const fetchSASLData = () => {
    // To trigger loading indicator on the table
    dispatch(loadTableData());
    dispatch(
      offSetfetchSASLDetails({
        offset: offset,
        limit: limit,
        sort: {
          orderByField: order,
          orderBy: orderBy,
        },
        filter: {
          searchText: searchQuery,
          viewByValue: status,
          filterByValue: CMOMember,
        },
      })
    );
  };
  return (
    <React.Fragment>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={4}>
          <Filters
            cmoFilter={collabType === 'Pharma Co' ? true : false}
            filterByCMOName={true}
            CMOMember={CMOMember}
            status={status}
            cmoMembersList={[]}
            handleSelectCMO={handleSelectCMO}
            handleSelectStatus={handleSelectStatus}
            statusArray={statusArray}
          />
        </Grid>
        <UploadExport
          isUploadDSBatch={access?.fgBatchFileUpload}
          isUploadConsumptionInfo={access?.consumptionFileUpload}
          refreshFunc={fetchSASLData}
        />
      </Grid>
      <Divider className={classes.mgTp} />
      <Grid container alignItems='center' className={classes.pdTp}>
        <Search
          searchQuery={tempSearch}
          handleSearchQuery={handleSearchQuery}
          handleClearSearch={handleClearSearch}
          submitSearch={submitSearch}
        />
        <Grid item xs={12} md={6}>
          <Grid
            container
            className={clsx(
              classes.dpFlx,
              classes.flxEndSmFlxStrt,
              classes.pdTponLr
            )}
          >
            <Typography variant='caption' className={clsx(classes.padR20)}>
              {`Last synced @ ${moment(new Date()).format(
                'HH:mm:ss; DD MMMM, YYYY'
              )}`}
            </Typography>

            <Typography
              variant='caption'
              color='primary'
              // onClick={fetchGRLineItems}
              onClick={fetchSASLData}
              className={clsx(
                classes.dpFlx,
                classes.algnCntr,
                classes.pointer,
                classes.onHvrUndrline
              )}
            >
              Refresh
              <CachedIcon color='primary' style={{ fontSize: 15 }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <ScheduleAgreementTable
        handleTableOrder={handleTableOrder}
        status={status}
        searchQuery={searchQuery}
        loading={loading}
        scheduleAgreements={scheduleAgreements}
        searchFilterFlag={searchFilterFlag}
        getLimit={getLimitPerPage}
        getOrderAndColumnFinal={getOrderAndColumnFinal}
        newTotalRecord={newTotalRecord}
        getCurrentPageNumber={getCurrentPageNumber}
        offset={offset}
        CMOMember={CMOMember}
        orderByField={order}
        orderBy={orderBy}
      />
    </React.Fragment>
  );
};

export default featuresAndValidations(SAIndex);
