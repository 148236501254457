import { BATCH_FILTERS, DS_FILTERS, SASL_FILTERS } from '../types';

export const saveSASLFilters = (data) => (dispatch) => {
  dispatch({
    type: SASL_FILTERS,
    payload: data,
  });
};
export const dsFilters = (data) => (dispatch) => {
  dispatch({
    type: DS_FILTERS,
    payload: data,
  });
};
export const batchFilters = (data) => (dispatch) => {
  dispatch({
    type: BATCH_FILTERS,
    payload: data,
  });
};
