import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  //BatchInfo
  font: {
    fontWeight: 500,
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
  },
  fontPadding2: {
    textAlign: 'initial',
  },
  fontPadding: {
    // padding: '0.5rem 0.5rem 0.5rem 0rem',
    textAlign: 'initial',
    overflow: 'auto',
  },
  grid: {
    paddingBottom: '2rem',
    paddingTop: '1rem',
    paddingRight: '1.5rem',
  },
  //Consumption
  export: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid #000000de',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },
  //otherDocuments
  files: {
    boder: '1px solid #707070',
    backgroundColor: '#F7F7F7',
    textAlign: 'center',
    padding: '2rem',
  },

  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  document: {
    display: 'flex',
    alignItems: 'center',
  },
  filesFont: {
    fontWeight: 500,
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
  },
  fileIcon: {
    cursor: 'pointer',
    textAlign: 'end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  input: {
    display: 'none',
  },
  child: {
    paddingTop: '1rem',
  },
  textDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5rem',
    cursor: 'pointer',
  },
  //FG - Batch,Shipping,QC styles
  borders: {
    border: '2px solid #707070',
    display: 'flex',
    width: '1.6rem',
    backgroundColor: 'grey',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'left',
    paddingBottom: '0.3rem',
  },
  closeIconSty: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    '&:hover': {
      color: 'red',
    },
  },
}));
