import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

// import ImageLogo from '../../../resources/images/mo-icon-white.png';
// import MoSymphhonyLogo from '../../../resources/images/Mo-text-white.png';
import { makeStyles } from '@material-ui/core/styles';
import SymphonyLogo from '../../../resources/images/Group 6399@2x.png';

//Icon
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { userLogout } from '../../../containers/actions/userActions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoGrid: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  alignEnd: {
    justifyContent: 'flex-end',
  },
  log: {
    width: '12rem',
    height: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '10rem',
      height: '2.5rem',
    },
  },
  headerContainer: {
    padding: '0.25rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

const HeaderComponent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [needLogout, setNeedLogout] = React.useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.userLogout(props.history);
  };
  const classes = useStyles();
  return (
    <Grid container alignItems='center' className={classes.headerContainer}>
      {props.menuNeeded && (
        <Hidden mdUp implementation='css'>
          <Grid item xs={2}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Hidden>
      )}
      <Grid item xs={7} sm={6} md={6} className={classes.logoGrid}>
        {/* <Grid> */}
        <Link to='/'>
          <img src={SymphonyLogo} alt='mosymphony' className={classes.log} />
        </Link>
        {/* </Grid> */}
      </Grid>
      <Grid
        item
        xs={1}
        sm={4}
        md={6}
        className={`${classes.logoGrid} ${classes.alignEnd}`}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleClick}
        >
          <AccountCircleIcon
            fontSize='large'
            className={classes.accountcircle}
          />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { userLogout })(
  withRouter(HeaderComponent)
);
