import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  inherited: {
    display: 'table-cell',
  },
}));

const CustomTableCell = ({ children, ...props }) => {
  const classes = useStyles();
  const { align, hideCell, colSpan, elementStyle } = props;
  return (
    <TableCell
      align={align ? align : 'center'}
      className={clsx(
        classes.customPadding,
        hideCell ? classes.hidden : classes.inherited
      )}
      colSpan={colSpan}
      style={elementStyle}
    >
      {children}
    </TableCell>
  );
};

CustomTableCell.propTypes = {
  align: PropTypes.string,
  hideCell: PropTypes.bool,
  elementStyle: PropTypes.object,
};

export default CustomTableCell;
