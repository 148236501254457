const StatusMapper = {
  1: 'Scheduled',
  2: 'Under Production',
  3: 'Production Complete',
  4: 'Quarantine',
  5: 'QC Released',
  6: 'Awaiting GRN',
  7: 'GRN Generated',
  8: 'Dispatch',
  9: 'Goods Received',
  13: 'Record Rejected',
  19: 'E-Invoice Uploaded',
  20: 'E-Way Bill Uploaded',
};

export default StatusMapper;
