import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledButton from '../../common/models/Button';
import ContactAdmin from '../../common/ContactAdmin';
//components
import CreateAccount from './CreateAccount';
import ConfirmDetails from './ConfirmDetails';
import ConsentAgreement from './ConsentAgreement';
//functions
import {
  getSignupTokenDetails,
  checkPasswordCriteria,
  joinNetwork,
} from '../../../containers/actions/authActions';
import { isValid } from '../../common/helperFunctions/helper';

const Styles = (theme) => ({
  backgroundGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
  bottomgrid: {
    padding: '2rem 0',
  },
  orgText: {
    padding: '0.75rem 0',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  //try ovverride
  step: {
    '& $completed': {
      color: '#008002',
    },
  },
  //the following enables ovverride and add other custom styles
  completed: {},
});

function getSteps() {
  return ['Create account', 'Confirm details', 'Consent & agreement'];
}

class SignUpSteps extends Component {
  state = {
    loading: true,
    tokenError: null,
    //for conditional render
    activeStep: 0,
    userDetails: {},
    passwordCriteria: {},
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
    fullName: '',
    designation: '',
    consent1: false,
    consent2: false,
    disableNext: false,
    disableBack: false,
    errors: {},
  };

  componentDidMount() {
    const { signupToken } = this.props.match.params;
    const data = { signupToken: signupToken };
    this.props.getSignupTokenDetails(data, this.props.history);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.invitedUserData !== this.props.invitedUserData &&
      this.props.invitedUserData.status === true
    ) {
      this.setState({
        userDetails: this.props.invitedUserData.data.userDetails,
        passwordCriteria: this.props.invitedUserData.data.passwordCriteria,
        //get full name from user data, designation is not pre-set currently in invitaion form
        //if designation incledes afterwards update that to state
        fullName: this.props.invitedUserData.data.userDetails.fullName,
        loading: false,
      });
    }
    if (
      prevProps.invitedUserData !== this.props.invitedUserData &&
      this.props.invitedUserData.status === false
    ) {
      this.setState({
        loading: false,
        tokenError: this.props.invitedUserData.errorDescription,
      });
    }
    if (
      prevProps.passwordCriteria !== this.props.passwordCriteria &&
      this.props.passwordCriteria.status === false
    ) {
      this.setState({
        errors: {
          passwordCriteria:
            'Password criteria does not match. Please check the password criteria given below',
        },
        disableNext: false,
      });
    }
    if (
      prevProps.passwordCriteria !== this.props.passwordCriteria &&
      this.props.passwordCriteria.status === true
    ) {
      this.setState({
        disableNext: false,
        activeStep: 1,
      });
    }
    if (
      prevProps.signUp !== this.props.signUp &&
      this.props.signUp.status === false
    ) {
      let key = Object.keys(this.props.signUp.errorDescription)[0];
      this.setState({
        disableNext: false,
        disableBack: false,
        errors: {
          consent: this.props.signUp.errorDescription[key],
        },
      });
    }
  }

  handleNext = () => {
    //write validations here to proceed to next step
    switch (this.state.activeStep) {
      case 0:
        return this.validatePassword();
      case 1:
        return this.validateConfirmDetails();
      case 2:
        return this.validateConsent();
      default:
        return this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  // for password & confirm password
  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      errors: {},
    });
  };

  handleConsent = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
      errors: {},
    });
  };

  // to show the visibility icon
  handleClickPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  // for Create Account -- Password & Confirm Password
  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    let errors = {};
    if (!isValid(password)) {
      errors.password = 'Please enter password';
    } else if (!isValid(confirmPassword)) {
      errors.confirmPassword = 'Please confirm password';
    } else if (password !== confirmPassword) {
      errors.password = 'Passwords do not match';
      errors.confirmPassword = 'Passwords do not match';
    } else {
      let data = { password: password };
      this.props.checkPasswordCriteria(data);
      this.setState({ disableNext: true });
    }
    this.setState({ errors });
  };

  //validate user name
  validateConfirmDetails = () => {
    const { fullName } = this.state;
    if (!isValid(fullName)) {
      this.setState({
        errors: {
          fullName: 'Please enter your Name.',
        },
      });
    } else {
      this.setState({ activeStep: 2 });
    }
  };

  //validate Consent
  validateConsent = () => {
    const { consent1, consent2 } = this.state;
    if (!consent1 || !consent2) {
      this.setState({
        errors: {
          consent: 'Please agree with all terms & conditions',
        },
      });
    } else {
      this.setState({ disableNext: true, disableBack: true });
      // create account API call here
      let { signupToken } = this.props.match.params;
      let {
        userDetails,
        fullName,
        designation,
        password,
        confirmPassword,
        consent1,
        consent2,
      } = this.state;
      let data = {
        signupToken,
        userDetails: {
          ...userDetails,
          fullName,
          password,
          confirmPassword,
          consent1,
          consent2,
          designation,
        },
      };
      this.props.joinNetwork(data, this.props.history);
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const {
      loading,
      tokenError,
      activeStep,
      showPassword,
      showConfirmPassword,
      userDetails,
      passwordCriteria,
      password,
      confirmPassword,
      fullName,
      designation,
      consent1,
      consent2,
      errors,
      disableNext,
      disableBack,
    } = this.state;

    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <CreateAccount
              activeStep={activeStep}
              maskedEmailAddress={userDetails.maskedEmailAddress}
              password={password}
              confirmPassword={confirmPassword}
              passwordCriteria={passwordCriteria}
              showPassword={showPassword}
              showConfirmPassword={showConfirmPassword}
              handleClickPassword={this.handleClickPassword}
              handleClickConfirmPassword={this.handleClickConfirmPassword}
              handleChange={this.handleChange}
              errors={errors}
            />
          );
        case 1:
          return (
            <ConfirmDetails
              activeStep={activeStep}
              handleChange={this.handleChange}
              maskedEmailAddress={userDetails.maskedEmailAddress}
              organizationName={userDetails.organizationName}
              collaboratorTypeName={userDetails.collaboratorTypeName}
              collaboratorRoleName={userDetails.collaboratorRoleName}
              fullName={fullName}
              designation={designation}
              errors={errors}
            />
          );
        case 2:
          return (
            <ConsentAgreement
              activeStep={activeStep}
              handleConsent={this.handleConsent}
              consent1={consent1}
              consent2={consent2}
              errors={errors}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    };
    return (
      <Grid>
        {loading === true ? (
          <div style={{ textAlign: 'center', marginTop: '25%' }}>
            <CircularProgress color='primary' thickness={7} />
          </div>
        ) : (
          <Grid container justify='center'>
            {tokenError !== null ? (
              <React.Fragment>
                <Typography
                  variant='body1'
                  style={{ marginTop: '3rem', fontWeight: 500 }}
                >
                  {tokenError}
                </Typography>
                <ContactAdmin />
              </React.Fragment>
            ) : (
              <Grid
                item
                xs={11}
                sm={10}
                md={8}
                className={classes.backgroundGrid}
              >
                <Grid container justify='center'>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center', marginTop: '1.5rem' }}
                  >
                    <Typography variant='h4'
                    style={{fontWeight:'500'}} // using fontWeight since its been used in all cards
                    >
                      Join Pharma Contract Manufacturing Ecosystem
                    </Typography>
                    <Typography variant='body1' className={classes.orgText}>
                      {userDetails.organizationName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step
                          key={label}
                          classes={{
                            root: classes.step,
                          }}
                        >
                          <StepLabel
                            StepIconProps={{
                              classes: {
                                root: classes.step,
                                completed: classes.completed,
                              },
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    {getStepContent(activeStep)}
                  </Grid>
                  <Grid item xs={10} md={9} className={classes.bottomgrid}>
                    <Grid container>
                      <Grid item xs={2}>
                        {activeStep === 0 || disableBack === true ? (
                          <Typography />
                        ) : (
                          <Typography
                            variant='caption'
                            onClick={this.handleBack}
                            style={{ cursor: 'pointer' }}
                          >
                            &lt; Back
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <StyledButton
                          size='small'
                          onClick={this.handleNext}
                          style={{
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                          }}
                          disabled={disableNext}
                        >
                          <Typography variant='caption'>
                            {activeStep === 2
                              ? 'Accept & Create Account'
                              : 'Next'}
                          </Typography>
                        </StyledButton>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <br />
        <br />
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  invitedUserData: state.auth.invitedUserData,
  passwordCriteria: state.auth.password,
  signUp: state.auth.adminSignUp,
});
export default connect(mapStateToProps, {
  getSignupTokenDetails,
  checkPasswordCriteria,
  joinNetwork,
})(withStyles(Styles)(withRouter(SignUpSteps)));
