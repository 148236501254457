import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Footer from '../../common/Footer';
import LoginCards from './SchrockenAdminDashboard';
import { StyledAppBar } from '../../common/models/index';

import HeaderComponent from './HeaderComponent';
// withstyles and makes styles should not be used together
const styles = {
  gridContainer: {
    backgroundColor: '#F2F2F2',
    //set initial screen height to 100vh to set footer at bottom
    height: '100vh',
  },
  header: {
    position: 'relative',
  },
  footerGrid: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 200,
  },
  flexGrid: {
    paddingTop: 100,
  },
  pageContainer: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    overflowY: 'hidden',
  },
  contentWrap: {
    paddingBottom: 80 /* Footer height */,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 50 /* Footer height */,
  },
};

class Dashboard extends Component {
  state = {
    cookiesAccepted: true,
    hasCookieSettings: false,
  };
  render() {
    const { classes } = this.props;
    const { cookiesAccepted, hasCookieSettings } = this.state;
    return (
      <div className={classes.pageContainer}>
        <div className={classes.contentWrap}>
          <StyledAppBar backgroundColor='#000000' className={classes.header}>
            <HeaderComponent />
          </StyledAppBar>
          <div className={classes.flexGrid}>
            <LoginCards />
          </div>
        </div>
        <footer className={classes.footer}>
          <Footer
            hasCookieSettings={hasCookieSettings}
            cookiesAccepted={cookiesAccepted}
          />
        </footer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
