import { combineReducers } from "redux";

import dashboardReducer from "./dashboardReducer";
import purchaseOrderReducer from "./purchaseOrderReducer";
import manageUsersReducer from "./manageUsersReducer";

import batchReducer from "./batchReducer";
import fileReducer from "./filesReducer";

// import cmoDashboardReducer from './cmoDashboardReducer';
// import cmoManageUsersReducer from './cmoManageUsersReducer';

// import pharmaCoReducer from './pharmaCoReducer';

export default combineReducers({
  dashboard: dashboardReducer,
  purchaseOrder: purchaseOrderReducer,
  manageUsers: manageUsersReducer,
  batchItems: batchReducer,
  files: fileReducer
});
