import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { StyledAppBar } from '../models/index';
import Footer from '../Footer';
import CookieDrawer from '../CookieDrawer';
import { cookieSettings } from '../helperFunctions/helper';
import Logo from '../../../resources/images/mosymphony@2x-1.png';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  headerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileHeaderImage: {
    maxHeight: '3rem',
    maxWidth: '62rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10rem',
    },
  },

  footerGrid: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 200,
  },
  flexGrid: {
    paddingTop: 100,
  },
  pageContainer: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    overflowY: 'hidden',
  },
  contentWrap: {
    paddingBottom: 80 /* Footer height */,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // height: 50 /* Footer height */,
  },
});

class HeaderFooterwithCookies extends Component {
  state = {
    //for component rendering
    cookiesAccepted: false,
    hasCookieSettings: true,
    hasLinks: false,
    headerLink: true,
    cookiesConsent: cookieSettings().cookiesConsent,
  };

  setCookies = (value) => {
    localStorage.setItem('cookiesConsent', value);
    this.setState({ cookiesConsent: value });
  };

  render() {
    const { classes, cookiesEnabled } = this.props;
    const {
      cookiesAccepted,
      hasCookieSettings,
      hasLinks,
      headerLink,
      cookiesConsent,
    } = this.state;
    return (
      <div className={classes.pageContainer}>
        <div className={classes.contentWrap}>
          <StyledAppBar position='static' headerLink={headerLink}>
            <Toolbar className={classes.headerAlign}>
              <Grid>
                <Link to='/'>
                  <img
                    src={Logo}
                    alt='final'
                    className={classes.mobileHeaderImage}
                  />
                </Link>
              </Grid>
            </Toolbar>
          </StyledAppBar>
          <div className={classes.flexGrid}>{this.props.children}</div>
        </div>
        <footer className={classes.footer}>
          {cookiesEnabled && (
            <CookieDrawer
              cookiesConsent={cookiesConsent}
              hasCookieSettings={hasCookieSettings}
              setCookies={this.setCookies}
            />
          )}
          <Footer
            hasLinks={hasLinks}
            hasCookieSettings={hasCookieSettings}
            cookiesAccepted={cookiesAccepted}
          />
        </footer>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderFooterwithCookies);
