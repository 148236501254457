import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AzureImage from '../../../resources/images/azureImage.png';
import BottomLink from '../../common/BottomLink';
const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.5rem',
  },
  root: {
    minWidth: 275,
    boxShadow: 'none',
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
});
const AzureRedirectCard = (props) => {
  const classes = useStyles();
  return (
    <Grid container justify='center'>
      <Grid item xs={11} sm={8} md={6} lg={5}>
        <Card className={classes.root}>
          <CardContent>
            <Grid item xs={12} className={classes.mediaGrid}>
              <img src={AzureImage} alt='AzureImage' />
            </Grid>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h4' className={classes.text}>
                We are verifying your Microsoft Azure credentials
              </Typography>
              <Typography
                variant='body2'
                style={{ fontSize: '1.2rem', padding: '0.75rem' }}
              >
                Once the process is done.We will automatically redirect you to
                Mosymphony. Thank you for your patience.
              </Typography>
            </Grid>
            <br />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={11}>
        <br />
        <br />
        <BottomLink
          typo='Do you think you have issues signing in?'
          link='Contact the ecosystem administrator'
          to='/contactadmin'
        />
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

export default AzureRedirectCard;
