import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import HeaderLayout from '../HeaderLayout';
import LoginFooter from '../Footer';
import CookieDrawer from '../CookieDrawer';
import Media from '../Media';
import { cookieSettings } from '../helperFunctions/helper';

const styles = (theme) => ({
  footerGrid: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 200,
  },
  flexGrid: {
    paddingTop: 100,
  },
  pageContainer: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
  },
  contentWrap: {
    paddingBottom: 170 /* Footer height */,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 170 /* Footer height */,
  },
});

class OpenPageMediaHeader extends Component {
  state = {
    fixDivHeight: false,
    footerStyle: {},
    cookiesConsent: cookieSettings().cookiesConsent,
  };

  setCookies = (value) => {
    localStorage.setItem('cookiesConsent', value);
    this.setState({ cookiesConsent: value });
  };
  render() {
    const { classes } = this.props;
    const { cookiesConsent } = this.state;
    return (
      <div className={classes.pageContainer}>
        <div className={classes.contentWrap}>
          <HeaderLayout headerLink={true} style={{ position: 'static' }} />
          <div className={classes.flexGrid}>{this.props.children}</div>
        </div>
        <footer className={classes.footer}>
          <Media />
          <CookieDrawer
            cookiesConsent={cookiesConsent}
            hasCookieSettings={true}
            setCookies={this.setCookies}
          />
          <LoginFooter
            hasLinks={true}
            hasCookieSettings={true}
            cookiesAccepted={false}
          />
        </footer>
      </div>
    );
  }
}

export default withStyles(styles)(OpenPageMediaHeader);
