import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import querystring from 'query-string';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledButton from '../../common/models/Button';
//components
import ConfirmDetails from '../signup/ConfirmDetails';
import ConsentAgreement from '../signup/ConsentAgreement';
//functions
import {
  getSignupTokenDetails,
  joinNetworkAzureUser,
} from '../../../containers/actions/authActions';
import { isValid } from '../../common/helperFunctions/helper';

const Styles = (theme) => ({
  alignGrid: {
    marginTop: '6rem',
    marginBottom: '3rem',
  },
  backgroundGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
  bottomgrid: {
    padding: '2rem 0',
  },
  orgText: {
    padding: '0.75rem 0',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  //try ovverride
  step: {
    '& $completed': {
      color: '#008002',
    },
  },
  //the following enables ovverride and add other custom styles
  completed: {},
});

function getSteps() {
  return ['Confirm details', 'Consent & agreement'];
}

class AzureSignUp extends Component {
  state = {
    loading: false,
    //for conditional render
    activeStep: 0,
    userDetails: {},
    fullName: '',
    designation: '',
    consent1: false,
    consent2: false,
    disableNext: false,
    disableBack: false,
    errors: {},
  };

  componentDidMount() {
    const { signupToken } = this.props.match.params;
    const data = { signupToken: signupToken };
    this.props.getSignupTokenDetails(data, this.props.history);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.invitedUserData !== this.props.invitedUserData &&
      this.props.invitedUserData.status === true
    ) {
      this.setState({
        userDetails: this.props.invitedUserData.data.userDetails,
        //get full name from user data, designation is not pre-set currently in invitaion form
        fullName: this.props.invitedUserData.data.userDetails.fullName,
        loading: false,
      });
    }
    if (
      prevProps.signUp !== this.props.signUp &&
      this.props.signUp.status === false
    ) {
      let key = Object.keys(this.props.signUp.errorDescription)[0];
      this.setState({
        disableNext: false,
        disableBack: false,
        errors: {
          consent: this.props.signUp.errorDescription[key],
        },
      });
    }
  }

  handleNext = () => {
    //write validations here to proceed to next step
    switch (this.state.activeStep) {
      case 0:
        return this.validateConfirmDetails();
      case 1:
        return this.validateConsent();
      default:
        return this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  // for password & confirm password
  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      errors: {},
    });
  };

  handleConsent = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
      errors: {},
    });
  };

  //validate user name
  validateConfirmDetails = () => {
    const { fullName } = this.state;
    if (!isValid(fullName)) {
      this.setState({
        errors: {
          fullName: 'Please enter your Name.',
        },
      });
    } else {
      this.setState({ activeStep: 1 });
    }
  };

  //validate Consent
  validateConsent = () => {
    const { consent1, consent2 } = this.state;
    if (!consent1 || !consent2) {
      this.setState({
        errors: {
          consent: 'Please agree with all terms & conditions',
        },
      });
    } else {
      this.setState({ disableNext: true, disableBack: true });
      // create account API call here
      let { signupToken } = this.props.match.params;
      let {
        userDetails,
        fullName,
        designation,
        consent1,
        consent2,
      } = this.state;
      let data = {
        signupToken,
        userDetails: {
          ...userDetails,
          fullName,
          consent1,
          consent2,
          designation,
        },
      };
      this.props.joinNetworkAzureUser(data, this.props.history);
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const {
      loading,
      activeStep,
      userDetails,
      fullName,
      designation,
      consent1,
      consent2,
      errors,
      disableNext,
      disableBack,
    } = this.state;

    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <ConfirmDetails
              activeStep={activeStep}
              handleChange={this.handleChange}
              maskedEmailAddress={userDetails.maskedEmailAddress}
              organizationName={userDetails.organizationName}
              collaboratorTypeName={userDetails.collaboratorTypeName}
              collaboratorRoleName={userDetails.collaboratorRoleName}
              fullName={fullName}
              designation={designation}
              errors={errors}
            />
          );
        case 1:
          return (
            <ConsentAgreement
              activeStep={activeStep}
              handleConsent={this.handleConsent}
              consent1={consent1}
              consent2={consent2}
              errors={errors}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    };
    return (
      <Grid style={{ backgroundColor: '#F2F2F2' }}>
        {loading === true ? (
          <div style={{ textAlign: 'center', marginTop: '25%' }}>
            <CircularProgress color='primary' thickness={7} />
          </div>
        ) : (
          <Grid container justify='center' className={classes.alignGrid}>
            <Grid item xs={11} sm={9} md={8} className={classes.backgroundGrid}>
              <Grid container justify='center'>
                <Grid item xs={11} md={10}>
                  {/**This Grid acts as wrapper */}
                  <Grid container direction='column' justify='center'>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'center', marginTop: '1.5rem' }}
                    >
                      <Typography
                        variant='body1'
                        style={{ fontSize: '1.2rem' }}
                      >
                        Join Pharma Contract Manufacturing Ecosystem
                      </Typography>
                      <Typography variant='body1' className={classes.orgText}>
                        {userDetails.organizationName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step
                            key={label}
                            classes={{
                              root: classes.step,
                            }}
                          >
                            <StepLabel
                              StepIconProps={{
                                classes: {
                                  root: classes.step,
                                  completed: classes.completed,
                                },
                              }}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                    <Grid item xs={12}>
                      {getStepContent(activeStep)}
                    </Grid>
                    <Grid item xs={12} className={classes.bottomgrid}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {activeStep === 0 || disableBack === true ? (
                            <Typography />
                          ) : (
                            <Typography
                              variant='caption'
                              onClick={this.handleBack}
                              style={{ cursor: 'pointer' }}
                            >
                              &lt; Back
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <StyledButton
                            size='small'
                            onClick={this.handleNext}
                            style={{
                              paddingLeft: '2rem',
                              paddingRight: '2rem',
                            }}
                            disabled={disableNext}
                          >
                            <Typography variant='caption'>
                              {activeStep === 1
                                ? 'Accept & Create Account'
                                : 'Next'}
                            </Typography>
                          </StyledButton>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <br />
        <br />
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  invitedUserData: state.auth.invitedUserData,
  signUp: state.auth.adminSignUp,
});
export default connect(mapStateToProps, {
  getSignupTokenDetails,
  joinNetworkAzureUser,
})(withStyles(Styles)(withRouter(AzureSignUp)));
