import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PhoneInput from 'react-phone-input-2';
import Typography from '@material-ui/core/Typography';
import StyledButton from '../../common/models/Button';
import TextField from '@material-ui/core/TextField';
import { sendOtp, verifyOtp } from '../../../containers/actions/inviteUsers';
import { Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import { isValidPhoneNumber } from '../../common/helperFunctions/helper';
import { fetchTwoStepAuthDetails } from '../../../containers/actions/inviteUsers';
import BottomLink from '../../common/BottomLink';

const styles = (theme) => ({
  header: {
    textAlign: 'center',
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.65rem',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  text: {
    fontSize: '1rem',
    padding: '0.5rem',
  },
  textPadding: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  headingText: {
    fontWeight: '500',
    padding: '0.5rem',
  },
  root: {
    minWidth: 275,
    padding: '0.75rem',
    marginTop: '3rem',
    boxShadow: 'none',
  },
  confirmTextfield: {
    // marginLeft: '-3.6rem',
  },
  btns: {
    textAlign: 'center',
  },
  helperTextFontSize: {
    fontSize: '0.75rem',
  },
  verifyheadertext: {
    fontSize: '1.7rem',
    fontWeight: 500,
    padding: '0.5rem',
  },
  inputClass: {
    width: '24rem !important',
    height: '2.5rem  !important',
    [theme.breakpoints.down('xs')]: {
      width: '16rem  !important',
      height: '2.5rem  !important',
    },
  },
});

class TwoStepAuthCards extends React.Component {
  state = {
    twoStepAuthDetails: {
      editPhoneNumber: false,
      phoneNumber: '91',
    },
    isOTPSent: false,
    otpValue: '',
    responseData: {},
    errors: {},
    userData: {},
    resendOtp: false,
    dialCode: '91',
    country: 'in',
    disableNextButton: false,
    disableVerifyButton: false,
  };

  componentDidMount() {
    this.props.fetchTwoStepAuthDetails({}, this.props.history);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.twoStepAuthDetails !== prevProps.twoStepAuthDetails &&
      this.props.twoStepAuthDetails.status === true
    ) {
      this.setState({
        twoStepAuthDetails: this.props.twoStepAuthDetails.data,
      });
    }

    if (
      this.props.otpSent !== prevProps.otpSent &&
      this.props.otpSent.status === false
    ) {
      this.setState({
        errors: {
          phoneNumber: this.props.otpSent.errorDescription,
        },
        disableNextButton: false,
      });
    }

    if (
      prevProps.otpSent !== this.props.otpSent &&
      this.props.otpSent.status === true
    ) {
      this.setState({
        isOTPSent: true,
        responseData: this.props.otpSent.data,
        otpError: '',
        disableNextButton: false,
      });
    }
    if (
      prevProps.otpVerified !== this.props.otpVerified &&
      this.props.otpVerified.status === false
    ) {
      this.setState({
        errors: {
          verifyError: {
            message: this.props.otpVerified.errorDescription.message
              ? this.props.otpVerified.errorDescription.message
              : this.props.otpVerified.errorDescription,
          },
        },
        disableVerifyButton: false,
      });
    }
  }

  changePhoneNumber = (phoneNumber, country) => {
    this.setState((prevState) => ({
      ...prevState,
      twoStepAuthDetails: {
        ...prevState.twoStepAuthDetails,
        phoneNumber,
      },
      country: country.countryCode,
      dialCode: country.dialCode,
      errors: {},
    }));
  };

  handleClose = () => {
    this.setState({
      resendOtp: false,
    });
  };
  handleCloseResendOTPDialog = () => {
    this.setState({
      resendOtp: false,
    });
  };

  getOTP = (event) => {
    event.preventDefault();
    const { twoStepAuthDetails, dialCode } = this.state;

    if (
      isValidPhoneNumber(twoStepAuthDetails.phoneNumber.slice(2), dialCode) ===
      true
    ) {
      let str = twoStepAuthDetails.phoneNumber;
      let patt = new RegExp('\\+');
      let res = patt.test(str);
      if (res === false) {
        twoStepAuthDetails.phoneNumber = `+${twoStepAuthDetails.phoneNumber}`;
      }
      this.props.sendOtp({ phoneNumber: twoStepAuthDetails.phoneNumber });
      this.setState({
        errors: {},
        disableNextButton: true,
      });
    } else {
      this.setState({
        errors: { phoneNumber: 'Invalid Phone Number' },
      });
    }
  };

  verifyOTP = (event) => {
    event.preventDefault();
    const { otpValue, responseData } = this.state;
    if (otpValue === '') {
      this.setState({
        errors: { verifyError: { message: 'Please enter verification code' } },
      });
    } else {
      this.props.verifyOtp(
        {
          otpValue,
          otpToken: responseData.otpToken,
        },
        this.props.history
      );
      this.setState({ disableVerifyButton: true });
    }
  };

  handleChangeCode = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      otpValue: e.target.value,
      errors: {},
    }));
  };

  resendOTP = () => {
    const { twoStepAuthDetails } = this.state;
    let checkPrefixPlus = new RegExp('\\+');
    let res = checkPrefixPlus.test(twoStepAuthDetails.phoneNumber);
    if (res === false) {
      twoStepAuthDetails.phoneNumber = `+${twoStepAuthDetails.phoneNumber}`;
    }
    this.props.sendOtp({ phoneNumber: twoStepAuthDetails.phoneNumber });
    this.setState({
      resendOtp: true,
      otpValue: '',
    });
  };

  render() {
    const { classes } = this.props;
    const {
      twoStepAuthDetails,
      isOTPSent,
      otpValue,
      responseData,
      resendOtp,
      country,
      errors,
      disableNextButton,
      disableVerifyButton,
    } = this.state;

    return (
      <Grid container alignItems='center' justify='center'>
        <Grid item xs={10} sm={8} md={8}>
          <Grid container alignItems='center' justify='center'>
            {!isOTPSent ? (
              <Card className={classes.root}>
                <CardContent>
                  <form id='getOTP' onSubmit={this.getOTP}>
                    <Grid item xs={12} className={classes.header}>
                      <Typography variant='h4' className={classes.headingText}>
                        2-Step Authentication
                      </Typography>
                      <Typography variant='body2'>
                        For security reasons we want to verify it's you.
                        <br />
                        <br />
                      </Typography>
                      <Typography variant='body2'>
                        Enter or verify your phone number to get a text message
                        <br /> with a verification code.
                      </Typography>
                      <br />

                      <Grid item xs={12}>
                        <PhoneInput
                          name='phoneNumber'
                          id='phoneNumber'
                          country={country}
                          value={twoStepAuthDetails.phoneNumber}
                          onChange={(phone, country) =>
                            this.changePhoneNumber(phone, country)
                          }
                          // enableSearch
                          defaultErrorMessage='error'
                          disabled={!twoStepAuthDetails.editPhoneNumber}
                          placeholder='Phone Number'
                          inputClass={classes.inputClass}
                          // inputStyle={{
                          //   width: '24rem',
                          //   height: '2.5rem',
                          // }}
                        />
                        <Grid container justify='flex-start'>
                          <Typography
                            variant='caption'
                            style={{
                              fontSize: '0.75rem',
                              color: errors.phoneNumber
                                ? '#f44336'
                                : '#0000008a',
                            }}
                          >
                            {errors.phoneNumber
                              ? errors.phoneNumber
                              : 'Enter your phone number'}
                          </Typography>
                        </Grid>
                        <br />
                        <br />
                      </Grid>
                      <br />
                    </Grid>
                    <Grid item xs={12} className={classes.btns}>
                      <StyledButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        size='medium'
                        style={{
                          padding: '0.25rem 3rem',
                        }}
                        disabled={disableNextButton}
                      >
                        Next
                      </StyledButton>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            ) : (
              <Card className={classes.root}>
                <CardContent>
                  <form id='verifyOTP' onSubmit={this.verifyOTP}>
                    <Grid item xs={12} className={classes.header}>
                      <Typography
                        variant='h4'
                        className={classes.verifyheadertext}
                      >
                        Enter Verification Code
                      </Typography>
                      <Typography variant='body2'>
                        For security reasons we want to verify it's you.
                        <br />
                        <br />
                      </Typography>
                      <Typography
                        variant='body2'
                        style={{
                          border: '0.15rem solid #E2E2E2',
                          backgroundColor: '#F8F8F8',
                          padding: '0.5rem',
                        }}
                      >
                        A text message with code has been sent to
                        <br />({responseData.maskedPhoneNumber})
                      </Typography>
                      <br />
                      {errors.verifyError && (
                        <Grid item xs={12} sm={12}>
                          <Typography
                            variant='h6'
                            style={{
                              border: '1px solid #FF0000',
                              backgroundColor: '#FFF0F0',
                              opacity: '1',
                              textAlign: 'center',
                              padding: '0.8rem',
                              fontSize: '0.75rem',
                            }}
                          >
                            {errors.verifyError.message}
                            <br />
                          </Typography>
                        </Grid>
                      )}
                      <br />
                      <Grid item xs={12} sm={12}>
                        <TextField
                          //   fullWidth
                          variant='outlined'
                          size='small'
                          name='enterCode'
                          id='enterCode'
                          autoComplete='off'
                          onChange={this.handleChangeCode}
                          value={otpValue}
                          label='Enter Code'
                          helperText={
                            <Typography
                              variant='caption'
                              className={classes.helperTextFontSize}
                            >
                              Enter Verification Code sent to your Phone Number
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid item xs={12} className={classes.btns}>
                      <StyledButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        size='medium'
                        disabled={disableVerifyButton}
                        style={{
                          padding: '0.25rem 3rem',
                        }}
                      >
                        Verify
                      </StyledButton>
                      <br />
                      <br />

                      <Typography style={{ fontWeight: 500 }}>
                        Didn't receive the code?
                      </Typography>
                      <Button
                        disableRipple
                        disableFocusRipple
                        disableElevation
                        onClick={this.resendOTP}
                        style={{
                          color: '#EC6535',
                          textTransform: 'none',
                          backgroundColor: 'transparent',
                          fontSize: '1rem',
                          textDecoration: 'underline',
                        }}
                      >
                        Resend Code again
                      </Button>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            )}

            <Grid
              container
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {!isOTPSent ? (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <br />
                  <br />
                  <BottomLink
                    typo='Unable to modify/edit the phone number?'
                    link='Contact the ecosystem administrator for details'
                    to='/contactadmin'
                  />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <br />
                  <br />
                  <Typography style={{ fontWeight: 500 }}>
                    Wrong Number?
                  </Typography>
                  <Button
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    onClick={() => {
                      this.setState({
                        isOTPSent: false,
                      });
                    }}
                    style={{
                      color: '#EC6535',
                      textTransform: 'none',
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      fontSize: '1rem',
                    }}
                  >
                    Change Number
                  </Button>
                </Grid>
              )}
            </Grid>

            <Dialog open={resendOtp} onClose={this.handleCloseResendOTPDialog}>
              <DialogContent style={{ padding: '2rem' }}>
                <Grid container justify='flex-end'>
                  <Typography
                    variant='body2'
                    className={classes.closeText}
                    onClick={this.handleClose}
                  >
                    Close <CloseIcon fontSize='large' />
                  </Typography>
                  <Grid item xs={12} className={classes.iconGrid}>
                    <CheckCircleIcon className={classes.icon} />
                  </Grid>
                </Grid>
                <Grid style={{ padding: '1rem 3rem 1rem 3rem' }}>
                  <Typography variant='h5' className={classes.saved}>
                    Verification Code has been sent
                  </Typography>
                  <Grid style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <Typography
                      variant='body2'
                      className={`${classes.text} ${classes.textPadding}`}
                      style={{
                        border: '0.15rem solid #E2E2E2',
                        backgroundColor: '#F8F8F8',
                      }}
                    >
                      A text message with code has been to sent to
                      {/* (+**) */}({responseData.maskedPhoneNumber})
                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <StyledButton
                      size='small'
                      style={{ padding: '0.25rem 3rem' }}
                      onClick={this.handleClose}
                    >
                      Ok
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  twoStepAuthDetails: state.usersInvitation.twoStepAuthDetails,
  otpSent: state.usersInvitation.otpSent,
  otpVerified: state.usersInvitation.otpVerified,
});

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, { sendOtp, verifyOtp, fetchTwoStepAuthDetails })(
      TwoStepAuthCards
    )
  )
);
