import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  contactText: {
    display: 'inline',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  gridAlign: {
    // margin: '2rem',
    textAlign: 'center',
  },
  text: {
    display: 'inline',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

export default function ContactAdmin(props) {
  const classes = useStyles();
  const { show } = props;
  return (
    <Grid item xs={10} sm={10} md={8} className={classes.gridAlign}>
      <br />
      <br />
      {show && (
        <Typography className={classes.text}>
          Don't have an account on this ecosystem yet?&#160;
        </Typography>
      )}
      <Typography
        variant='body2'
        color='primary'
        className={classes.contactText}
        component={Link}
        to='/contactadmin'
      >
        Contact the ecosystem administrator for an invitation
      </Typography>
      <br />
      <br />
    </Grid>
  );
}
