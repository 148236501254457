import React from 'react';
import HeaderFooterwithCookies from  '../common/openPages/HeaderFooterwithCookies';
import MultiDashboard from '../multiUserDashboard/MultiDashboard';
const SwitchEcosystem = props => {
  return (
    <HeaderFooterwithCookies >
      <MultiDashboard/>
    </HeaderFooterwithCookies>
  );
};

export default SwitchEcosystem;