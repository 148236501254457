import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import LoginSettings from './LoginSettings';
import PasswordSettings from './PasswordSettings';
import SessionSettings from './SessionSettings';
import EmailSettings from './EmailSettings';
import TwoStepAuthSettings from './TwoStepAuthSettings';
import StyledButton from '../../../common/models/Button';
import { compareValues } from '../../../common/helperFunctions/helper';
// import SaveSettings from './SaveSettings';
import StyledDialog from '../../../common/models/Dialog';

//actions
import {
  getMosymphonySettings,
  updateMosymphonySettings,
  resetToDefault,
} from '../../../../containers/actions/ecosystemActions';

//import GA
import { trackEvent } from '../../../../tracking/index';

class EcosystemSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminSettings: {
        login: {
          maxLoginAttempts: 1,
        },
        password: {
          minCharacters: 8,
          maxCharacters: 10,
          isOneCapitalLetter: false,
          isOneSmallLetter: false,
          isOneNumber: false,
          isAlphanumeric: true,
          mustHaveSpecialCharacters: true,
          shouldNotHaveNumbers: false,
          expirationTime: 80,
        },

        session: {
          inActivityTime: 15,
          isPasswordRequiredAfterLogout: false,
          isLogoutForcedAfterInactivity: true,
        },
        email: {
          inviteEmailExpirationTime: 15,
          passwordResestEmailExpirationTime: 15,
        },
        twoStepAuthentication: {
          defaultTwoStepAuthentication: 'Mobile Number',
          maxOTPAttemptsAllowed: 3,
        },
      },
      settingsSaved: false,
      disableSaveButton: false,
      errors: {},
    };
  }
  componentDidMount() {
    this.props.getMosymphonySettings();
    if (this.props.mosymphonySettings !== undefined) {
      this.setState({
        adminSettings: this.props.mosymphonySettings.data,
      });
    }
  }

  // for object comparision getderived state from props will not work out here
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.mosymphonySettings &&
      nextProps.mosymphonySettings.status === true &&
      nextProps.mosymphonySettings !== this.props.mosymphonySettings
    ) {
      this.setState({
        adminSettings: nextProps.mosymphonySettings.data,
      });
    }
    if (
      nextProps.settingsUpdate &&
      nextProps.settingsUpdate.status === true &&
      nextProps.settingsUpdate !== this.props.settingsUpdate
    ) {
      this.setState({
        settingsSaved: true,
        disableSaveButton: false,
      });
    }
    if (
      nextProps.settingsReset &&
      nextProps.settingsReset.status === true &&
      nextProps.settingsReset !== this.props.settingsReset
    ) {
      this.setState({
        settingsSaved: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.mosymphonySettings &&
      prevState.mosymphonySettings.status === true &&
      prevState.mosymphonySettings.data !== this.props.mosymphonySettings.data
    ) {
      this.setState({
        adminSettings: prevState.mosymphonySettings.data,
      });
    }
  }

  handleChange = (obj, event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      adminSettings: {
        ...prevState.adminSettings,
        [obj]: {
          ...prevState.adminSettings[obj],
          [name]: value !== '' ? parseInt(value) : value,
        },
      },
      errors: {},
    }));
  };

  // If other select fields added to the form either follow above method or use a different handler
  handleChangeAuthProcess = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      adminSettings: {
        ...prevState.adminSettings,
        twoStepAuthentication: {
          ...prevState.adminSettings.twoStepAuthentication,
          [name]: value,
        },
      },
    }));
  };

  handleCheck = (obj, event) => {
    const { name, checked } = event.target;

    //handle contadicting checks first
    if (name === 'shouldNotHaveNumbers' && checked === true) {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
            isOneNumber: false,
            isAlphanumeric: false,
          },
        },
      }));
    } else if (name === 'isOneNumber' && checked === true) {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
            shouldNotHaveNumbers: false,
          },
        },
      }));
    } else if (name === 'isAlphanumeric' && checked === true) {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
            shouldNotHaveNumbers: false,
          },
        },
      }));
    } else if (name === 'isPasswordRequiredAfterLogout' && checked === true) {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
            isLogoutForcedAfterInactivity: false,
          },
        },
      }));
    } else if (name === 'isLogoutForcedAfterInactivity' && checked === true) {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
            isPasswordRequiredAfterLogout: false,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        adminSettings: {
          ...prevState.adminSettings,
          [obj]: {
            ...prevState.adminSettings[obj],
            [name]: checked,
          },
        },
      }));
    }
  };

  validate = () => {
    let isError = false;
    const {
      login,
      password,
      session,
      email,
      twoStepAuthentication,
    } = this.state.adminSettings;
    let errors = {};

    if (!compareValues(login.maxLoginAttempts, 1, 99).status) {
      isError = true;
      errors.maxLoginAttempts = 'Value should be between 1 and 99';
    } else if (!compareValues(password.minCharacters, 1, 99).status) {
      isError = true;
      errors.minCharacters = 'Value should be between 1 and 99';
    } else if (password.minCharacters > password.maxCharacters) {
      isError = true;
      errors.minCharacters =
        'Value should be less than maximum allowed characters';
    } else if (!compareValues(password.maxCharacters, 1, 99).status) {
      isError = true;
      errors.maxCharacters = 'Value should be between 1 and 99';
    } else if (!compareValues(password.expirationTime, 1, 365).status) {
      isError = true;
      errors.expirationTime = 'Value should be between 1 and 365';
    } else if (!compareValues(session.inActivityTime, 1, 1440).status) {
      isError = true;
      errors.inActivityTime = 'Value should be between 1 and 1440';
    } else if (!compareValues(email.inviteEmailExpirationTime, 1, 48).status) {
      isError = true;
      errors.inviteEmailExpirationTime = 'Value should be between 1 and 48';
    } else if (
      !compareValues(email.passwordResestEmailExpirationTime, 1, 48).status
    ) {
      isError = true;
      errors.passwordResestEmailExpirationTime =
        'Value should be between 1 and 48';
    } else if (
      !compareValues(twoStepAuthentication.maxOTPAttemptsAllowed, 1, 99).status
    ) {
      isError = true;
      errors.maxOTPAttemptsAllowed = 'Value should be between 1 and 99';
    }

    this.setState({ errors });
    return isError;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    trackEvent('Admin Settings', 'Save Settings', 'settings');
    let err = this.validate();
    if (!err) {
      const { adminSettings } = this.state;
      this.props.updateMosymphonySettings(adminSettings);
      this.setState({ disableSaveButton: true });
    }
  };

  //Reset Settings to default
  resetValues = () => {
    trackEvent('Admin Settings', 'Reset Settings', 'settings');
    this.props.resetToDefault();
  };
  // close dialog
  handleClose = () => {
    this.setState({ settingsSaved: false });
  };

  render() {
    const {
      login,
      password,
      session,
      email,
      twoStepAuthentication,
    } = this.state.adminSettings;

    const { settingsSaved, errors, disableSaveButton } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            style={{ fontWeight: 500, fontSize: '1.75rem' }}
          >
            Mosymphony Settings
          </Typography>
        </Grid>
        <LoginSettings
          maxLoginAttempts={login.maxLoginAttempts}
          handleChange={this.handleChange}
          error={errors.maxLoginAttempts}
        />
        <PasswordSettings
          minCharacters={password.minCharacters}
          maxCharacters={password.maxCharacters}
          isOneCapitalLetter={password.isOneCapitalLetter}
          isOneSmallLetter={password.isOneSmallLetter}
          isOneNumber={password.isOneNumber}
          isAlphanumeric={password.isAlphanumeric}
          mustHaveSpecialCharacters={password.mustHaveSpecialCharacters}
          shouldNotHaveNumbers={password.shouldNotHaveNumbers}
          expirationTime={password.expirationTime}
          handleChange={this.handleChange}
          handleCheck={this.handleCheck}
          //errors
          errors={errors}
        />
        <SessionSettings
          inActivityTime={session.inActivityTime}
          isPasswordRequiredAfterLogout={session.isPasswordRequiredAfterLogout}
          isLogoutForcedAfterInactivity={session.isLogoutForcedAfterInactivity}
          handleChange={this.handleChange}
          handleCheck={this.handleCheck}
          //errors
          errors={errors}
        />
        <EmailSettings
          inviteEmailExpirationTime={email.inviteEmailExpirationTime}
          passwordResestEmailExpirationTime={
            email.passwordResestEmailExpirationTime
          }
          handleChange={this.handleChange}
          //errors
          errors={errors}
        />
        <TwoStepAuthSettings
          defaultTwoStepAuthentication={
            twoStepAuthentication.defaultTwoStepAuthentication
          }
          maxOTPAttemptsAllowed={twoStepAuthentication.maxOTPAttemptsAllowed}
          handleChange={this.handleChange}
          handleChangeAuthProcess={this.handleChangeAuthProcess}
          //errors
          errors={errors}
        />
        <Grid item xs={12}>
          <br />
          <br />
          <Grid container alignItems='center' justify='space-between'>
            <Grid item xs={12} sm={4} md={3}>
              <StyledButton
                onClick={this.handleSubmit}
                disabled={disableSaveButton}
              >
                Save Changes
              </StyledButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              style={{ paddingTop: '0.75rem', paddingLeft: '0.2rem' }}
            >
              <Typography
                variant='caption'
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={this.resetValues}
              >
                Reset To Default
              </Typography>
            </Grid>
          </Grid>
          <br />
          <br />
        </Grid>
        <Dialog open={settingsSaved} onClose={this.handleClose}>
          <StyledDialog
            title='Settings has been Saved'
            subtitle='Your settings have been saved and will take effect soon'
            handleClose={this.handleClose}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => ({
  mosymphonySettings: state.ecosystems.mosymphonySettings,
  settingsUpdate: state.ecosystems.settingsUpdate,
  settingsReset: state.ecosystems.settingsReset,
});
export default connect(mapStatetoProps, {
  getMosymphonySettings,
  updateMosymphonySettings,
  resetToDefault,
})(EcosystemSettings);
