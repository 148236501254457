import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSASLDetails } from '../../../../containers/actions/common/saslAction';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
// import AlteredSingleRow from './AlteredSingleRow';
//files import
import TableWrapper from '../../wrappers/tableWrapper';
import { header } from './header';
import ExpandableRow from './ExpandableRow';

// Test model Table cell for header
import TableHeaderCell from '../../models/table/CustomTableHeaderCellPagination';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme) => ({
  paper: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #C6C6C6',
  },
  table: {
    minWidth: 1200,
    width: '100%',
  },
  hidden: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  inherited: {
    display: 'table-cell',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  labelHeader: {
    flexDirection: 'initial',
    '&:hover': {
      color: 'black',
    },
    '&:active': {
      color: 'black',
    },
  },
});
class ScheduleAgreementTable extends Component {
  state = {
    loading: false,
    selected: [],
    checked: false,
    sampleSelect: [],
    totalLength: 0,
    scheduleAgreement: [],
    orderBy: 'lastUpdatedDate',
    order: 'desc',
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scheduleAgreement !== prevProps.scheduleAgreement) {
      this.setState({
        loading: this.props.loading,
        scheduleAgreement: this.props.scheduleAgreement,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      checked: event.target.checked,
    });
    if (event.target.checked) {
      let arr = [];
      let newSelecteds = this.state.scheduleAgreement.map((n) => {
        if (n.SASLDetails.deliveryScheduleDetails.length > 1) {
          arr = [].concat(
            ...n.SASLDetails.deliveryScheduleDetails.map((q) => {
              return q.schrockenDeliveryScheduleID.toString();
            })
          );
          return arr;
        } else {
          return n.SASLDetails.schrockenSAID.toString();
        }
      });
      newSelecteds = [].concat(...newSelecteds);
      this.setState({
        selected: newSelecteds,
      });
      return;
    }
    this.setState({
      selected: [],
      sampleSelect: [],
    });
  };

  createSortHandler = (sort, property) => (event) => {
    this.handleRequestSort(event, sort, property);
  };
  handleRequestSort = (event, sort, property) => {
    const isAsc =
      this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isAsc ? 'asc' : 'desc',
      orderBy: property,
    });
    this.props.getOrderAndColumn(sort, property);
  };

  selectedArrayValue = (data) => {
    if (Array.isArray(data) === true) {
      data.map((d) => {
        return this.setState((prevState) => ({
          selected: prevState.selected.filter(
            (n, i) => n !== Object.keys(d)[0]
          ),
        }));
      });
    } else {
      if (Object.values(data)[0] === true) {
        if (this.state.selected.indexOf(Object.keys(data)[0]) === -1) {
          this.setState((prevState) => ({
            selected: [...prevState.selected, Object.keys(data)[0]],
          }));
        }
      } else if (Object.values(data)[0] === false) {
        this.setState((prevState) => ({
          selected: this.state.selected.filter(
            (n, i) => n !== Object.keys(data)[0]
          ),
        }));
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { scheduleAgreement, loading, orderBy, order } = this.state;
    return (
      <TableWrapper>
        <TableContainer component={Paper} className={classes.paper}>
          <Table
            id='grnreceipts'
            className={classes.table}
            aria-label='collapsible table'
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: '1%',
                    maxWidth: 0,
                    overflow: 'hidden',
                  }}
                />

                {header.map((headCell) => (
                  <TableHeaderCell
                    isSortEnable={headCell.isSortEnable}
                    sortingFunction={this.createSortHandler}
                    cellData={headCell}
                    order={order}
                    orderBy={orderBy}
                    key={headCell.id}
                    elementStyle={{
                      verticalAlign: 'top',
                      width: headCell.width ? headCell.width : 'auto',
                    }}
                  />
                ))}
              </TableRow>
              {this.props.tableLoading === true && (
                <TableRow style={{ padding: 0 }}>
                  <TableCell colSpan={16} style={{ padding: 0 }}>
                    <LinearProgress color='secondary' />
                  </TableCell>
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              <React.Fragment>
                {(loading === true ||
                  (scheduleAgreement.length === 0 &&
                    this.props.scheduleAgreement.length !== 0)) && (
                  <TableRow style={{ height: 150 }}>
                    <TableCell colSpan={header.length}>
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress color='primary' thickness={7} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {loading === false &&
                this.props.scheduleAgreement.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={header.length} align='center'>
                      No Records Found
                    </TableCell>
                  </TableRow>
                ) : (
                  scheduleAgreement.map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      keys={i}
                      checked={this.state.checked}
                      selected={this.state.selected}
                      sampleSelect={this.state.sampleSelect}
                      selectedArrayValue={this.selectedArrayValue}
                      searchFilterFlag={this.props.searchFilterFlag}
                      stableSort={this.stableSort}
                      getComparator={this.getComparator}
                      order={this.state.order}
                      orderBy={this.state.orderBy}
                      searchQuery={this.props.searchQuery}
                      loading={this.props.tableLoading}
                    />
                  ))
                )}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    );
  }
}

function Row(props) {
  const selectedArrayValue = (data) => {
    props.selectedArrayValue(data);
  };
  return (
    <ExpandableRow
      row={props.row}
      keys={props.keys}
      checked={props.checked}
      selected={props.selected}
      selectedArrayValue={selectedArrayValue}
      stableSort={props.stableSort}
      getComparator={props.getComparator}
      order={props.order}
      orderBy={props.orderBy}
      searchQuery={props.searchQuery}
      loading={props.loading}
    />
  );
}

ScheduleAgreementTable.propTypes = {
  fetchSASLDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fetchSASLData: state.pcmp.cmo.shortFalls.fetchSASLData,
  tableLoading: state.pcmp.common.files.tableLoading,
});
export default withStyles(styles)(
  connect(mapStateToProps, { fetchSASLDetails })(
    withRouter(ScheduleAgreementTable)
  )
);
