import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  gridBottom: {
    marginBottom: '1rem',
  },
  helperTextFontSize: {
    fontSize: '0.75rem',
  },
  textFieldMinWidth: {
    minWidth: '14rem',
  },
});

const SettingsInputTextField = ({ children, ...props }) => {
  const classes = useStyles();
  const {
    id,
    name,
    value,
    minValue,
    maxValue,
    error,
    label,
    description,
    handleChange,
    obj,
  } = props;
  return (
    <TextField
      variant='outlined'
      size='small'
      name={name}
      id={id}
      type='number'
      InputProps={{
        inputProps: {
          min: minValue,
          max: maxValue,
        },
      }}
      value={value}
      onChange={(event) => handleChange(obj, event)}
      className={classes.textFieldMinWidth}
      label={label}
      helperText={
        <Typography variant='caption' className={classes.helperTextFontSize}>
          {error ? error : description}
        </Typography>
      }
      error={error ? true : false}
      inputRef={error ? (input) => input && input.focus() : null}
    >
      {children}
    </TextField>
  );
};

export default SettingsInputTextField;
