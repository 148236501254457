import React, { Component } from 'react';
import DeactivatedUserCard from './DeactivatedUserCard';
import OpenPageMediaHeader from '../common/openPages/HeaderFooterwithCookies';
import Grid from '@material-ui/core/Grid';
class DeactivateUser extends Component {
    render() {
        return (
            <OpenPageMediaHeader>
                <Grid
                    container
                    justify='center'
                >
                    <DeactivatedUserCard />
                </Grid>
            </OpenPageMediaHeader>
        );
    }
}

export default DeactivateUser;
