import React from 'react';
import Grid from '@material-ui/core/Grid';
import AdminImage from '../../../resources/images/Group 6505.svg';
import EcosystemImage from '../../../resources/images/Group 6440.svg';
import DashboardCard from './DashboardCard';

export default function SchrockenAdminDashboard() {
  return (
    <Grid container justify='center' alignItems='center'>
      <DashboardCard
        header='Login into Admin Interface'
        ImageUrl={AdminImage}
        imgTitle='Admin Image'
        description='Use this to manage ecosystem admin users, information, adjust
        settings change the default settings for ecosystem(s).'
        buttonText='View Admin Tools'
        whereTo='/schrockenAdmin/settings/ecosystemsettings'
      />
      <DashboardCard
        header='Login into your Ecosystem'
        ImageUrl={EcosystemImage}
        imgTitle='Ecosystem Image'
        description='Login into this to view your ecosystems'
        buttonText='View Ecosystem'
        whereTo='/userecosystems'
      />
    </Grid>
  );
}
