import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import StyledButton from '../../../common/models/Button';
//import components
import OrganisationDetails from '../../../common/OrganisationDetails';
import CollaboratorDetails from '../../../common/CollaboratorDetails';
import UserDetails from '../../../common/UserDetails';
import EcosystemInfo from './EcosystemInfo';
import InvitationSent from '../../../common/InvitationSent';
import { sendBrandOwner } from '../../../../containers/actions/ecosystemActions';
import { connect } from 'react-redux';
import {
  isValid,
  isValidPhoneNumber,
  isValidEmail,
} from '../../../common/helperFunctions/helper';

const useStyles = (theme) => ({
  titleGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.75rem',
    backgroundColor: '#F7F7F7',
  },
  divide: {
    marginTop: '1rem',
  },
  closeText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 500,
  },
  responsiveGrid: {
    [theme.breakpoints.down('sm')]: {
      margin: '2rem',
    },
  },
  invite: {
    textAlign: 'center',
  },
});

class InviteToEcosystem extends Component {
  state = {
    inviteSent: false,
    invitationData: {
      organizationName: '',
      collaboratorTypeName: '',
      collaboratorTypeId: '',
      collaboratorRoleName: '',
      collaboratorRoleId: '',
      email: '',
      fullName: '',
      phoneNumber: '',
      // countryCode : String ,
      ecosystemTypeName: '',
      ecosystemTypeId: '',
      ecosystemUrl: '',
    },
    countryCode: 'in',
    dialCode: '',
    errors: {},
    disableSendInvite: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.brandOwner !== this.props.brandOwner &&
      this.props.brandOwner.status === true
    ) {
      this.setState({ inviteSent: true });
    }
    if (
      prevProps.brandOwner !== this.props.brandOwner &&
      this.props.brandOwner.status === false
    ) {
      this.setState({
        errors: {
          //remove index if array error approach is changed
          ...this.props.brandOwner.errorDescription,
        },
        disableSendInvite: false,
      });
    }
  }

  validate = () => {
    const {
      organizationName,
      collaboratorTypeName,
      collaboratorTypeId,
      collaboratorRoleName,
      collaboratorRoleId,
      email,
      fullName,
      phoneNumber,
      ecosystemTypeName,
      ecosystemTypeId,
      ecosystemUrl,
    } = this.state.invitationData;

    let errors = {};
    let isError = false;
    if (!isValid(organizationName)) {
      isError = true;
      errors.organizationName = 'Please select Organization Name';
    } else if (!isValid(collaboratorTypeName)) {
      isError = true;
      errors.collaboratorTypeName = 'Please Select Collaborator Type Name';
    } else if (!isValid(collaboratorTypeId)) {
      isError = true;
      errors.collaboratorTypeId = 'Please Select Collaborator Type ID';
    } else if (!isValid(collaboratorRoleName)) {
      isError = true;
      errors.collaboratorRoleName = 'Please select collaborator role';
    } else if (!isValid(collaboratorRoleId)) {
      isError = true;
      errors.collaboratorRoleId = 'Please select collaborator role ID';
    } else if (!isValid(email)) {
      isError = true;
      errors.email = 'Please enter email';
    } else if (!isValidEmail(email)) {
      isError = true;
      errors.email = 'Please enter valid email';
    } else if (!isValid(fullName)) {
      isError = true;
      errors.fullName = 'Please enter full name';
    } else if (!isValidPhoneNumber(phoneNumber, this.state.dialCode)) {
      isError = true;
      errors.phoneNumber = 'Please enter phone number';
    } else if (phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumber = 'Please enter a valid phone number';
    } else if (!isValid(ecosystemTypeName)) {
      isError = true;
      errors.ecosystemTypeName = 'Please Select Ecosytem Type';
    } else if (!isValid(ecosystemTypeId)) {
      isError = true;
      errors.ecosystemTypeId = 'Please Select Ecosytem Type ID';
    } else if (!isValid(ecosystemUrl)) {
      isError = true;
      errors.ecosystemUrl = 'Please Enter Ecosystem URL';
    }

    this.setState({
      errors,
    });
    return isError;
  };

  handleClose = () => {
    this.setState({ inviteSent: false });
    this.props.handleClose();
  };
  sendAnotherInvite = () => {
    this.setState((prevState) => ({
      inviteSent: false,
      disableSendInvite: false,
      invitationData: {
        ...prevState.invitationData,
        organizationName: '',
        collaboratorTypeName: '',
        collaboratorTypeId: '',
        collaboratorRoleName: '',
        collaboratorRoleId: '',
        email: '',
        fullName: '',
        phoneNumber: `${this.state.dialCode}`,
        ecosystemTypeName: '',
        ecosystemTypeId: '',
        ecosystemUrl: '',
      },
    }));
  };

  //user details handles
  changePhoneNumber = (phoneNumber, country) => {
    this.setState((prevState) => ({
      invitationData: {
        ...prevState.invitationData,
        phoneNumber: phoneNumber,
      },
      country: country.countryCode,
      dialCode: country.dialCode,
      errors: {},
    }));
  };

  handleChange = (e) => {
    this.setState((prevState) => ({
      invitationData: {
        ...prevState.invitationData,
        [e.target.name]: e.target.value,
      },
      errors: {},
    }));
  };
  // collaborator component handles
  handleChangeColloborator = (data) => {
    this.setState((prevState) => ({
      invitationData: {
        ...prevState.invitationData,
        ...data,
      },
      errors: {},
    }));
  };

  handleChangeEmailandName = (e) => {
    e.persist();
    this.setState((prevState) => ({
      invitationData: {
        ...prevState.invitationData,
        [e.target.name]: e.target.value,
      },
      errors: {},
    }));
  };

  sendInvite = (event) => {
    event.preventDefault();

    let isError = this.validate();

    if (!isError) {
      let { invitationData } = this.state;
      let data = {
        ...invitationData,
        phoneNumber: `+${invitationData.phoneNumber}`,
      };
      this.setState({ disableSendInvite: true });
      this.props.sendBrandOwner(data);
    }
  };

  render() {
    const { classes, handleClose } = this.props;
    const { inviteSent, errors, disableSendInvite } = this.state;
    const {
      organizationName,
      email,
      fullName,
      phoneNumber,
      collaboratorTypeName,
      collaboratorTypeId,
      collaboratorRoleName,
      collaboratorRoleId,
      ecosystemTypeName,
      ecosystemTypeId,
      ecosystemUrl,
    } = this.state.invitationData;
    return (
      <Grid container justify='center'>
        <Grid item xs={12} className={classes.titleGrid}>
          <Grid item xs={'auto'} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8} className={classes.invite}>
            <Typography variant='h6' style={{ fontSize: '1.875rem' }}>
              Invite to create ecosystem
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <Typography
              variant='body2'
              onClick={handleClose}
              className={classes.closeText}
            >
              Cancel &#38; go back <CloseIcon fontSize='large' />
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify='center'>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={5}
            className={classes.responsiveGrid}
          >
            <form onSubmit={this.sendInvite}>
              <OrganisationDetails
                // brandOwnerOrganization prop helps in differentiating validation for
                // adding Brand owner organization and CMO organization
                brandOwnerOrganization
                organizationName={organizationName}
                handleChange={this.handleChange}
                errors={errors}
              />
              <Divider className={classes.divide} />
              <CollaboratorDetails
                collaboratorTypeName={collaboratorTypeName}
                collaboratorTypeId={collaboratorTypeId}
                collaboratorRoleName={collaboratorRoleName}
                collaboratorRoleId={collaboratorRoleId}
                handleChangeColloborator={this.handleChangeColloborator}
                errors={errors}
              />
              <Divider className={classes.divide} />
              <UserDetails
                cmsInvite
                phoneNumber={phoneNumber}
                changePhoneNumber={this.changePhoneNumber}
                email={email}
                fullName={fullName}
                handleChangeColloborator={this.handleChangeColloborator}
                handleChangeEmailandName={this.handleChangeEmailandName}
                errors={errors}
              />
              <Divider className={classes.divide} />
              <EcosystemInfo
                ecosystemTypeName={ecosystemTypeName}
                ecosystemTypeId={ecosystemTypeId}
                ecosystemUrl={ecosystemUrl}
                handleChangeColloborator={this.handleChangeColloborator}
                errors={errors}
              />
              <Grid container justify='center'>
                <StyledButton
                  type='submit'
                  disabled={disableSendInvite}
                  style={{
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    marginTop: '1rem',
                    marginBottom: '1.5rem',
                  }}
                  onClick={this.sendInvite}
                >
                  Send Invitation
                </StyledButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Dialog
          open={inviteSent}
          onClose={this.handleClose}
          email={this.state.invitationData.email}
        >
          <InvitationSent
            handleClose={this.handleClose}
            sendAnotherInvite={this.sendAnotherInvite}
            email={this.state.invitationData.email}
          />
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  brandOwner: state.ecosystems.brandOwnerInfo,
});

export default connect(mapStateToProps, { sendBrandOwner })(
  withStyles(useStyles)(InviteToEcosystem)
);
