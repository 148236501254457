import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { appUrl } from '../../../../../../../utils/config';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  cancelSty: {
    '&:hover': {
      borderBottom: '2px solid red',
    },
  },
  multipleLogo: {
    border: '1px solid #7d7c7c',
    borderRadius: '4px',
    padding: '0.3rem',
    height: 'inherit',
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
}));
const FileViewer = (props) => {
  const {
    schrockenCustomFileID,
    openFileView,
    handleFileViewClose,
    downloadFile,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openFileView);
  const [fileURL, setFileURL] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const handleClose = () => {
    setOpen(false);
    setFileURL(null);
    handleFileViewClose();
  };

  useEffect(() => {
    let url = null;
    if (schrockenCustomFileID) {
      setLoading(true);
      setOpen(true);
      setError('');
      const data = {
        schrockenCustomFileID,
      };
      axios
        .post(
          `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/downloadCustomFile`,
          data
        )
        .then((res) => {
          if (res.data.status) {
            let fileDataArray = res.data.fileData.data;
            var byteArray = new Uint8Array(fileDataArray);

            // var a = window.document.createElement('a');

            // a.href = window.URL.createObjectURL(
            //   new Blob([byteArray], { type: 'application/octet-stream' })
            // );
            const blob = new Blob([byteArray], {
              type: 'application/pdf',
            });
            url = window.URL.createObjectURL(blob);
            console.log(url);
            setFileURL(url);
            setFileName(res.data.fileName);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError('Something went wrong. Please try again.');
        });
    }

    return () => {
      window.URL.revokeObjectURL(url);
    };
  }, [schrockenCustomFileID]);

  const renderFileContent = () => {
    if (loading) {
      return (
        <Grid container justifyContent='center' style={{ padding: '2rem' }}>
          <CircularProgress />
        </Grid>
      );
    }

    if (loading === false && error !== '') {
      return (
        <Grid
          container
          justifyContent='center'
          style={{
            padding: '1rem 0',
            display: 'inherit',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              border: '1px solid #FF0000',
              backgroundColor: '#FFF0F0',
              opacity: '1',
              padding: '0.8rem',
              fontSize: '0.875rem',
              textAlign: 'center',
            }}
          >
            <Typography
              variant='h6'
              style={{
                fontSize: '0.875rem',
              }}
            >
              Something went wrong. Please try again.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid>
            <DialogTitle>{fileName ? fileName : 'File View'}</DialogTitle>
          </Grid>
          <Grid>
            <GetAppIcon
              titleAccess={fileName ? fileName : 'Download CoA'}
              fontSize='small'
              className={classes.multipleLogo}
              onClick={() => downloadFile(schrockenCustomFileID)}
            />
          </Grid>
        </Grid>
        <iframe
          src={fileURL}
          title={fileName ? fileName : 'File View'}
          width='100%'
          height='600px'
          style={{ border: 'none' }}
        ></iframe>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
        <Grid container justifyContent='flex-end'>
          <Grid item className={classes.cancelSty}>
            <Typography
              variant='body2'
              style={{
                fontSize: '1rem',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '1rem',
              }}
              onClick={handleClose}
            >
              Cancel <CloseIcon fontSize='medium' style={{ color: '#F00' }} />
            </Typography>
          </Grid>
        </Grid>

        <DialogContent>{renderFileContent()}</DialogContent>
      </Dialog>
    </div>
  );
};

export default FileViewer;
