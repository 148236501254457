import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
//password strength
import zxcvbn from 'zxcvbn';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F8F8F8',
    border: '2px solid #E2E2E2',
    padding: '1rem',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  strength: {
    border: '1px solid #E2E2E2',
    borderRadius: '0.25rem',
    padding: '0.5rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  text: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  icon: {
    paddingRight: 0,
  },
  bottomgrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '2rem',
    marginTop: '1rem',
  },
  span: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  textPadding: {
    padding: '0.75rem 0',
  },
}));

export default function CreateAccount(props) {
  const classes = useStyles();
  const {
    password,
    confirmPassword,
    passwordCriteria,
    showPassword,
    showConfirmPassword,
    handleClickPassword,
    handleClickConfirmPassword,
    handleChange,
    maskedEmailAddress,
    errors,
  } = props;

  const onCopyPaste = (event) => {
    event.preventDefault();
    return false;
  };

  const passwordStrength = zxcvbn(password).score;

  const PasswordStrengthMeter = (passwordStrength) => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return (
          <span
            //mention styles in makestyles
            className={classes.span}
            style={{ color: 'red' }}
          >
            Weak Password
          </span>
        );
      case 2:
      case 3:
        return (
          <span className={classes.span} style={{ color: '#aaad04' }}>
            Good
          </span>
        );
      case 4:
        return (
          <span className={classes.span} style={{ color: 'green' }}>
            Very Good
          </span>
        );
      default:
        return <span className={classes.span}>--</span>;
    }
  };
  return (
    <Grid container justify='space-around'>
      <Grid item xs={10} sm={10} md={11} className={classes.root}>
        <Typography variant='subtitle1'>
          Your login email address &#160;
        </Typography>

        <Typography variant='body1' style={{ fontWeight: 500 }}>
          {maskedEmailAddress}
        </Typography>
      </Grid>
      {errors.passwordCriteria && (
        <Grid item xs={10} sm={10} md={11} className={classes.textPadding}>
          <Typography
            variant='h6'
            style={{
              border: '1px solid #FF0000',
              backgroundColor: '#FFF0F0',
              opacity: '1',
              textAlign: 'center',
              padding: '0.75rem',
              fontSize: '0.75rem',
            }}
          >
            {errors.passwordCriteria}
          </Typography>
        </Grid>
      )}
      <Grid item xs={10} sm={4} md={5} className={classes.textPadding}>
        <TextField
          variant='outlined'
          required
          name='password'
          value={password}
          onChange={handleChange}
          //no copy paste allowed
          onPaste={onCopyPaste}
          onCopy={onCopyPaste}
          fullWidth
          size='small'
          label='Enter Password'
          type={showPassword === false ? 'password' : 'text'}
          autoComplete='password'
          placeholder='Enter password'
          error={errors.password ? true : false}
          helperText={
            <Typography variant='caption' style={{ fontSize: '0.615rem' }}>
              {errors.password ? errors.password : 'Please enter your password'}
            </Typography>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={handleClickPassword}
                  className={classes.icon}
                  // classes={{adornedEnd:classes.icon}}
                >
                  {showPassword === false ? (
                    <VisibilityOffIcon fontSize='small' />
                  ) : (
                    <VisibilityIcon fontSize='small' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={10} sm={4} md={5} className={classes.textPadding}>
        <TextField
          variant='outlined'
          label='Confirm Password'
          required
          name='confirmPassword'
          value={confirmPassword}
          onChange={handleChange}
          //no copy paste allowed
          onPaste={onCopyPaste}
          onCopy={onCopyPaste}
          fullWidth
          size='small'
          id='confirm_password'
          type={showConfirmPassword === false ? 'password' : 'text'}
          placeholder='Re-enter your password'
          error={errors.confirmPassword ? true : false}
          helperText={
            <Typography variant='caption' style={{ fontSize: '0.615rem' }}>
              {errors.confirmPassword
                ? errors.confirmPassword
                : 'Please re-enter your password to confirm'}
            </Typography>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={handleClickConfirmPassword}
                  className={classes.icon}
                >
                  {showConfirmPassword === false ? (
                    <VisibilityOffIcon fontSize='small' />
                  ) : (
                    <VisibilityIcon fontSize='small' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={10} sm={10} md={11}>
        <Typography
          variant='body1'
          //mention styles in makestyles
          className={classes.strength}
        >
          Password Strength&#160;:&#160;
          {password !== '' ? PasswordStrengthMeter(passwordStrength) : ''}
        </Typography>
      </Grid>

      <Grid item xs={10} md={11}>
        <Typography variant='h6' className={classes.text}>
          Password Criteria :
        </Typography>
        <ul style={{ fontSize: '1rem', margin: '0.5rem' }}>
          {passwordCriteria.isAlphanumeric === true && (
            <li>
              <Typography variant='body2'>Should be alphanumeric</Typography>
            </li>
          )}
          {passwordCriteria.mustHaveSpecialCharacters === true && (
            <li>
              <Typography variant='body2'>
                Should contain symbols (like &#64;, &#163;, &#37;, &#33; or
                &amp;)
              </Typography>
            </li>
          )}
          {passwordCriteria.isOneSmallLetter === true && (
            <li>
              <Typography variant='body2'>
                Should contain atleast one small letter
              </Typography>
            </li>
          )}
          {passwordCriteria.isOneCapitalLetter === true && (
            <li>
              <Typography variant='body2'>
                Should contain atleast one capital letter
              </Typography>
            </li>
          )}
          <li>
            <Typography variant='body2'>
              Minimum {passwordCriteria.minCharacters} characters - Maximum
              {passwordCriteria.maxCharacters} characters
            </Typography>
          </li>
          {passwordCriteria.isOneNumber === true && (
            <li>
              <Typography variant='body2'>
                Should have atleast one number
              </Typography>
            </li>
          )}
          {passwordCriteria.shouldNotHaveNumbers === true && (
            <li>
              <Typography variant='body2'>Should not have numbers</Typography>
            </li>
          )}
        </ul>
      </Grid>
    </Grid>
  );
}
