import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledCheckbox } from '../../../common/models/CheckBox';
//components
import SettingsInputTextField from './SettingsInputTextField';

const useStyles = makeStyles({
  gridTop: {
    marginTop: '1.5rem',
  },
  headerText: {
    fontSize: '1rem',
  },
  gridBottom: {
    padding: '1rem 0',
  },
});

const SessionSettings = (props) => {
  const classes = useStyles();

  const {
    inActivityTime,
    isPasswordRequiredAfterLogout,
    isLogoutForcedAfterInactivity,
    handleChange,
    handleCheck,
    errors,
  } = props;
  const checkBoxElements = [
    {
      name: 'isPasswordRequiredAfterLogout',
      checked: isPasswordRequiredAfterLogout,
      labelText: 'Require Password after Lockout',
    },
    {
      name: 'isLogoutForcedAfterInactivity',
      checked: isLogoutForcedAfterInactivity,
      labelText: 'Force Logout after user Inactivity Time',
    },
  ];
  return (
    <Grid item xs={12} className={classes.gridTop}>
      <Typography
        variant='h6'
        className={`${classes.headerText} ${classes.gridBottom}`}
      >
        Session Settings
      </Typography>

      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>Session Inactivity Time</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='inActivityTime'
            id='Settings_inActivityTime'
            obj='session'
            value={inActivityTime}
            minValue={1}
            maxValue={1440}
            handleChange={handleChange}
            label='Session Inactivity Time'
            description='Enter Session Inactivity Time in Minutes'
            error={errors.inActivityTime}
          />
        </Grid>
      </Grid>
      <FormGroup row>
        <Grid container alignItems='center' className={classes.gridBottom}>
          {checkBoxElements.map((ele, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    name={ele.name}
                    id={`settings_${ele.name}`}
                    checked={ele.checked}
                    onChange={(event) => handleCheck('session', event)}
                  />
                }
                label={
                  <Typography variant='caption'>{ele.labelText}</Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <Divider />
    </Grid>
  );
};

export default SessionSettings;
