import React, { Component } from 'react';
import MultiEcosystemCards from './MultiEcosystemCards';
import Grid from '@material-ui/core/Grid';
import OpenPageMediaHeader from '../common/openPages/HeaderFooterwithCookies';

class MultiEcosystemDashboard extends Component {
  render() {
    return (
      <OpenPageMediaHeader>
        <Grid
          container
          justify='center'
          style={{
            backgroundColor: '#f2f2f2',
          }}
        >
          <MultiEcosystemCards />
        </Grid>
      </OpenPageMediaHeader>
    );
  }
}

export default MultiEcosystemDashboard;
