import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Images from '../../../../resources/images/baseline_create_black_18dp.svg';
import ProfileImage from '../../../../resources/images/Group 6520.svg';

const useStyles = makeStyles((theme) => ({
  borderGrid: {
    border: '1px solid #707070',
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    marginTop: '1rem',
  },
  data: {
    fontSize: '1rem',
    fontWeight: 500,
    opacity: '1',
    color: '#000000',
    textAlign: 'left',
  },
  editbuttons: {
    border: '1px solid #707070',
    borderRadius: '0',
    textTransform: 'none',
    backgroundColor: 'transparent',
  },
  checkPadding: {
    padding: '0.5rem 0 0.5rem 0',
  },
  logoStyles: {
    height: '1.1rem',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  info: {
    padding: '0.35rem',
  },
  editIcon: {
    height: '2rem',
    border: '1px solid #707070',
    padding: '0.3rem',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tables: {
    minWidth: 650,
    tableLayout: 'fixed',
  },

  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '2rem',
  },
  table: {
    minWidth: 700,
  },
  editbuttonsGrid: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Tables(props) {
  const classes = useStyles();
  const { ecosystemsData } = props;

  return (
    <Grid container className={classes.borderGrid}>
      <Grid container justify='flex-end' alignItems='center'>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          className={classes.checkPadding}
          style={{ paddingTop: 0 }}
        >
          <Typography variant='body2'>Company Name</Typography>
          <Typography className={classes.data}>
            {ecosystemsData.organizationName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={8} className={classes.editbuttonsGrid}>
          <Button
            variant='text'
            disableRipple
            disableFocusRipple
            className={classes.editbuttons}
            style={{ marginRight: '0.5rem' }}
          >
            <img src={Images} alt='edit' className={classes.logoStyles} />
            <Typography variant='body2' className={classes.info}>
              Edit Information
            </Typography>
          </Button>

          <Button
            variant='text'
            className={classes.editbuttons}
            disableRipple
            disableFocusRipple
          >
            <img src={ProfileImage} alt='edit' className={classes.logoStyles} />
            <Typography variant='body2' className={classes.info}>
              View Members Info
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.checkPadding}>
        <Typography variant='body2'>Ecosystem Type ID</Typography>
        <Typography className={classes.data}>
          {ecosystemsData.ecosystemTypeId}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.checkPadding}>
        <Typography variant='body2'>Ecosystem Name</Typography>
        <Typography className={classes.data}>
          {ecosystemsData.ecosystemTypeName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.checkPadding}>
        <Typography variant='body2'>Ecosystem URL</Typography>
        <Typography className={classes.data}>
          {ecosystemsData.ecosystemURL}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.checkPadding}>
        <Typography style={{ fontWeight: 400 }}>Admin Information</Typography>
      </Grid>
      <TableContainer style={{ overflowX: 'auto' }}>
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          className={classes.tables}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align='left'
                style={{
                  paddingLeft: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordWrap: 'break-word',
                }}
              >
                Full Name
              </TableCell>
              <TableCell align='left'>Email Address</TableCell>
              <TableCell align='left'>Collaborator Type</TableCell>
              <TableCell align='right'>Collaborator Type ID</TableCell>
              <TableCell align='left'>Collaborator Role</TableCell>
              <TableCell align='right'>Collaborator Role ID</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {ecosystemsData.users.map((row, i) => (
              <React.Fragment key={i}>
                {row.emailAddress ? (
                  <TableRow role='checkbox' tabIndex={-1} key={i}>
                    <TableCell align='left' style={{ paddingLeft: 0 }}>
                      {row.fullName}
                    </TableCell>
                    <TableCell
                      align='left'
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                      }}
                    >
                      {row.emailAddress}
                    </TableCell>
                    <TableCell align='left'>
                      {row.collaboratorTypeName}
                    </TableCell>
                    <TableCell align='right'>
                      {row.collaboratorTypeId}
                    </TableCell>
                    <TableCell align='left'>
                      {row.collaboratorRoleName}
                    </TableCell>
                    <TableCell align='right'>
                      {row.collaboratorRoleId}
                    </TableCell>
                    <TableCell style={{ paddingRight: 0 }}>
                      <Button
                        disableRipple
                        disableFocusRipple
                        style={{
                          borderRadius: 0,
                          backgroundColor: 'transparent',
                        }}
                      >
                        <img
                          src={Images}
                          alt='edit'
                          className={classes.editIcon}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                      User not yet accepted invitation!
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
