import {
  BATCH_SUMMERY,
  LINE_SUMMERY,
  SUMMERY,
  BATCH_SUMMERY_DETAILS,
  LINE_SUMMERY_DETAILS,
  ALL_PURCHASE_ORDERS,
  ALL_LINE_ITEMS,
  ALL_BATCH_ITEMS,
  PO_BATCH_ITEMS,
  DATE_FORMAT_UPDATE,
} from "../../actions/types";

const intialState = {};

export default function (state = intialState, action) {
  switch (action.type) {
    case BATCH_SUMMERY:
      return {
        ...state,
        batchSummery: action.payload,
      };
    case LINE_SUMMERY:
      return {
        ...state,
        lineItemSummary: action.payload,
      };
    case SUMMERY:
      return {
        ...state,
        summary: action.payload,
      };
    case BATCH_SUMMERY_DETAILS:
      return {
        ...state,
        batchSummaryDetails: action.payload,
      };
    case LINE_SUMMERY_DETAILS:
      return {
        ...state,
        lineSummaryDetails: action.payload,
      };
    case ALL_PURCHASE_ORDERS:
      return {
        ...state,
        allPurchaseOrders: action.payload,
      };
    case ALL_LINE_ITEMS:
      return {
        ...state,
        allLineItems: action.payload,
      };
    case ALL_BATCH_ITEMS:
      return {
        ...state,
        allBatchItems: action.payload,
      };
    case PO_BATCH_ITEMS:
      return {
        ...state,
        poBatchItems: action.payload,
      };
    case DATE_FORMAT_UPDATE:
      return {
        ...state,
        dateFormatUpdated: action.payload,
      };
    
    default:
      return state;
  }
}
