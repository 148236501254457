import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import StyledButton from '../common/models/Button';
import { TextField, IconButton, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AzureLogin from '../common/AzureLogin';
import { sessionTimeout } from '../../containers/actions/userActions';
import { loginUser } from '../../containers/actions/authActions';
import { isValid } from '../common/helperFunctions/helper';
import MainCards from '../common/MainCards';

const useStyles = {
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.75rem',
  },
  root: {
    minWidth: 275,
  },
  btns: {
    textAlign: 'center',
  },
  helperTextFontSize: {
    fontSize: '0.875rem',
  },
};
class SessionTimeoutCard extends Component {
  state = {
    email: '',
    maskedEmailAddress: '',
    password: '',
    ecosystemUrl: '',
    showPassword: false,
    isAzureUser: false,
    resError: null,
    errors: {},
    disableButton: false,
  };

  componentDidMount() {
    this.props.sessionTimeout();
    this.setState({
      isAzureUser: localStorage.accountType === 'azure' ? true : false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userSessionTimeout !== this.props.userSessionTimeout &&
      this.props.userSessionTimeout.status === true
    ) {
      this.setState({
        maskedEmailAddress: this.props.userSessionTimeout.data
          .maskedEmailAddress,
        email: this.props.userSessionTimeout.data.email,
        ecosystemUrl: this.props.userSessionTimeout.data.ecosystemUrl,
      });
    }

    if (
      prevProps.userSessionTimeout !== this.props.userSessionTimeout &&
      this.props.userSessionTimeout.status === false
    ) {
      this.props.history.push('/login');
    }
    if (
      prevProps.login !== this.props.login &&
      this.props.login.status === false
    ) {
      let errorKey = Object.keys(this.props.login.errorDescription)[0];
      this.setState({
        resError: this.props.login.errorDescription[errorKey],
        disableButton: false,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let isError = this.validate();
    if (!isError) {
      const { email, password } = this.state;
      this.props.loginUser({ email, password }, this.props.history, {
        sessionLogin: true,
      });
      this.setState({ disableButton: true });
    }
  };

  validate = () => {
    let error = false;
    let errors = {};
    const { password } = this.state;
    if (!isValid(password)) {
      error = true;
      errors.password = 'Please enter password';
    }
    this.setState({ errors });
    return error;
  };

  handleChangePassword = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, errors: {}, resError: null });
  };

  handleClickToggle = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes } = this.props;
    const {
      maskedEmailAddress,
      password,
      ecosystemUrl,
      isAzureUser,
      resError,
      showPassword,
      errors,
      disableButton,
    } = this.state;
    return (
      <MainCards>
        <Grid item xs={12} className={classes.header}>
          <Typography variant='h4' className={classes.text}>
            Your session has been timed out
          </Typography>
          <Typography
            variant='body2'
            style={{
              fontSize: '1.2rem',
              padding: '0.75rem',
              display: 'inline',
            }}
          >
            Please reauthenticate your login for
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: '1.2rem',
            }}
          >
            {ecosystemUrl}
          </Typography>
        </Grid>
        <br />

        {resError && (
          <Grid container justify='center'>
            <Grid item xs={12} sm={8} md={6} style={{ marginBottom: '1rem' }}>
              <Typography
                variant='h6'
                style={{
                  border: '1px solid #FF0000',
                  backgroundColor: '#FFF0F0',
                  opacity: '1',
                  textAlign: 'center',
                  padding: '1rem',
                  fontSize: '0.75rem',
                }}
              >
                {resError}
              </Typography>
              <br />
            </Grid>
          </Grid>
        )}
        <form onSubmit={this.handleSubmit}>
          <Grid container justify='center'>
            <Grid item xs={12} sm={8} md={6}>
              <Box
                variant='body2'
                style={{
                  border: '0.15rem solid #E2E2E2',
                  backgroundColor: '#F8F8F8',
                  padding: '0.75rem',
                  textAlign: 'center',
                }}
              >
                Your login email address
                <Typography style={{ fontWeight: 500 }}>
                  {/* sa****ar@schrocken.com */}
                  {maskedEmailAddress}
                </Typography>
              </Box>
              <br />
              {!isAzureUser && (
                <Grid>
                  <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='password'
                    autoComplete='off'
                    onChange={this.handleChangePassword}
                    value={password}
                    label='Password'
                    error={errors.password ? true : false}
                    type={showPassword === false ? 'password' : 'text'}
                    helperText={errors.password ? errors.password : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Toggle password visibility'
                            onClick={this.handleClickToggle}
                          >
                            {showPassword === false ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <br />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.btns}>
            {!isAzureUser && (
              <StyledButton
                type='submit'
                variant='contained'
                color='primary'
                size='medium'
                style={{ padding: '0.25rem 3rem' }}
                disabled={disableButton}
              >
                Sign In
              </StyledButton>
            )}
            {isAzureUser && <AzureLogin />}
          </Grid>
        </form>
        <br />
      </MainCards>
    );
  }
}
const mapStateToProps = (state) => ({
  userSessionTimeout: state.user.userSessionData,
  login: state.auth.login,
});

export default connect(mapStateToProps, { sessionTimeout, loginUser })(
  withStyles(useStyles)(SessionTimeoutCard)
);
