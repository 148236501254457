import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import { StyledAppBar } from '../../common/models';
import SideNavBar from './SideNavBar';
import HeaderComponent from '../dashboard/HeaderComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentMargin: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 240,
    },
  },
}));

export default function SettingsLayout({ children, props }) {
  const [drawerOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!drawerOpen);
  };

  const classes = useStyles();

  return (
    <div>
      <CssBaseline />
      <StyledAppBar
        position='fixed'
        className={classes.appBar}
        backgroundColor='#000000'
      >
        <HeaderComponent handleDrawerToggle={handleDrawerToggle} menuNeeded />
      </StyledAppBar>
      <SideNavBar
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <main className={classes.content}>
        <Toolbar />
        <div className={classes.contentMargin}>{children}</div>
      </main>
    </div>
  );
}
