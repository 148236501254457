import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorImage from '../../resources/images/400Error.svg';
import BottomLink from '../common/BottomLink';
import MainCards from '../common/MainCards';
const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.75rem',
  },
  root: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
});
const ErrorPage404Card = (props) => {
  const classes = useStyles();
  return (
    <MainCards>
      <Grid item xs={12} className={classes.mediaGrid}>
        <img src={ErrorImage} alt='Error404Image' className={classes.media} />
      </Grid>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h4' className={classes.text}>
          Whoops! :(
        </Typography>
        <Typography style={{ padding: '0.75rem' }}>
          We can't seem to find the page you are looking for.
        </Typography>
      </Grid>
      <br />
      {/* </CardContent> */}
      <BottomLink
        typo='Looking to Sign in?'
        link='Click here to go to sign In page'
        to='/login'
      />
      <br />
      <BottomLink
        typo='Trouble with password?'
        link='Request to change your password'
        to='/resetpassword'
      />
      <br />
      <BottomLink
        typo='Do you think you have issues?'
        link='Contact the ecosystem administrator'
        to='/contactadmin'
      />
      <br /> <br />
    </MainCards>
  );
};

export default ErrorPage404Card;
