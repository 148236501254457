import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AzureRedirectCard from './AzureRedirectCard';
import HeaderFooterwithCookies from '../../common/openPages/HeaderFooterwithCookies';
import querystring from 'query-string';
import ErrorImage from '../../../resources/images/Group 6536.svg';
import MainCards from '../../common/MainCards';
import { withStyles } from '@material-ui/core/styles';
import BottomLink from '../../common/BottomLink';
import { azureUserLogin } from '../../../containers/actions/authActions';

const styles = {
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.75rem',
  },
  root: {
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
};
class AzureRedirect extends Component {
  state = {
    tokenError: null,
  };
  componentDidMount() {
    const { search } = this.props.location;
    const parsed = querystring.parse(search);
    let azureAdCode = parsed.code;
    this.props.azureUserLogin({ azureAdCode }, this.props.history);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.invitedUserData !== this.props.invitedUserData &&
      this.props.invitedUserData.status === false
    ) {
      this.setState({
        loading: false,
        tokenError: this.props.invitedUserData.errorDescription,
      });
    }
  }
  render() {
    const { tokenError } = this.state;
    const { classes } = this.props;
    return (
      <HeaderFooterwithCookies>
        {tokenError !== null ? (
          <MainCards>
            <Grid item xs={12} className={classes.mediaGrid}>
              <img
                src={ErrorImage}
                alt='Error404Image'
                className={classes.media}
              />
            </Grid>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h4' className={classes.text}>
                Hmmm… something is wrong here!
              </Typography>
              <Typography style={{ padding: '0.75rem' }}>
                Azure AD used is either not invited or you have used a different
                one while joining Mosymphony. We advice you to contact your
                administrator using the link below or try again.{' '}
              </Typography>
            </Grid>
            <br />
            <BottomLink
              typo='Looking to Sign in?'
              link='Click here to go to sign In page'
              to='/login'
            />
            <br />
            <BottomLink
              typo='Trouble with password?'
              link='Request to change your password'
              to='/resetpassword'
            />
            <br />
            <BottomLink
              typo='Do you think you have issues?'
              link='Contact the ecosystem administrator'
              to='/contactadmin'
            />
            <br /> <br />
          </MainCards>
        ) : (
          <AzureRedirectCard />
        )}
      </HeaderFooterwithCookies>
    );
  }
}

const mapStateToProps = (state) => ({
  invitedUserData: state.auth.invitedUserData,
});
export default connect(mapStateToProps, { azureUserLogin })(
  withStyles(styles)(withRouter(AzureRedirect))
);
