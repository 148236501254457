import { combineReducers } from "redux";

import dashboardReducer from "./dashboardReducer";
// import purchaseOrderReducer from './purchaseOrderReducer';
// import manageUsersReducer from './manageUsersReducer';
import batchReducer from "./addBatchItemReducer";
import rmpmReducer from "./addRmPmReducer";
import filesReducer from "./filesReducer";
import shortFallsReducer from "./shortFallsReducers";

export default combineReducers({
  dashboard: dashboardReducer,
  batchItems: batchReducer,
  rmpm: rmpmReducer,
  files: filesReducer,
  shortFalls:shortFallsReducer
  // purchaseOrder: purchaseOrderReducer,
  // manageUsers: manageUsersReducer,
});
