import React from 'react';
import TableWrapper from '../../wrappers/tableWrapper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CustomTableCell from '../../models/table/CustomTableCell';
import CustomTableHeaderCell from '../../models/table/CustomTableHeaderCell';
import { useStyles } from '../../sasl/scheduleAgreements/saslStyles';
import {
  presentFormatedData,
  presentDate,
} from '../../functionalComponents/dataFormatFunctions';
// for header data
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const TableData = (props) => {
  const classes = useStyles();
  const { header, data, reportType, qaApprovalFlag } = props;

  const handleData = (row, i) => {
    switch (reportType) {
      case 'vendorBatchNumber':
        return (
          <TableRow key={i} className={classes.root}>
            <CustomTableCell>
              {presentFormatedData(row.lastUpdatedDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventType)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventByUser)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.updateByOrganization)}
            </CustomTableCell>
            <CustomTableCell>
              {presentFormatedData(row.batchStatus)}
            </CustomTableCell>
            {qaApprovalFlag && (
              <CustomTableCell>
                {presentFormatedData(row.qaStatusText)}
              </CustomTableCell>
            )}
            <CustomTableCell>
              {presentFormatedData(row.batchUOM)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.batchSize)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.producedQuantity)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.readyForDispatchQuantity)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.controlSampleQuantity)}
            </CustomTableCell>
            {/* <CustomTableCell align='center'>
              {presentDate(row.batchStartDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentDate(row.batchEndDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentDate(row.qcReleaseDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentDate(row.dispatchDate)}
            </CustomTableCell> */}
          </TableRow>
        );
      case 'sa':
        return (
          <TableRow key={i} className={classes.root}>
            <CustomTableCell align='center'>
              {presentFormatedData(row.lastUpdatedDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventByUser)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventType)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.scheduleAgreementStatusText)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.agreementDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.agreementType)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.validityStartDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.validityEndDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.currency)}
            </CustomTableCell>
          </TableRow>
        );
      case 'saLine':
        return (
          <TableRow key={i} className={classes.root}>
            <CustomTableCell align='center'>
              {presentFormatedData(row.plant)}
            </CustomTableCell>

            <CustomTableCell align='center'>
              {presentFormatedData(row.lastUpdatedDate)}
            </CustomTableCell>

            <CustomTableCell align='center'>
              {presentFormatedData(row.eventByUser)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventType)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.materialCode)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.materialName)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.materialGroup)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.uom)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.targetQuantity)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.openTargetQuantity)}
            </CustomTableCell>
          </TableRow>
        );

      case 'saDeliverySchedule':
        return (
          <TableRow key={i} className={classes.root}>
            <CustomTableCell align='center'>
              {presentFormatedData(row.lastUpdatedDate)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventByUser)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.eventType)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.status)}
            </CustomTableCell>
            <CustomTableCell align='center'>
              {presentFormatedData(row.deliveryDate)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.uom)}
            </CustomTableCell>
            <CustomTableCell align='right'>
              {presentFormatedData(row.scheduledQuantity)}
            </CustomTableCell>

            <CustomTableCell align='right'>
              {presentFormatedData(row.openQuantity)}
            </CustomTableCell>
          </TableRow>
        );

      default:
        return [];
    }
  };

  const dataHeader = (item) => {
    switch (reportType) {
      case 'vendorBatchNumber':
        return (
          <Grid item xs={12} style={{ padding: '1rem' }}>
            <Toolbar>
              <Grid container alignItems='baseline' spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6'> Batch Audit Report</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        Batch No :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2'>
                        {item.vendorBatchNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        SA No :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2'>
                        {item.scheduleAgreementNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        SL Item No :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2'>
                        {item.scheduleLineItemNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        DS Month :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2'>
                        {item.deliveryScheduleMonth}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        Material Code &amp; Description :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>
                        {item.fgCodeAndDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        Vendor Name :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>{item.vendorName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        MFG Start Date :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>
                        {presentDate(item.batchStartDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        MFG Finish Date :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>
                        {presentDate(item.batchEndDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        QC Release Date :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>
                        {presentDate(item.qcReleaseDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        );
      case 'sa':
        return (
          <Grid item xs={12}>
            <Toolbar>
              <Grid container alignItems='baseline' spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6'>
                    {' '}
                    Schedule Agreement Report
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    SA No :&nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    {item.scheduleAgreementNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Grid
                    container
                    spacing={1}
                    direction='row'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='body2' className={classes.heavyFont}>
                        Vendor Name :&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Typography variant='body2'>{item.vendorName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    Vendor Number :&nbsp;
                  </Typography>
                  <Typography variant='body2'>{item.vendorNumber}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        );
      case 'saLine':
        return (
          <Grid item xs={12}>
            <Toolbar>
              <Grid container alignItems='baseline' spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6'> SA Line Audit Report</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    SA No :&nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    {item.scheduleAgreementNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    Item No :&nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    {item.scheduleLineItemNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        );
      case 'saDeliverySchedule':
        return (
          <Grid item xs={12}>
            <Toolbar>
              <Grid container alignItems='baseline' spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6'>
                    {' '}
                    Delivery Schedule Audit Report
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    Delivery Month :&nbsp;
                  </Typography>
                  <Typography variant='body2'>{item.deliveryMonth}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    SA No :&nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    {item.scheduleAgreementNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    SL Item No :&nbsp;
                  </Typography>
                  <Typography variant='body2'>
                    {item.scheduleLineItemNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    Material Code :&nbsp;
                  </Typography>
                  <Typography variant='body2'>{item.materialCode}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.grdAlgnrow}>
                  <Typography variant='body2' className={classes.heavyFont}>
                    Material Description :&nbsp;
                  </Typography>
                  <Typography variant='body2'>{item.materialName}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        );

      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <Grid container className={classes.saslAuditTable}>
        {dataHeader(data[0])}
        <Divider style={{ width: '100%' }} />
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {header.map((headCell) => (
                    <CustomTableHeaderCell
                      isSortEnable={false}
                      cellData={headCell}
                      key={headCell.id}
                      elementStyle={{
                        verticalAlign: 'top',
                        width: headCell.width ? headCell.width : 'auto',
                      }}
                    />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <React.Fragment>
                  {data.length > 0 ? (
                    data.map((row, i) => handleData(row, i))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={header.length} align='center'>
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </Grid>
    </React.Fragment>
  );
};

export default TableData;
