import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  displayText: {
    display: 'inline',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
export default function BottomLink(props) {
  const classes = useStyles();
  return (
    <Grid container justify='center'>
      <Grid item xs={11} sm={12} md={10} style={{ textAlign: 'center' }}>
        <Typography className={classes.displayText}>
          {props.typo}&#160;
        </Typography>
        <Typography
          component={Link}
          to={props.to}
          style={{ color: '#EC6535', textDecoration: 'underline' }}
        >
          {props.link}
        </Typography>
      </Grid>
    </Grid>
  );
}
