import {
  VERIFY_INVITE_USER,
  SEND_EMAIL_INVITE,
  SEND_OTP,
  VERIFY_OTP,
  FETCH_AUTH_DETAILS,
} from '../actions/types';

const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case VERIFY_INVITE_USER:
      return {
        ...state,
        userInvite: action.payload,
      };
    case SEND_EMAIL_INVITE:
      return {
        ...state,
        emailInvite: action.payload,
      };
    case SEND_OTP:
      return {
        ...state,
        otpSent: action.payload,
      };
    case VERIFY_OTP:
      return {
        ...state,
        otpVerified: action.payload,
      };
    case FETCH_AUTH_DETAILS:
      return {
        ...state,
        twoStepAuthDetails: action.payload,
      };
    default:
      return state;
  }
}
