import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: (props) =>
      props.backgroundColor ? props.backgroundColor : "white",
  },
  color: {
    color: (props) => props.color,
  },
}));

export const StyledAppBar = ({ children, ...props }) => {
  const { background, color } = useStyles(props);
  return (
    <AppBar className={`${background} ${color} ${props.className}`}>
      {children}
    </AppBar>
  );
};
