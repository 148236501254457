import React from 'react';
import Grid from '@material-ui/core/Grid';
import OpenPages from '../common/openPages';
import LoginAttemptsExhaustedCard from './LoginAttemptsExhaustedCard';
import { Switch, Route } from 'react-router-dom';

const LoginAttemptsExhausted = ({ children, ...props }) => {
  return (
    <Grid container>
      <OpenPages>
        <Switch>
          <Route exact component={LoginAttemptsExhaustedCard} />
        </Switch>
      </OpenPages>
    </Grid>
  );
};

export default LoginAttemptsExhausted;
