import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import StyledButton from '../common/models/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { isValid, isValidEmail } from '../common/helperFunctions/helper';

import {
  userIssueType,
  userContactAdmin,
} from '../../containers/actions/userActions';
// Captcha v3 library
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import { smlUrl } from '../../utils/config';
import BottomLink from '../common/BottomLink';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: '#00000000 0% 0% no-repeat padding-box',
    border: '0.125rem solid #374761',
  },
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.8rem',
    fontSize: '2.5rem',
  },
  root: {
    minWidth: 275,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flexGrow: 1,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
    },
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
}));
const ContactAdminCard = (props) => {
  const classes = useStyles();
  const { isMailSent } = props;
  const [email, setEmail] = useState('');
  const [ecosystemName, setEcosystemName] = useState('');
  const [issueDetail, setIssueDetail] = useState('');
  const [issueType, setIssueType] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [errors, setErrors] = useState({});
  const [resError, setResError] = useState({});
  const [issueId, setIssueId] = useState('');
  const dispatch = useDispatch();

  // Use below function for captcha execute
  const { executeRecaptcha } = useGoogleReCaptcha();

  let getIssueType = useSelector((state) => state.user.issueType);
  let contactAdmin = useSelector((state) => state.user.contactAdmin);

  useEffect(() => {
    if (contactAdmin !== undefined && contactAdmin.status === false) {
      if (contactAdmin.errorDescription !== undefined) {
        setResError(contactAdmin.errorDescription);
        setDisableButton(false);
      }
    }

    if (contactAdmin !== undefined && contactAdmin.status === true) {
      isMailSent(true);
      setDisableButton(false);
    }

    if (getIssueType === undefined) {
      dispatch(userIssueType());
    }
  }, [dispatch, contactAdmin, getIssueType, isMailSent]);

  const handleMenu = (e) => {
    setIssueId(e.target.getAttribute('data-newvalue'));
  };

  const selectIssueType = (issues) => {
    if (issues !== undefined) {
      return Object.keys(issues.data.issueObject).map((issue) => (
        <MenuItem
          key={issue}
          value={issues.data.issueObject[issue]}
          data-newvalue={issue}
          onClick={handleMenu}
        >
          {issues.data.issueObject[issue]}
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem key={5} value='other'>
          Other
        </MenuItem>
      );
    }
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrors({});
    setResError({});
  };
  const handleDropdown = (e) => {
    setIssueType(e.target.value);
    setResError({});
  };
  const handleIssueDetail = (e) => {
    setIssueDetail(e.target.value);
    setErrors({});
    setResError({});
  };

  const validate = () => {
    let error = false;
    // const { email, password } = this.state;

    let errors = {};
    if (!isValid(email)) {
      error = true;
      errors.email = 'Please enter email';
    } else if (!isValidEmail(email)) {
      error = true;
      errors.email = 'Please enter valid email address';
    } else if (!isValid(ecosystemName)) {
      error = true;
      errors.ecosystemName = 'Please provide your Ecosystem Name';
    } else if (!isValid(issueType)) {
      error = true;
      errors.issueType = 'Please select an Issue';
    }
    setErrors(errors);
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = validate();
    if (!isError) {
      setDisableButton(true);

      const token = await executeRecaptcha('contact_admin_form');

      axios
        .post(`${smlUrl}/user/validateFormCaptcha`, {
          token,
        })
        .then((res) => {
          if (res.data.score > 0.6) {
            const data = {
              email,
              ecosystemUrl: ecosystemName,
              issueType,
              issueDescription: issueDetail,
              isCaptchaCheck: true,
              issueId,
            };
            dispatch(userContactAdmin(data, props.history));
          } else {
            window.alert('Sorry! You might be a bot.');
          }
        });
    }
  };
  return (
    <Grid container alignItems='center' justify='space-around'>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={11} sm={10} md={6} lg={5}>
          <Card className={classes.root}>
            <CardContent>
              <Grid item xs={12} md={12} className={classes.header}>
                <Typography variant='h4' className={classes.text}>
                  Send Request
                </Typography>
                <Typography
                  variant='body2'
                  style={{ fontSize: '1.2rem', padding: '0.75rem' }}
                >
                  Send request to the ecosystem administrator for support.
                </Typography>
              </Grid>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid container justify='flex-start'>
                  <Grid item xs={12} sm={10} md={12}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      autoFocus
                      fullWidth
                      size='small'
                      id='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      // type='email'
                      helperText={
                        errors.email
                          ? errors.email
                          : resError !== undefined && resError.email
                          ? resError.email
                          : ''
                      }
                      onChange={handleChange}
                      value={email}
                      error={
                        errors.email ? true : resError.email ? true : false
                      }
                      style={{ marginBottom: '1rem' }}
                    />
                  </Grid>
                </Grid>
                <Grid container justify='flex-start'>
                  <Grid item xs={6} sm={5} md={5}>
                    <Typography style={{ fontWeight: 500 }}>
                      Ecosystem URL
                    </Typography>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      size='small'
                      value={ecosystemName}
                      name='ecosystemName'
                      label='Ecosystem Name'
                      helperText={
                        errors.ecosystemName
                          ? errors.ecosystemName
                          : resError !== undefined && resError.ecosystemUrl
                          ? resError.ecosystemUrl
                          : ''
                      }
                      error={
                        errors.ecosystemName
                          ? true
                          : resError.ecosystemUrl
                          ? true
                          : false
                      }
                      id='ecosystemName'
                      style={{ marginBottom: '1.5rem' }}
                      autoComplete='password'
                      onChange={(e) => {
                        setEcosystemName(e.target.value);
                        setResError({});
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={5}
                    md={5}
                    style={{ paddingTop: '3.1rem', paddingLeft: '0.5rem' }}
                  >
                    <Typography style={{ fontWeight: 500, fontSize: '1.5rem' }}>
                      .schrocken.io
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify='flex-start'>
                  <Grid item xs={12} sm={10} md={12}>
                    <br />
                    <Typography style={{ fontWeight: 500 }}>
                      When or what kind of issue are you facing with the page?
                    </Typography>

                    <TextField
                      fullWidth
                      select
                      variant='outlined'
                      size='small'
                      margin='normal'
                      name='issueType'
                      label='Issue Type'
                      error={
                        errors.issueType
                          ? true
                          : resError.issueType
                          ? true
                          : false
                      }
                      onChange={handleDropdown}
                      id='issueType'
                      htmlFor='issueType'
                      helperText={
                        errors.issueType
                          ? errors.issueType
                          : resError.issueType
                          ? resError.issueType
                          : ''
                      }
                      value={issueType}
                    >
                      {selectIssueType(getIssueType)}
                    </TextField>
                    <br />
                    <br />
                    <Typography style={{ fontWeight: 500 }}>
                      Describe the issue you are facing in detail{' '}
                    </Typography>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      size='small'
                      value={issueDetail}
                      name='issueDetail'
                      id='issueDetail'
                      multiline={true}
                      rows={5}
                      onChange={handleIssueDetail}
                    />
                    <Typography
                      style={{
                        color: '#00000',
                        textAlign: 'left',
                        fontSize: '0.9rem',
                      }}
                    >
                      Give as much detail as you can for administrator to help
                      you better.
                    </Typography>
                    <br />
                  </Grid>
                </Grid>
                <br />

                {/*Please compare the changes*/}
                <Grid container justify='center'>
                  <Grid
                    item
                    xs={8}
                    sm={8}
                    md={4}
                    style={{
                      marginBottom: '1rem',
                      textAlign: 'center',
                    }}
                  >
                    <StyledButton
                      type='submit'
                      id='signin'
                      variant='contained'
                      color='primary'
                      fullWidth
                      disabled={disableButton}
                    >
                      Send Request
                    </StyledButton>
                  </Grid>
                  {/* <StyledCheckbox /> */}
                </Grid>
              </form>
            </CardContent>
          </Card>
          <br />
          <br />
          <br />
          <BottomLink
            typo='Want to try to signing in again?'
            link='Sign In using your login details'
            to='/login'
          />
          <br />
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(ContactAdminCard);
