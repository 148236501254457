import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';
import { withRouter, Link } from 'react-router-dom';
import Gear from '../../../resources/images/Gear.svg';
// import { userLogout } from '../../../containers/actions/userActions';
// import { connect } from 'react-redux';

const drawerWidth = 240;

const Styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    // width: drawerWidth,
    // flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#454545',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  list: {
    marginLeft: '1rem',
  },
  listButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItem: {
    backgroundColor: '#ffffff',
    marginTop: '1rem',
    borderTopLeftRadius: '2rem',
    borderBottomLeftRadius: '2rem',
    color: '#000000',
  },
  inActive: {
    backgroundColor: '#454545',
    color: '#ffffff',
    marginTop: '1rem',
  },
  removeBorder: {
    borderRight: 0,
  },
});

class SideNavBar extends Component {
  state = {
    path: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathName !== prevState.path) {
      return { path: nextProps.location.pathname.split('/') };
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const pathName = this.state.path[3];
    const { path } = this.state;
    const { window, drawerOpen, handleDrawerToggle } = this.props;
    const container =
      window !== undefined ? () => window().document.body : undefined;

      // const handleLogout = () => {
      //   this.props.userLogout(this.props.history);
      // };

    const drawer = (
      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          <ListItem>
            <ListItemIcon className={classes.iconStyle}>
              <img src={Gear} alt='Gear icon' style={{ width: '2rem' }} />
            </ListItemIcon>
            <Typography variant='body1' style={{ color: '#A5A5A5' }}>
              Admin Tools
            </Typography>
          </ListItem>
          <ListItem
            component={Link}
            to={`/${path[1]}/${path[2]}/ecosystemsettings`}
            className={
              pathName === 'ecosystemsettings'
                ? classes.listItem
                : classes.inActive
            }
          >
            <Typography variant='body2'>Mosymphony Settings</Typography>
          </ListItem>
          <ListItem
            component={Link}
            to={`/${path[1]}/${path[2]}/privacysettings`}
            className={
              pathName === 'privacysettings'
                ? classes.listItem
                : classes.inActive
            }
          >
            <Typography variant='body2'>Privacy Settings </Typography>
          </ListItem>
          <ListItem
            component={Link}
            to={`/${path[1]}/${path[2]}/ecosystems`}
            className={
              pathName === 'ecosystems' ? classes.listItem : classes.inActive
            }
          >
            <Typography variant='body2'>Ecosystems </Typography>
          </ListItem>
          <ListItem
            component={Link}
            to={`/${path[1]}/${path[2]}/userpermissions`}
            className={
              pathName === 'userpermissions'
                ? classes.listItem
                : classes.inActive
            }
          >
            <Typography variant='body2'>User Permissions </Typography>
          </ListItem>
          {/* <Hidden mdUp implementation='css'>
          <ListItem
            className={
              pathName === 'userpermissions'
                ? classes.listItem
                : classes.inActive
            }
          >
            <Typography variant='body2'
            onClick={handleLogout}
            >Logout </Typography>
          </ListItem>
          </Hidden> */}
        </List>
      </div>
    );
    return (
      <React.Fragment>
        <Hidden mdUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper,
              paperAnchorDockedLeft: classes.removeBorder,
            }}
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  }
}

// const mapStateToProps = (state) => ({});
export default withStyles(Styles)(withRouter(SideNavBar));
