import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  alignment: {
    padding: '1rem',
  },
}));
const GridMdWrapper = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.alignment}
      justify='center'
      style={props.style}
    >
      <Grid item xs={10} sm={10} md={6} lg={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default GridMdWrapper;
