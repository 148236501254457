import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ToolTip from '../common/models/buttons/ToolTip';
//svg icons
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as PoIcon } from './icons/po.svg';
import { ReactComponent as LineIcon } from './icons/line.svg';
import { ReactComponent as BatchIcon } from './icons/batch.svg';
import { ReactComponent as ScheduleIcon } from './icons/schedule.svg';
import { ReactComponent as GrnIcon } from './icons/grn.svg';
import { ReactComponent as AuditIcon } from './icons/audit.svg';
import { ReactComponent as UsersIcon } from './icons/users.svg';
import { ReactComponent as CompanyIcon } from './icons/company.svg';
// import { navList } from './Navgiation';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
// import schrockenMiniLogo from '../../resources/images/logoMini.png';
import schrockenLogo from '../../../../resources/images/Schrocken-Logo-Dark.png';

const useStyles = makeStyles((theme) => ({
  listDiv: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '91%',
  },
  toolTip: {
    fontSize: '0.875rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  activePath: {
    background:
      'linear-gradient(82deg, #3B173F 0%, #073270 100%) 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    color: '#ffffff',
    // [theme.breakpoints.down('md')]: {
    //   paddingBottom: '0.25rem',
    //   paddingTop: '0.25rem',
    // },
  },
  inactivePath: {
    // [theme.breakpoints.down('md')]: {
    //   paddingBottom: '0.25rem',
    //   paddingTop: '0.25rem',
    // },
  },
  listButton: {
    marginTop: 2,
    '&:hover': {
      borderRadius: 12,
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
  },
  listItemIcon: {
    minWidth: 50,
  },
  dspNone: {
    display: 'none',
  },
  listItemPadding: {
    // [theme.breakpoints.down('md')]: {
    //   paddingBottom: '0.25rem',
    //   paddingTop: '0.25rem',
    // },
  },
  manage: {
    fontSize: '0.875rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
}));

const SideBar = (props) => {
  const classes = useStyles();
  const { sideBarOpen } = props;

  let screensAccess = useSelector((state) => {
    return state.pcmp.common.accessControl.screensAccess.data;
  });

  return (
    <div className={classes.listDiv}>
      <div>
        <List>
          {screensAccess !== undefined &&
            screensAccess.dashboard.screenAccess === true && (
              <NavigationItem
                routeName={'Dashboard'}
                route='dashboard'
                NavIcon={DashboardIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}
          {screensAccess !== undefined &&
            screensAccess.execDashboard.screenAccess === true && (
              <NavigationItem
                routeName={'Executive Dashboard'}
                route='executivedashboard'
                NavIcon={AssessmentOutlinedIcon}
                {...props}
              />
            )}
          {screensAccess !== undefined &&
            screensAccess.purchaseOrders.screenAccess === true && (
              <NavigationItem
                routeName={'Purchase Orders'}
                route='purchaseorders'
                NavIcon={PoIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}
          {screensAccess !== undefined &&
            screensAccess.lineItems.screenAccess === true && (
              <NavigationItem
                routeName={'Line Items'}
                route='lineitems'
                NavIcon={LineIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}

          {screensAccess !== undefined &&
            screensAccess.grnReceipts.screenAccess === true && (
              <NavigationItem
                routeName={'GRN Receipts'}
                route='GRNReceipts'
                NavIcon={GrnIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}

          <NavigationItem
            routeName={'Purchase Orders/SAs'}
            route='schedule agreements'
            NavIcon={ScheduleIcon}
            handleDrawerClose={props.handleDrawerClose}
          />

          {screensAccess !== undefined &&
            screensAccess.fgBatch.screenAccess === true && (
              <NavigationItem
                routeName={'Batch Dashboard'}
                route='fgBatches'
                NavIcon={BatchIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}

          {screensAccess !== undefined &&
            screensAccess.shortFalls.screenAccess === true && (
              <NavigationItem
                routeName={'Shortfall Reports'}
                route='shortFalls'
                NavIcon={DescriptionOutlinedIcon}
                {...props}
              />
            )}
          {screensAccess !== undefined &&
            screensAccess.reports.screenAccess === true && (
              <NavigationItem
                routeName={'Reports'}
                route='reports'
                NavIcon={AuditIcon}
                handleDrawerClose={props.handleDrawerClose}
              />
            )}

          <NavigationItem
            routeName={'SASL Reports'}
            route='sa-audit-reports'
            NavIcon={AuditIcon}
            handleDrawerClose={props.handleDrawerClose}
          />
        </List>
        <Typography
          variant='body1'
          style={{ paddingTop: '0.2rem', fontWeight: 500 }}
          className={classes.manage}
        >
          Manage
        </Typography>
        <List>
          <NavigationItem
            routeName={'Ecosystem Users'}
            route='managemembers'
            NavIcon={UsersIcon}
          />

          <NavigationItem
            routeName={'Organization Profile'}
            route='orgprofile'
            NavIcon={CompanyIcon}
          />
        </List>
      </div>

      <div>
        {sideBarOpen === true && (
          <div>
            <img
              src={schrockenLogo}
              // src={sideBarOpen?schrockenLogo:schrockenMiniLogo}
              alt='logo_mini'
              style={{
                maxWidth: sideBarOpen ? '65%' : '100%',
                height: 'auto',
              }}
            />
          </div>
        )}
        <div style={{ padding: sideBarOpen ? 8 : 0 }}>
          <Typography
            variant='caption'
            className={classes.type}
            style={{
              fontSize: '0.63rem',
              display: sideBarOpen ? 'none' : 'initial',
            }}
          >
            &#169; 2020 <br className={sideBarOpen ? classes.dspNone : null} />
            Schrocken <br className={sideBarOpen ? classes.dspNone : null} />
            Inc. All rights{' '}
            <br className={sideBarOpen ? classes.dspNone : null} />
            reserved.
          </Typography>
        </div>
      </div>
    </div>
  );
};

const NavigationItem = (props) => {
  const { routeName, route, NavIcon, handleDrawerClose } = props;
  const location = useLocation();
  const matches = useMediaQuery('(min-height: 650px)');
  const classes = useStyles();
  let path = location.pathname.split('/');
  let currentPath = path[6];
  return (
    <ToolTip title={routeName} placement='right-start' arrow>
      <ListItem
        button
        className={
          currentPath === route ? classes.activePath : classes.inactivePath
        }
        classes={{ button: classes.listButton }}
        component={Link}
        to={`/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/${route}`}
        onClick={handleDrawerClose}
        // className={classes.listItemPadding}
        style={{
          paddingBottom: matches ? '8px' : '0.25rem',
          paddingTop: matches ? '8px' : '0.25rem',
        }}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <NavIcon
            fill={currentPath === route ? '#ffffff' : '#313645'}
            style={{ color: currentPath === route ? '#ffffff' : '#313645' }}
          />
        </ListItemIcon>
        <ListItemText
          primary={routeName}
          classes={{ primary: classes.toolTip }}
        />
      </ListItem>
    </ToolTip>
  );
};

export default SideBar;
