import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
//components
import SettingsInputTextField from './SettingsInputTextField';

const useStyles = makeStyles({
  gridTop: {
    marginTop: '1rem',
  },
  headerText: {
    fontSize: '1rem',
  },
  gridBottom: {
    padding: '0.75rem 0',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
  textFieldMinWidth: {
    minWidth: '14rem',
  },
});

const EmailSettings = (props) => {
  const classes = useStyles();
  const {
    inviteEmailExpirationTime,
    passwordResestEmailExpirationTime,
    handleChange,
    errors,
  } = props;
  return (
    <Grid item xs={12} className={classes.gridTop}>
      <Typography
        variant='h6'
        className={`${classes.headerText} ${classes.gridBottom}`}
      >
        Email Settings
      </Typography>

      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>
            Invitation Email Expiration Time
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='inviteEmailExpirationTime'
            id='Settings_inviteEmailExpirationTime'
            obj='email'
            value={inviteEmailExpirationTime}
            minValue={1}
            maxValue={48}
            handleChange={handleChange}
            label='Invitation Email Expiration'
            description='Enter Invite Email Expiration Time in Hours'
            error={errors.inviteEmailExpirationTime}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>
            Password Reset Email Expiration Time
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='passwordResestEmailExpirationTime'
            id='Settings_passwordResestEmailExpirationTime'
            obj='email'
            value={passwordResestEmailExpirationTime}
            minValue={1}
            maxValue={48}
            handleChange={handleChange}
            label='Reset Email Expiration'
            description='Enter Reset Email Expiration Time in Hours'
            error={errors.passwordResestEmailExpirationTime}
          />
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default EmailSettings;
