import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  cards: { boxShadow: 'none' },
}));

export default function ButtonAppBar({ children, ...props }) {
  const classes = useStyles(props);
  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={10} sm={9} md={6} lg={6}>
        <Card className={classes.cards}>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
