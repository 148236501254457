export const filterItems = (itemsArray, fieldTobeFiltered, fieldValue) => {
  let arrSearch = [];
  arrSearch = itemsArray.filter(
    (filteringValue) => filteringValue.SASLBatchStatusID === fieldValue
  );
  return arrSearch;
};

export const searchItems = (itemsArray, searchFields, searchQuery) => {
  let arrSearch = [];
  arrSearch = itemsArray.filter((filteringValue) =>
    filteringValue.vendorBatchNumber
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  return arrSearch;
};

export const filterByQAStatus = (itemsArray, fieldTobeFiltered, fieldValue) => {
  let arrSearch = [];
  arrSearch = itemsArray.filter(
    (filteringValue) => String(filteringValue.qaStatusID) === String(fieldValue)
  );
  return arrSearch;
};
