import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import StyledButton from '../../common/models/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useStyles } from './grnStyles';
import UploadRecord from '../uploadRecords';
import Dialog from '@material-ui/core/Dialog';
import ExportToExcel from './ExportToExcel';
import Button from '@material-ui/core/Button';

function UploadExport(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { collabType } = localStorage;
  const [openUploadRecord, setOpenUploadRecord] = React.useState(false);
  const [openUploadDSBatch, setOpenUploadDSBatch] = React.useState(false);
  const [openConsumptionInfo, setOpenConsumptionInfo] = React.useState(false);

  const isClosed = (data) => {
    setOpenUploadRecord(data);
    // setOpenUploadDSBatch(data);
    // setOpenConsumptionInfo(data);
  };

  const openCloseBatchUpload = (trigger) => {
    setOpenUploadDSBatch(trigger);
  };
  const openCloseConsumptionUpload = (trigger) => {
    setOpenConsumptionInfo(trigger);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let grnFeatures = useSelector((state) => {
    return state.pcmp.common.accessControl.featuresAccess.grnFeatures;
  });
  return (
    <Grid item xs={12} md={props.isUploadRMPMGRN ? 6 : 8}>
      <Grid container className={classes.flxEnd}>
        {localStorage.collabType === 'CMO' &&
          props.isUploadRMPMGRN === true &&
          grnFeatures.updateFileRecords === true && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenUploadRecord(true);
              }}
              className={classes.upload}
            >
              Upload Record(s)
            </Button>
          )}

        {localStorage.collabType === 'CMO' && props.isUploadDSBatch === true && (
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenUploadDSBatch(true);
            }}
            className={classes.upload}
          >
            Upload Batch Details
          </Button>
        )}

        {localStorage.collabType === 'CMO' &&
          props.isUploadConsumptionInfo === true && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenConsumptionInfo(true);
              }}
              className={classes.upload}
            >
              Upload Consumption Details
            </Button>
          )}
        <UploadRecord
          open={openUploadRecord}
          close={isClosed}
          isUploadRMPMGRN={props.isUploadRMPMGRN}
          refreshFunc={props.refreshFunc}
        />
        <UploadRecord
          open={openUploadDSBatch}
          close={openCloseBatchUpload}
          isUploadDSBatch={props.isUploadDSBatch}
          refreshFunc={props.refreshFunc}
        />
        <UploadRecord
          open={openConsumptionInfo}
          close={openCloseConsumptionUpload}
          isUploadConsumptionInfo={props.isUploadConsumptionInfo}
          refreshFunc={props.refreshFunc}
        />

        {props.isUploadRMPMGRN === true && grnFeatures.exportToExcel === true && (
          <StyledButton
            endIcon={
              <ArrowForwardIosIcon
                style={{
                  fontSize: 12,
                }}
              />
            }
            onClick={handleOpen}
            className={classes.export}
          >
            Export to Excel
          </StyledButton>
        )}
      </Grid>

      <Dialog
        open={open}
        maxWidth={collabType !== 'CMO' ? 'md' : 'md'}
        // fullWidth={localStorage.collabType === 'CMO' ? true : false}
      >
        <ExportToExcel handleClose={handleClose} />
      </Dialog>
    </Grid>
  );
}

export default UploadExport;
