import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import StyledButton from './models/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    fontSize: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  textPadding: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
});

const InvitationSent = (props) => {
  const classes = useStyles();
  const {
    email,
    handleClose,
    sendAnotherInvite,
    multiUsers,
    usersList,
  } = props;
  return (
    <DialogContent style={{ padding: '2rem' }}>
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={handleClose}
        >
          Close <CloseIcon fontSize='large' />
        </Typography>
        <Grid item xs={12} className={classes.iconGrid}>
          <CheckCircleIcon className={classes.icon} />
        </Grid>
      </Grid>
      <Grid style={{ padding: '1rem 3rem 3rem 3rem' }}>
        <Typography variant='h5' className={classes.saved}>
          Invitation Sent
        </Typography>
        <Grid style={{ textAlign: 'center', marginBottom: '1rem' }}>
          <Typography
            variant='body2'
            className={`${classes.text} ${classes.textPadding}`}
          >
            Your Invitation has been sent to: &#160;
          </Typography>
          {multiUsers === true ? (
            usersList.map((user) => (
              <Typography
                variant='body1'
                style={{
                  fontWeight: 500,
                }}
                className={classes.textPadding}
              >
                {user.email}
              </Typography>
            ))
          ) : (
            <Typography
              variant='body1'
              style={{
                fontWeight: 500,
              }}
              className={classes.textPadding}
            >
              {email}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <StyledButton
            size='small'
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
            onClick={handleClose}
          >
            Save &#38; Continue
          </StyledButton>
        </Grid>
        <Grid style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography
            variant='subtitle2'
            style={{ display: 'inline', fontSize: '0.75rem' }}
          >
            Want to send another Invite?&#160;
          </Typography>

          <Typography
            variant='body2'
            color='primary'
            style={{
              display: 'inline',
              fontSize: '0.75rem',
              cursor: 'pointer',
            }}
            onClick={sendAnotherInvite}
          >
            Send another Invitation
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default InvitationSent;
