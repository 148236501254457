import { BATCH_FILTERS, DS_FILTERS, SASL_FILTERS } from '../../actions/types';

const intialState = {};

export default function (state = intialState, action) {
  switch (action.type) {
    case SASL_FILTERS:
      return {
        saslFilters:
          state?.saslFilters && action.payload
            ? { ...state.saslFilters, ...action.payload }
            : action.payload,
      };
    case DS_FILTERS:
      return {
        ...state,
        dsFilters:
          state?.dsFilters && action.payload
            ? { ...state?.dsFilters, ...action.payload }
            : action.payload,
      };
    case BATCH_FILTERS:
      return {
        batchFilters:
          state?.batchFilters && action.payload
            ? { ...state.batchFilters, ...action.payload }
            : action.payload,
      };
    default:
      return state;
  }
}
