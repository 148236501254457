import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorImage from '../../resources/images/500Error.svg';
import BottomLink from '../common/BottomLink';
import MainCards from '../common/MainCards';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.75rem',
  },
  root: {
    minWidth: 275,
  },
  btns: {
    textAlign: 'center',
  },
  mediaGrid: {
    textAlign: 'center',
  },
  media: {
    maxWidth: '20rem',
    maxHeight: '13rem',
  },
  cardContent: {
    padding: '2rem',
  },
});
const ErrorPage500Card = (props) => {
  const classes = useStyles();
  return (
    <MainCards>
      <Grid item xs={12} className={classes.mediaGrid}>
        <img src={ErrorImage} alt='Error500Image' className={classes.media} />
      </Grid>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h4' className={classes.text}>
          Oops! Something went wrong
        </Typography>
        <Typography style={{ padding: '0.75rem' }}>
          We were not able to verify your details. You can try login again using
          your details or contact your ecosystem administrator for further
          assistance using the links below
        </Typography>
      </Grid>
      <br />
      <BottomLink
        typo='Want to try again?'
        link='Sign in again to restart process'
        to='/login'
      />
      <br />
      <BottomLink
        typo='Do you think you have issues signing in?'
        link='Contact the ecosystem administrator'
        to='/contactadmin'
      />
    </MainCards>
  );
};

export default ErrorPage500Card;
