import React from 'react';
// import StyledButton from './models/Button';
import StyleDialog from './models/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import DialogContent from '@material-ui/core/DialogContent';
// Icons
// import CloseIcon from '@material-ui/icons/Close';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: '600',
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  info: {
    fontWeight: 500,
  },
});
const OrganisationAdded = (props) => {
  const classes = useStyles();
  const { handleClose, organizationName, LLCode, TPCode } = props;
  return (
            <StyleDialog
              title="Organization Added"
              subtitle="Your new organization has been added.
                     You can edit details in your Settings Page."
              handleClose={handleClose}
            >

              <Grid container justify='center' spacing={3}>
                <Grid item xs={6} sm={6} md={10}>
                  <Typography variant='body2' className={classes.text}>
                    Organization Name : &#160;
            <span className={classes.info}>{organizationName}</span>
                  </Typography>
                  {LLCode === null || '' ? (
                    ''
                  ) : (
                      <Typography variant='body2' className={classes.text}>
                        Loan Licensing Code : &#160;
                        <span className={classes.info}>{LLCode}</span>
                      </Typography>
                    )}
                  {TPCode === null || '' ? (
                    ''
                  ) : (
                      <Typography variant='body2' className={classes.text}>
                        Third Party Code : &#160;
                        <span className={classes.info}>{TPCode}</span>
                      </Typography>
                    )}
                </Grid>
              </Grid>

              {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
        <StyledButton
          style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
          onClick={handleClose}
        >
          OK
        </StyledButton>
        </Grid> */}
            </StyleDialog>
  );
};

export default OrganisationAdded;
