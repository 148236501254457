import React from 'react';
import ResetPasswordCard from './ResetPasswordCard';
import OpenPageMediaHeader from '../../../common/openPages/openPageHeaderwithMedia';
import BottomLink from '../../../common/BottomLink';
import Grid from '@material-ui/core/Grid';
const index = () => {
  return (
    <OpenPageMediaHeader>
      <Grid
        container
        justify='center'
        style={{
          backgroundColor: '#f2f2f2',
        }}
      > 
        <ResetPasswordCard />
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <br />
          <br />
          <BottomLink
            typo='Remembered your details?'
            link='Sign In using your login details'
            to='/login'
          />
          <br />
          <br />
        </Grid>
      </Grid>
    </OpenPageMediaHeader>
  );
};

export default index;
