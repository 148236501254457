import { SERVER_ERROR } from "../actions/types";

// Server Error
export const serverError = () => (dispatch) => {
  dispatch({
    type: SERVER_ERROR,
    payload: {
      errorType: "Server Crash",
      errorCode: 500,
    },
  });
};
