import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { appUrl } from '../../../../../../../utils/config';
import DisplayCard from './DisplayCard';

const Block = (props) => {
  const { statusID, firstStatusName, secondStatusName } = props.data;
  const {
    status,
    CMOMember,
    productSearch,
    startDate,
    endDate,
    handleQuickStatus,
  } = props;

  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    let quickStatus = statusID.map(String);
    if (JSON.stringify(status) !== JSON.stringify(quickStatus)) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  }, [status, statusID]);

  const handleClick = () => {
    setSelected(true);
    handleQuickStatus(statusID);
  };

  useEffect(() => {
    let data = {
      statusID: statusID,
      vendorMosymphonyID: CMOMember,
      searchByProductCode: productSearch,
      BSDStartDate: startDate,
      BSDEndDate: endDate,
    };
    axios
      .post(
        `${appUrl}/${localStorage.appName}/${localStorage.network}/${localStorage.collabType}/${localStorage.roleName}/quickSummaryCount`,
        data
      )
      .then((res) => {
        setLoading(false);
        setCount(res.data.data.statusCount.count);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [statusID, CMOMember, productSearch, startDate, endDate]);

  return (
    <DisplayCard
      tertiaryText={secondStatusName}
      secondaryText={firstStatusName}
      primaryText={count}
      loading={loading}
      selected={selected}
      handleClick={handleClick}
    />
  );
};

export default Block;
