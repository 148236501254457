import React from 'react';

import StyledButton from './Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  closeText: {
    fontSize: '1rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  saved: {
    textAlign: 'center',
    padding: '0.5rem',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  text: {
    textAlign: 'center',
    fontSize: '1rem',
    margin: '1rem',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
    color: '#39811D',
  },
  cardContent: {
    padding: '2rem !important',
  },
  buttonGrid: {
    textAlign: 'center',
    padding: '0.5rem',
  },
  noButton: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #FFF',
    },
  },
});

const StyledDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <DialogContent
      classes={{
        root: classes.cardContent,
      }}
    >
      <Grid container justify='flex-end'>
        <Typography
          variant='body2'
          className={classes.closeText}
          onClick={
            props.handleNoButton ? props.handleNoButton : props.handleClose
          }
        >
          Close <CloseIcon fontSize='large' />
        </Typography>
        <Grid item xs={12} className={classes.iconGrid}>
          <CheckCircleIcon className={classes.icon} />
        </Grid>
      </Grid>
      <Typography variant='h5' className={classes.saved}>
        {/* Settings Saved */}
        {props.title}
      </Typography>

      <Typography variant='body2' className={classes.text}>
        {/* Your settings have been saved and will take effect soon */}
        {props.subtitle}
      </Typography>

      {children}

      {props.hasMultipleButton ? (
        <Grid
          item
          xs={12}
          className={classes.buttonGrid}
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Button
            variant='contained'
            disableElevation
            disableRipple
            disableFocusRipple
            className={classes.noButton}
            onClick={props.handleNoButton}
            disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
          >
            No
          </Button>
          <StyledButton
            style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
            onClick={props.handleClose}
            disabled={props.isButtonDisabled ? props.isButtonDisabled : false}
          >
            Yes
          </StyledButton>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.buttonGrid}>
          <StyledButton
            style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
            onClick={props.handleClose}
          >
            OK
          </StyledButton>
        </Grid>
      )}
    </DialogContent>
  );
};

export default StyledDialog;
