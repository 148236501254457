import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from '../../models/buttons/buttonStyles';
import UpdateScreen from '../batch/updateBatch';

const Update = (props) => {
  const classes = useStyles();
  const {
    schrockenDeliveryScheduleBatchID,
    schrockenDeliveryScheduleID,
    SASLBatchStatusID,
    statusDisplayName,
    batchDashboard,
    qaApproval,
    featuresAccess,
    qaStatusID,
    qaStatusText,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        className={classes.button}
        // onClick={handleClickRecieved}
      >
        <div className={classes.logo} onClick={handleClose}>
          <AutorenewIcon fontSize='small' className={classes.logoHeight} />
          &nbsp;
          <Typography variant='body1'>Approve</Typography>
        </div>
      </Button>

      <UpdateScreen
        open={open}
        handleClose={handleClose}
        schrockenDeliveryScheduleBatchID={schrockenDeliveryScheduleBatchID}
        schrockenDeliveryScheduleID={schrockenDeliveryScheduleID}
        SASLBatchStatusID={SASLBatchStatusID}
        statusDisplayName={statusDisplayName}
        batchDashboard={batchDashboard}
        qaStatusID={qaStatusID}
        qaStatusText={qaStatusText}
        qaApproval={qaApproval}
        featuresAccess={featuresAccess}
      />
    </React.Fragment>
  );
};

export default Update;
