import axios from 'axios';
import { smlUrl } from '../../utils/config';
import {
  VERIFY_INVITE_USER,
  SEND_EMAIL_INVITE,
  SEND_OTP,
  VERIFY_OTP,
  FETCH_AUTH_DETAILS,
} from './types';
// Import redirect logic
import { redirectUser } from './redirectUser';

// Verify UserInvitation
export const verifyUserInvite = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/verifyUserInvitation`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: VERIFY_INVITE_USER,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: VERIFY_INVITE_USER,
        payload: err.response.data,
      });
      console.log(err);
    });
};

export const sendEmailInvite = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/sendEmailInvitation`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: SEND_EMAIL_INVITE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({
          type: VERIFY_INVITE_USER,
          payload: {
            status: false,
            errorDescription: err.response.data.errorDescription[0]['errors'],
          },
        });
      }
    });
};

export const fetchTwoStepAuthDetails = (data, history) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/fetchTwoStepAuthenticationDetails`, data)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data.isTwoStepAuthenticationEnabled === false) {
          history.push(`/${localStorage.userType}/dashboard`);
        } else if (
          res.data.data.isTwoStepAuthenticationEnabled === true &&
          res.data.data.isTwoStepAuthenticationCompleted === true
        ) {
          history.push(`/${localStorage.userType}/dashboard`);
        } else {
          dispatch({
            type: FETCH_AUTH_DETAILS,
            payload: res.data,
          });
        }
      }
    })
    .catch((err) => {
      if (err.response.status === 400) {
        if (
          err.response.data.errorDescription.isOTPAttemptsExhausted === true
        ) {
          history.push('/authfailed');
          // clear tokens and other data from local storage
          localStorage.removeItem('authToken');

          // clear session data
          sessionStorage.clear();
        } else {
          dispatch({
            type: FETCH_AUTH_DETAILS,
            payload: err.response.data,
          });
        }
      }
    });
};

export const sendOtp = (data) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/sendOTP`, data)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: SEND_OTP,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SEND_OTP,
        payload: err.response.data,
      });
      console.log(err);
    });
};

export const verifyOtp = (data, history) => (dispatch) => {
  axios
    .post(`${smlUrl}/user/verifyOTP`, data)
    .then((res) => {
      redirectUser(res.data, history);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        if (
          err.response.data.errorDescription.isOTPAttemptsExhausted === true
        ) {
          history.push('/authfailed');
          // clear tokens and other data from local storage
          localStorage.removeItem('authToken');

          // clear session data
          sessionStorage.clear();
        } else {
          dispatch({
            type: VERIFY_OTP,
            payload: err.response.data,
          });
        }
      }
    });
};
