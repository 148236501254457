import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import StyledButton from '../common/models/Button';
import BottomLink from '../common/BottomLink';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    padding: '0.5rem',
  },
  root: {
    minWidth: 275,
  },
  btns: {
    textAlign: 'center',
  },
});
const LoginAttemptsExhaustedCard = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      style={{
        position: 'fixed',
        height: '100%',
        backgroundColor: '#F2F2F2',
      }}
    >
      <Grid item xs={7} sm={6} md={5}>
        <Card className={classes.root}>
          <CardContent>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h4' className={classes.text}>
                Wrong Email address and password combination
              </Typography>
              <Typography
                variant='body2'
                style={{ fontSize: '1.2rem', padding: '0.75rem' }}
              >
                Email address &#38; password combination does not match. We
                advice to reset your password for security reasons.
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12} className={classes.btns}>
              <StyledButton
                type='submit'
                variant='contained'
                color='primary'
                size='medium'
              >
                Reset Password
              </StyledButton>
            </Grid>
            <br />
          </CardContent>
        </Card>
      </Grid>
      <BottomLink
        typo='Remembered your details?'
        link='Sign In using your login details'
        to='/login'
      />
    </Grid>
  );
};

export default LoginAttemptsExhaustedCard;
