import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
// import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import { ecosystemType } from '../../../../containers/actions/ecosystemActions';
import { connect } from 'react-redux';

const useStyles = {
  text: {
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  helperTextFontSize: {
    fontSize: '0.65rem',
  },
};

class EcosystemInfo extends Component {
  state = {
    ecosystemType: [],
  };

  componentDidMount() {
    this.props.ecosystemType();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.ecoType !== this.props.ecoType &&
      this.props.ecoType.status === true
    ) {
      this.setState({
        ecosystemType: this.props.ecoType.data,
      });
    }
  }

  handleChangeEcosystem = (e) => {
    this.state.ecosystemType.forEach((item) => {
      if (item.ecosystemTypeName === e.target.value) {
        const data = {
          ecosystemTypeId: item.ecosystemTypeId,
          ecosystemTypeName: item.ecosystemTypeName,
        };
        this.props.handleChangeColloborator(data);
      }
    });
  };

  handleChangeEcosytemUrl = (e) => {
    const data = {
      ecosystemUrl: e.target.value,
    };
    this.props.handleChangeColloborator(data);
  };

  render() {
    const {
      classes,
      ecosystemTypeName,
      ecosystemTypeId,
      ecosystemUrl,
      errors,
    } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.text}>
            Ecosystem Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            select
            variant='outlined'
            size='small'
            name='ecosystemTypeName'
            onChange={this.handleChangeEcosystem}
            value={ecosystemTypeName}
            label='Ecosystem Type'
            error={errors.ecosystemTypeName ? true : false}
            helperText={
              errors.ecosystemTypeName ? (
                errors.ecosystemTypeName
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Select Ecosystem Type
                </Typography>
              )
            }
          >
          {
            this.state.ecosystemType.length === 0 
            ?
            <MenuItem disabled>
               Select
              </MenuItem>
           :
            this.state.ecosystemType.map((ecosystemType, index) => (
              <MenuItem value={ecosystemType.ecosystemTypeName} key={index}>
                {ecosystemType.ecosystemTypeName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            disabled
            variant='outlined'
            size='small'
            name='ecosystemTypeId'
            onChange={this.handleChangeEcosystem}
            value={ecosystemTypeId}
            label='Ecosystem Type ID'
            error={errors.ecosystemTypeId ? true : false}
            helperText={
              errors.ecosystemTypeId ? (
                errors.ecosystemTypeId
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Ecosystem Type
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            name='ecosystemURL'
            autoComplete='off'
            onChange={this.handleChangeEcosytemUrl}
            value={ecosystemUrl}
            label='Ecosystem URL'
            error={errors.ecosystemUrl ? true : false}
            helperText={
              errors.ecosystemUrl ? (
                errors.ecosystemUrl
              ) : (
                <Typography
                  variant='caption'
                  className={classes.helperTextFontSize}
                >
                  Enter Ecosystem URL
                </Typography>
              )
            }
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  ecoType: state.ecosystems.ecosystemType,
});

export default connect(mapStateToProps, { ecosystemType })(
  withStyles(useStyles)(EcosystemInfo)
);
