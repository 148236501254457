import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  //upload export
  export: {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },
  upload: {
    background: 'transparent linear-gradient(251deg, #173072 0%, #301232 100%) 0% 0% no-repeat padding-box;',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    marginRight: 20,
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: 'green',
      background: 'green',
      color: 'white',
    },
  },
  flxEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  //cmo filter
  formControl: {
    minWidth: 120,
  },
  outlined: {
    padding: theme.spacing(1),
  },
  //filter
  hgText: {
    fontWeight: 500,
  },
  //status filter
  //index
  pdTp: { paddingTop: '1.5rem' },
  mgTp: { marginTop: '1.5rem' },
  dpFlx: { display: 'flex' },
  algnCntr: { alignItems: 'center' },
  pointer: { cursor: 'pointer' },
  onHvrUndrline: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  pdL10: {
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  padR20: {
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  pdTponLr: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1rem',
    },
  },
  flxEndSmFlxStrt: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingTop: '1rem',
    },
  },
  dspNone: {
    display: 'none',
  },
  //Search button
  search: {
    padding: '2px 16px',
    backgroundColor: '#e36e39',
    color: 'white',
    textTransform: 'none',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid black',
    },
  },

  //export to excel filter
  closeText: {
    fontSize: "1rem",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));
