import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tables from './Tables';
import { getEcosystems } from '../../../../containers/actions/ecosystemActions';

const styles = {
  root: {
    flexGrow: 1,
  },
  notExist: {
    textAlign: 'center',
    padding: '1rem',
    border: '0.15rem dotted #707070',
    marginTop: '5rem',
  },
};

class EcosystemsInfo extends Component {
  state = {
    loading: true,
    ecosystemDataArray: [],
    currentPage: 1,
    noOfPages: 1,
    //use this to calculate pagination and array slicing
    noOfrecordsPerPage: 4,
    showPagination: false,
  };

  componentDidMount() {
    this.props.getEcosystems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.ecosystems !== prevProps.ecosystems &&
      this.props.ecosystems.status === true
    ) {
      // Create pages based on no of records
      const { noOfrecordsPerPage } = this.state;
      let noOfPages = Math.ceil(
        this.props.ecosystems.data.length / noOfrecordsPerPage
      );
      this.setState({
        ecosystemDataArray: this.props.ecosystems.data,
        showPagination:
          this.props.ecosystems.data.length > noOfrecordsPerPage ? true : false,
        loading: false,
        noOfPages,
      });
    }
  }
  handlePageChange = (event, page) => {
    this.setState({
      currentPage: page,
    });
  };
  render() {
    const { classes } = this.props;
    const {
      loading,
      ecosystemDataArray,
      currentPage,
      noOfPages,
      showPagination,
      noOfrecordsPerPage,
    } = this.state;
    // Show page records
    const records = currentPage * noOfrecordsPerPage;
    const Ecosystems = ecosystemDataArray.slice(
      records - noOfrecordsPerPage,
      records
    );
    return (
      <React.Fragment>
        {loading === true ? (
          <div style={{ textAlign: 'center', marginTop: '25%' }}>
            <CircularProgress color='primary' thickness={7} />
          </div>
        ) : (
          <div className={classes.root}>
            {Ecosystems.map((ele, i) => (
              <Tables ecosystemsData={ele} key={i} />
            ))}
            {ecosystemDataArray.length === 0 && (
              <Grid container className={classes.notExist} justify='center'>
                <Typography variant='body1'>
                  No ecosystem has been created yet. Please invite users to
                  create new ecosystem.
                </Typography>
              </Grid>
            )}
            {showPagination === true && (
              <Grid container justify='center' style={{ marginTop: '1rem' }}>
                <Pagination
                  page={currentPage}
                  name='currentPage'
                  onChange={(event, page) => this.handlePageChange(event, page)}
                  count={noOfPages}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

EcosystemsInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ecosystems: state.ecosystems.ecosystems,
});

export default connect(mapStateToProps, { getEcosystems })(
  withStyles(styles)(EcosystemsInfo)
);
