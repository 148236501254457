import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import List from '@material-ui/core/List';

import GridMdWrapper from './wrapper/GridMdWrapper';

const useStyles = makeStyles((theme) => ({
  alignment: {
    backgroundColor: '#E8E8E8 ',
    padding: '1rem',
  },
  invisible: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    cursor: 'pointer',
    color: '#000000',
    textDecoration: 'none',
  },
  textPadding: {
    padding: '0.35rem 0',
    fontSize: '0.875rem',
    textAlign: 'left',
  },
  legal: {
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <GridMdWrapper style={{ backgroundColor: '#E8E8E8' }}>
      <Grid container justify='center'>
        <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'start' }}>
          <List>
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              SCHROCKEN
            </Typography>
            <Typography
              variant='body2'
              className={classes.textPadding}
              style={{ marginRight: '30px' }}
            >
              <Link
                className={classes.link}
                href='https://www.schrocken.com/mosymphony/'
                target='_blank'
              >
                Mosymphony
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/about-us/'
                target='_blank'
              >
                Who We are
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/contact-us/'
                target='_blank'
              >
                Get In Touch
              </Link>
            </Typography>
          </List>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <List>
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              LEGAL
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/privacy-security/'
                target='_blank'
              >
                Privacy & Security
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='http://www.schrocken.com/terms-of-service/'
                target='_blank'
              >
                Terms of Service
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/policies/'
                target='_blank'
              >
                Policies
              </Link>
            </Typography>
          </List>
        </Grid>

        <Grid item xs={12} sm={3} md={4}>
          <List>
            <Typography variant='body1' style={{ fontWeight: 500 }}>
              SUPPORT &amp; LINKS
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/usage-guide-mosymphony/'
                target='_blank'
              >
                How to Videos?
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/create-a-support-request/'
                target='_blank'
              >
                Create a Support Request
              </Link>
            </Typography>
            <Typography variant='body2' className={classes.textPadding}>
              <Link
                className={classes.link}
                href='https://www.schrocken.com/feedback/'
                target='_blank'
              >
                Give Us Your Feedback
              </Link>
            </Typography>
          </List>
        </Grid>
      </Grid>
    </GridMdWrapper>
  );
}



