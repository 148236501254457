import React, { Component } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
//files
import Header from '../../common/models/NewHeader';
import QuickTable from '../models/QuickViewTable';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Backdrop from '@material-ui/core/Backdrop';
import {
  quickViewAction,
  fetchUOM,
  approveGRN,
  recordTimelineAction,
  recordResolutionAction,
} from '../../../containers/actions/cmo/shortFallsActions';
import StyledDialog from '../../../../common/models/Dialog';
import POItemTable from '../../common/models/POItemTable';
import ReceivedQuantity from '../models/ReceivedQuantity';
import QualityAssurance from '../models/QualityAssuranceInfo';
import ChallanInfo from '../models/ChallanInfo';
import InvoiceInfo from '../models/InvoiceInfo';
import OtherInfo from '../models/OtherInfo';
import CheckBoxButton from '../CheckboxAndButton';
import RecordTimeline from '../Accordion';
import { SupplierPOContext } from '../grnReceipts/context';
import { RejectionRemarks } from '../models/dialogs/CommonDialogs';

class ApproveRecords extends Component {
  static contextType = SupplierPOContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      resError: null,

      POLineRecordDetails: {},
      POBatchRecordDetails: {},
      challanInformation: {},
      invoiceInformation: {},
      qualityAssuranceInfo: {},
      receivedQuantityInfo: {},

      //edit dialog states
      openPOItem: false,
      openReceived: false,
      openQuality: false,
      openChallan: false,
      openInvoice: false,
      openOtherInfo: false,
      //consent
      verifyConsent: false,
      //updateGRN
      isSubmitForApproval: false,
      //action flags for Approve/Repeat/Reject records
      action: false,
      actionType: '',
      recordSaved: false,
      submitRecord: false,
      loading: true,
      reasonForRejection: '',
      submitAlternative: false,
      isRecordApproved: false,
      timeLine: [],
      isTextfieldError: false,
      resolution: [],
      disableButtons: {
        recordRejected: false,
        recordAccepted: false,
        approveRecord: false,
      },
    };
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    const data = {
      supplierPurchaseOrderNumber:
        this.props.POLineRecordDetails.supplierPurchaseOrderNumber,
      materialCode: this.props.POLineRecordDetails.materialCode,
      schrockenSupplierPOBatchID: this.props.row.schrockenSupplierPOBatchID,
    };
    this.props.quickViewAction(data);
    this.props.recordTimelineAction(data);
    //record resolution func
    this.props.recordResolutionAction(data);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.UOM !== prevProps.UOM && this.props.UOM.status === true) {
      this.setState({
        CompleteUOM: this.props.UOM.data.UOM,
      });
    }
    if (
      this.props.approveGRNData !== prevProps.approveGRNData &&
      this.props.approveGRNData.status === true &&
      this.state.isRecordApproved === true
    ) {
      this.setState({
        submitRecord: true,
        errors: {},
        disableButtons: {
          recordRejected: false,
          recordAccepted: false,
        },
      });
    }
    if (
      this.props.approveGRNData !== prevProps.approveGRNData &&
      this.props.approveGRNData.status === true &&
      this.state.isRecordApproved === false
    ) {
      this.setState({
        submitRecord: true,
        recordSaved: false,
        errors: {},
        disableButtons: {
          recordRejected: false,
          recordAccepted: false,
        },
      });
    }
    if (
      this.props.supplierPOData !== prevProps.supplierPOData &&
      this.props.supplierPOData.status === false
    ) {
      let errorKey = Object.keys(this.props.supplierPOData.errorDescription)[0];
      this.setState({
        resError: this.props.supplierPOData.errorDescription[errorKey],
        errors: {
          [errorKey]: true,
        },
      });
    }
    // grn got generated but qc cc got failed - we are showing the error desc and calling fetch api
    if (
      this.props.approveGRNData !== prevProps.approveGRNData &&
      this.props.approveGRNData.status === false
    ) {
      if (this.props.approveGRNData.grnGenerated === true) {
        // calling fetch api
        const data = {
          supplierPurchaseOrderNumber:
            this.props.POLineRecordDetails.supplierPurchaseOrderNumber,
          materialCode: this.props.POLineRecordDetails.materialCode,
          schrockenSupplierPOBatchID: this.props.row.schrockenSupplierPOBatchID,
        };
        this.props.quickViewAction(data);
      }

      if (typeof this.props.approveGRNData.errorDescription === 'object') {
        let errorKey = Object.keys(
          this.props.approveGRNData.errorDescription
        )[0];
        this.setState({
          resError: this.props.approveGRNData.errorDescription[errorKey],
          errors: {
            [errorKey]: true,
          },
          disableButtons: {
            recordRejected: false,
            recordAccepted: false,
            approveRecord: this.props.approveGRNData.grnGenerated,
          },
        });
      } else {
        let errorKey = this.props.approveGRNData.errorDescription;

        this.setState({
          resError: this.props.approveGRNData.errorDescription,
          errors: {
            [errorKey]: true,
          },
          disableButtons: {
            recordRejected: false,
            recordAccepted: false,
            approveRecord: this.props.approveGRNData.grnGenerated,
          },
        });
      }
    }

    if (
      this.props.supplierPOData &&
      this.props.supplierPOData !== prevProps.supplierPOData
    ) {
      let { POLineRecordDetails } = this.props.supplierPOData;
      let {
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
      } = this.props.supplierPOData.GRDetails;
      this.setState({
        POLineRecordDetails,
        POBatchRecordDetails,
        challanInformation,
        invoiceInformation,
        qualityAssuranceInfo,
        receivedQuantityInfo,
        loading: false,
      });
    }
    if (
      this.props.recordTimeline &&
      this.props.recordTimeline !== prevProps.recordTimeline
    ) {
      this.setState({
        timeLine: this.props.recordTimeline.data,
      });
    }
    if (
      this.props.recordResolution &&
      this.props.recordResolution !== prevProps.recordResolution
    ) {
      this.setState({
        resolution: this.props.recordResolution.data,
      });
    }
  }
  handleOpenDialog = (name) => {
    this.setState({
      [name]: true,
    });
  };
  handleText = (e) => {
    this.setState({
      reasonForRejection: e.target.value,
      isTextfieldError: false,
    });
  };
  handleSavePOItemData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
    });
    this.handleCloseSectionDialog('openPOItem');
  };

  handleSaveReceivedQuantityData = (data) => {
    this.setState({
      receivedQuantityInfo: data,
    });
    this.handleCloseSectionDialog('openReceived');
  };
  handleSaveQualityAssuranceData = (data) => {
    this.setState({
      qualityAssuranceInfo: data,
    });
    this.handleCloseSectionDialog('openQuality');
  };
  handleSaveChallanInformationData = (data) => {
    this.setState({
      challanInformation: data,
    });
    this.handleCloseSectionDialog('openChallan');
  };
  handleSaveInvoiceInformationData = (data) => {
    this.setState({
      invoiceInformation: data,
    });
    this.handleCloseSectionDialog('openInvoice');
  };

  handleSaveOtherInformationData = (data) => {
    this.setState({
      POBatchRecordDetails: data,
    });
    this.handleCloseSectionDialog('openOtherInfo');
  };

  handleCloseSectionDialog = (name) => {
    this.setState({
      [name]: false,
    });
  };
  handleVerifyConsent = () => {
    this.setState({
      verifyConsent: !this.state.verifyConsent,
      errors: {},
      resError: null,
    });
  };
  handleRecordRejected = () => {
    this.setState({
      recordSaved: true,
      action: 'resubmit',
      actionType: 'rejection',
      isRecordApproved: false,
      resError: null,
    });
  };
  handleCloseAll = () => {
    this.setState({
      submitRecord: false,
      recordSaved: false,
      submitAlternative: false,
      resError: null,
      errors: {},
    });
    this.props.handleClose();
    //call parent method to refresh the Supplier PO table data
    this.context.fetchGRLineItems();
  };
  onClickClose = () => {
    this.setState({
      recordSaved: false,
      actionType: '',
      isTextfieldError: false,
    });
  };

  handleDialogBox = () => {
    if (this.state.verifyConsent === false) {
      this.setState({
        errors: {
          verifyConsent: true,
        },
        resError: null,
      });
    } else if (this.state.verifyConsent === true) {
      this.setState({
        submitAlternative: true,
        action: 'approve',
        actionType: 'approve',
        isRecordApproved: true,
        resError: null,
      });
    }
  };

  handleSubmitFalse = () => {
    this.setState({
      submitAlternative: false,
      resError: null,
      actionType: '',
      isTextfieldError: false,
    });
  };
  handleApproveRecord = () => {
    this.setState({
      disableButtons: {
        recordRejected: true,
        recordAccepted: true,
      },
      submitAlternative: false,
      resError: null,
    });
    const data = {
      schrockenSupplierPOLineID:
        this.state.POLineRecordDetails.schrockenSupplierPOLineID,
      schrockenSupplierPOBatchID:
        this.state.POBatchRecordDetails.schrockenSupplierPOBatchID,
      approveRecord: this.state.isRecordApproved,
      action: this.state.action,
      reasonForRejection: this.state.reasonForRejection,
    };
    if (
      this.state.action !== 'approve' &&
      this.state.reasonForRejection.trim() === ''
    ) {
      this.setState({
        isTextfieldError: true,
        disableButtons: {
          recordRejected: false,
        },
      });
    } else {
      this.props.approveGRN(
        data,
        `Please wait while ${
          this.state.action === 'approve' ? 'approving' : 'rejecting'
        } record`
      );
      this.setState({
        recordSaved: false,
        // reset action
        actionType: '',
      });
    }
  };
  selectOption = (event, value) => {
    this.setState({
      action: value,
      actionType: 'rejection',
    });
  };

  render() {
    const {
      POLineRecordDetails,
      POBatchRecordDetails,
      receivedQuantityInfo,
      qualityAssuranceInfo,
      challanInformation,
      invoiceInformation,
      errors,
      loading,
      resError,
      verifyConsent,
    } = this.state;
    const { handleClose } = this.props;
    if (resError /*&& errorRef* /* + other conditions */) {
      setTimeout(
        () =>
          this.errorRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        100
      );
    }
    return (
      <React.Fragment>
        <Header
          handleClose={handleClose}
          title='Approve Record'
          subtitle='Raw Material (RM) / Packaging Material (PM) Record'
          padding={true}
        >
          <div ref={this.errorRef}>
            {loading === true ? (
              <Grid container justify='center'>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '25%',
                    minWidth: '60vw',
                  }}
                >
                  <CircularProgress color='primary' thickness={7} />
                </div>
              </Grid>
            ) : (
              <Grid container justify='center'>
                <Grid item xs={12} sm={6} md={10}>
                  <QuickTable
                    unitOfMeasurement={
                      receivedQuantityInfo.unitOfMeasurementReceived
                    }
                    quantity={POLineRecordDetails.quantityOrdered}
                    actualQuantityRecieved={
                      receivedQuantityInfo.actualQuantityReceived
                    }
                  />

                  <Grid container justify='center'>
                    {resError && (
                      <Grid
                        container
                        justify='center'
                        style={{ padding: '1rem 0' }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant='h6'
                            style={{
                              border: '1px solid #FF0000',
                              backgroundColor: '#FFF0F0',
                              opacity: '1',
                              textAlign: 'center',
                              padding: '0.8rem',
                              fontSize: '0.875rem',
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {resError}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <POItemTable
                    {...this.props}
                    POLineRecordDetails={POLineRecordDetails}
                    POBatchRecordDetails={POBatchRecordDetails}
                    handleOpenDialog={this.handleOpenDialog}
                    isRecievedExits={true}
                    errors={errors}
                  />
                  <hr />

                  <ReceivedQuantity
                    {...this.props}
                    receivedQuantityInfo={receivedQuantityInfo}
                    isEditExist={false}
                    handleOpenDialog={this.handleOpenDialog}
                    errors={errors}
                  />

                  <hr />

                  <QualityAssurance
                    {...this.props}
                    qualityAssuranceInfo={qualityAssuranceInfo}
                    isEditExist={false}
                    handleOpenDialog={this.handleOpenDialog}
                    handlingDeleteFile={this.props.handlingDeleteFile}
                    errors={errors}
                  />

                  <hr />

                  <ChallanInfo
                    {...this.props}
                    challanInformation={challanInformation}
                    isEditExist={false}
                    handleOpenDialog={this.handleOpenDialog}
                    errors={errors}
                  />

                  <hr />

                  <InvoiceInfo
                    {...this.props}
                    invoiceInformation={invoiceInformation}
                    isEditExist={false}
                    handleOpenDialog={this.handleOpenDialog}
                    errors={errors}
                  />

                  <hr />

                  <OtherInfo
                    {...this.props}
                    POBatchRecordDetails={POBatchRecordDetails}
                    isEditExist={false}
                    handleOpenDialog={this.handleOpenDialog}
                    errors={errors}
                  />

                  <CheckBoxButton
                    status={POBatchRecordDetails.batchStatusID}
                    errors={this.state.errors}
                    verifyConsent={verifyConsent}
                    handleVerifyConsent={this.handleVerifyConsent}
                    handleApproveRecord={this.handleDialogBox}
                    handleRecordRejected={this.handleRecordRejected}
                    selectOption={this.selectOption}
                    action={this.state.action}
                    disableAccept={this.state.disableButtons.approveRecord}
                  />

                  <RejectionRemarks
                    open={this.state.actionType === 'rejection'}
                    title={
                      this.state.POBatchRecordDetails.batchStatusID === 3 ||
                      this.state.action === 'reject'
                        ? 'Record Rejected'
                        : 'Send for Repeat QC/QA'
                    }
                    textLabel={
                      this.state.POBatchRecordDetails.batchStatusID === 3 ||
                      this.state.action === 'reject'
                        ? 'Add remarks for rejection'
                        : 'Add remarks for repeating QC/QA'
                    }
                    subtitle={
                      this.state.POBatchRecordDetails.batchStatusID === 3 ||
                      this.state.action === 'reject'
                        ? 'Add remarks for rejection'
                        : 'Record will be sent to CMO for repeating QC/QA'
                    }
                    helperText={
                      this.state.POBatchRecordDetails.batchStatusID === 3 ||
                      this.state.action === 'reject'
                        ? 'Please add your reason for rejecting the Record'
                        : 'Please add reasons for repeating QC/QA'
                    }
                    handleAddReason={this.handleApproveRecord}
                    onClose={this.onClickClose}
                    onTextChange={this.handleText}
                    textValue={this.state.reasonForRejection}
                    disabled={this.state.disableButtons.recordRejected}
                  />

                  <Dialog
                    open={this.state.actionType === 'approve'}
                    onClose={this.handleSubmitFalse}
                    fullWidth
                  >
                    <StyledDialog
                      handleClose={this.handleApproveRecord}
                      hasMultipleButton={true}
                      handleNoButton={this.handleSubmitFalse}
                      isButtonDisabled={
                        this.state.disableButtons.recordAccepted
                      }
                      title='Do you want to ‘Commit’ this record?'
                      subtitle='Please check all the details before committing this record. You will not be able to edit this record further. You will have to contact the administrator if you need to modify this record.'
                    />
                  </Dialog>

                  <Dialog
                    open={this.state.submitRecord}
                    onClose={this.handleCloseAll}
                    fullWidth
                  >
                    <StyledDialog
                      icons={true}
                      handleClose={this.handleCloseAll}
                      title='Record Committed'
                      subtitle={
                        this.state.action === 'approve'
                          ? `Your record has been added in queue for ${
                              this.state.POBatchRecordDetails.batchStatusID ===
                              3 // Awaiting GRN state
                                ? 'GRN generation'
                                : 'further approval'
                            }.`
                          : this.state.action === 'resubmit'
                          ? 'Response has been sent to the CMO to resubmit record.'
                          : 'Record has been rejected and sent to External Manufacturing team for further action.'
                      }
                    />
                  </Dialog>
                  <RecordTimeline
                    timeLine={this.state.timeLine}
                    resolution={this.state.resolution}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </Header>
      </React.Fragment>
    );
  }
}

// export default ApproveRecords;

const mapStateToProps = (state) => ({
  supplierPOData: state.pcmp.cmo.shortFalls.quickView,
  UOM: state.pcmp.cmo.shortFalls.fetchUOMData,
  approveGRNData: state.pcmp.cmo.shortFalls.approveGRNData,
  // backdropLoading: state.pcmp.common.files.backdropLoading,
  recordTimeline: state.pcmp.cmo.shortFalls.recordTimeline,
  recordResolution: state.pcmp.cmo.shortFalls.recordResolution,
});

export default connect(mapStateToProps, {
  quickViewAction,
  fetchUOM,
  approveGRN,
  recordTimelineAction,
  recordResolutionAction,
})(ApproveRecords);
