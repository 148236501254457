import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//components
import { StyledCheckbox } from '../../../common/models/CheckBox';
import SettingsInputTextField from './SettingsInputTextField';

const useStyles = makeStyles({
  gridTop: {
    marginTop: '1.5rem',
  },
  headerText: {
    fontSize: '1rem',
  },
  gridBottom: {
    padding: '0.75rem 0',
  },
});

const PasswordSettings = (props) => {
  const classes = useStyles();
  const {
    minCharacters,
    maxCharacters,
    isOneCapitalLetter,
    isOneSmallLetter,
    isOneNumber,
    isAlphanumeric,
    mustHaveSpecialCharacters,
    shouldNotHaveNumbers,
    expirationTime,
    handleChange,
    handleCheck,
    errors,
  } = props;

  const checkBoxElements = [
    {
      name: 'isOneCapitalLetter',
      checked: isOneCapitalLetter,
      labelText: 'Should have at least a Capital Letter',
    },
    {
      name: 'isOneSmallLetter',
      checked: isOneSmallLetter,
      labelText: 'Should have at least One Small Letter',
    },
    {
      name: 'isOneNumber',
      checked: isOneNumber,
      labelText: 'Should have at least One Number',
    },
    {
      name: 'isAlphanumeric',
      checked: isAlphanumeric,
      labelText: 'Should be Alphanumeric',
    },
    {
      name: 'mustHaveSpecialCharacters',
      checked: mustHaveSpecialCharacters,
      labelText: 'Should have Special Characters',
    },
    {
      name: 'shouldNotHaveNumbers',
      checked: shouldNotHaveNumbers,
      labelText: 'Should not have Numbers',
    },
  ];
  return (
    <Grid item xs={12} className={classes.gridBottom}>
      <Typography
        variant='h6'
        className={`${classes.headerText} ${classes.gridBottom}`}
      >
        Password Settings
      </Typography>

      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>Minimum Number of Characters</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='minCharacters'
            id='Settings_minCharacters'
            obj='password'
            value={minCharacters}
            minValue={1}
            maxValue={99}
            handleChange={handleChange}
            label='Min Number of Characters'
            description='Enter min. Number of Characters'
            error={errors.minCharacters}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>Maximum Number of Characters</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='maxCharacters'
            id='Settings_maxCharacters'
            obj='password'
            value={maxCharacters}
            minValue={1}
            maxValue={99}
            handleChange={handleChange}
            label='Max Number of Characters'
            description='Enter max. Number of Characters'
            error={errors.maxCharacters}
          />
        </Grid>
      </Grid>
      <Typography variant='body2' className={classes.gridBottom}>
        Other Criteria
      </Typography>
      <Grid container alignItems='center'>
        <FormGroup row>
          {checkBoxElements.map((ele, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={i}
              className={classes.gridBottom}
            >
              <FormControlLabel
                control={
                  <StyledCheckbox
                    name={ele.name}
                    id={`settings_${ele.name}`}
                    checked={ele.checked}
                    onChange={(event) => handleCheck('password', event)}
                  />
                }
                label={
                  <Typography variant='caption'>{ele.labelText}</Typography>
                }
              />
            </Grid>
          ))}
        </FormGroup>
      </Grid>
      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'>Password Expiration Time</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='expirationTime'
            id='Settings_expirationTime'
            obj='password'
            value={expirationTime}
            minValue={1}
            maxValue={365}
            handleChange={handleChange}
            label='Password Expiration Time'
            description='Enter Time in Days'
            error={errors.expirationTime}
          />
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default PasswordSettings;
