import {
  OTIF_REPORT,
  YIELD_REPORT,
  AUDIT_REPORT,
  LINE_AUDIT_REPORT,
  PO_LINE_NUMBERS,
  BATCH_AUDIT_REPORT,
  PO_LINE_BATCH_NUMBERS,
  PO_NUMBER_ERROR,
  GRN_STATUSES,
  GRN_FEATURES,
} from '../../actions/types';

const intialState = {};

export default function (state = intialState, action) {
  switch (action.type) {
    case OTIF_REPORT:
      return {
        ...state,
        otifReport: action.payload,
      };
    case YIELD_REPORT:
      return {
        ...state,
        yieldReport: action.payload,
      };
    case AUDIT_REPORT:
      return {
        ...state,
        poAuditReport: action.payload,
      };
    case LINE_AUDIT_REPORT:
      return {
        ...state,
        poLineAuditReport: action.payload,
      };
    case PO_LINE_NUMBERS:
      return {
        ...state,
        lineNumbers: action.payload,
      };
    case BATCH_AUDIT_REPORT:
      return {
        ...state,
        batchAuditReport: action.payload,
      };
    case PO_LINE_BATCH_NUMBERS:
      return {
        ...state,
        batchNumbers: action.payload,
      };
    case PO_NUMBER_ERROR:
      return {
        ...state,
        poNumberError: action.payload,
      };
    case GRN_STATUSES:
      return {
        ...state,
        grnstatuses: action.payload,
      };
    case GRN_FEATURES:
      return {
        ...state,
        rmpmGrnFeatures: action.payload,
      };
    default:
      return state;
  }
}
