import React from 'react';
import HeaderFooterwithCookies from '../../../common/openPages/HeaderFooterwithCookies';
import ResetPasswordCompletedCard from './ResetPasswordCompletedCard';

const ResetPasswordCompleted = (props) => {
  return (
    <HeaderFooterwithCookies cookiesEnabled>
      <ResetPasswordCompletedCard />
    </HeaderFooterwithCookies>
  );
};

export default ResetPasswordCompleted;
