import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledCheckbox } from '../../common/models/CheckBox';

const useStyles = makeStyles((theme) => ({
  root: {
    justify: 'flex-start',
    backgroundColor: '#F2F2F2',
    border: '2px solid #E2E2E2',
    maxHeight: '22rem',
    overflow: 'scroll',
    padding: '1rem',
  },
  text: {
    marginBottom: '0.9rem',
    fontSize: '0.8rem',
  },
  bottomgrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '2rem',
    marginTop: '1rem',
  },
}));

const ConsentAgreement = (props) => {
  const { handleConsent, consent1, consent2, errors } = props;

  const classes = useStyles();

  return (
    <Grid container justify='center'>
      <Typography
        variant='h6'
        style={{ fontSize: '1rem', padding: '0.75rem 0' }}
      >
        Schrocken Application and Services Terms &amp; Conditions
      </Typography>

      <Grid item xs={11} sm={11} md={11}>
        {/* Maintain a class for this box and for small screens increase size -media query*/}
        <Box className={classes.root}>
          <Typography variant='body1' className={classes.text}>
            These terms and conditions create a contract between you and
            Schrocken Inc (the “Agreement”). Please read the Agreement
            carefully. To confirm your understanding and acceptance of the
            Agreement, click “Agree.”
          </Typography>

          <Typography variant='body1' className={classes.text}>
            A. INTRODUCTION TO OUR SERVICES
          </Typography>

          <Typography variant='body1' className={classes.text}>
            This Agreement governs your use of Schrocken’s services
            (“Services”), through which you can buy, get, license, rent or
            subscribe to content, apps (“Apps”), and other in-app services
            (collectively, “Content”). Content may be offered through the
            Services by Schrocken Inc. Our Services are available for your use
            in your country of residence (“Home Country”). To use our Services,
            you need compatible hardware, software (latest version recommended
            and sometimes required) and Internet access (fees may apply). Our
            Services’ performance may be affected by these factors.
          </Typography>

          <Typography variant='body1' className={classes.text}>
            B. USING OUR SERVICES
          </Typography>

          <Typography variant='body1' className={classes.text}>
            SCHROCKEN ID Using our Services and accessing your Content may
            require an Schrocken ID. An Schrocken ID is the account you use
            across Schrocken’s ecosystem. Your Schrocken ID is valuable, and you
            are responsible for maintaining its confidentiality and security.
            Schrocken is not responsible for any losses arising from the
            unauthorized use of your Schrocken ID. Please contact Schrocken if
            you suspect that your Schrocken ID has been compromised. You must be
            age 13 (or equivalent minimum age in your Home Country, as set forth
            in the registration process) to create an Schrocken ID and use our
            Services.
          </Typography>

          <Typography variant='body1' className={classes.text}>
            PRIVACY
          </Typography>

          <Typography variant='body1' className={classes.text}>
            Your use of our Services is subject to Schrocken’s Privacy Policy,
            which is available at https://www.schrocken.com/legal/privacy/.
          </Typography>

          <Typography variant='body1' className={classes.text}>
            SERVICES AND CONTENT USAGE RULES
          </Typography>

          <Typography variant='body1' className={classes.text}>
            Your use of the Services and Content must follow the rules set forth
            in this section (“Usage Rules”). Any other use of the Services and
            Content is a material breach of this Agreement. Schrocken may
            monitor your use of the Services and Content to ensure that you are
            following these Usage Rules.
          </Typography>
        </Box>
      </Grid>
      {/** Show consent error */}
      {errors.consent && (
        <Grid item xs={11} style={{ marginTop: '1rem' }}>
          <Typography
            variant='body1'
            style={{
              border: '1px solid #FF0000',
              backgroundColor: '#FFF0F0',
              opacity: '1',
              textAlign: 'center',
              padding: '0.5rem',
              fontSize: '0.875rem',
            }}
          >
            {errors.consent}
          </Typography>
        </Grid>
      )}
      {/* needs to have checkboxes down */}
      <Grid
        item
        xs={11}
        style={{ marginLeft: '0.25rem', paddingTop: '0.75rem' }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <StyledCheckbox
                name='consent1'
                checked={consent1}
                onChange={handleConsent}
              />
            }
            label={
              <Typography variant='caption' style={{ fontSize: '0.75rem' }}>
                I have read and agree to the above terms &amp; conditions for
                application usage
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <StyledCheckbox
                name='consent2'
                checked={consent2}
                onChange={handleConsent}
              />
            }
            label={
              <Typography variant='caption' style={{ fontSize: '0.75rem' }}>
                I accept and agree with the data storage &amp; usage policy
                mentioned above
              </Typography>
            }
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default ConsentAgreement;
