import React from "react";
import Layout from "./Layout";
import { Switch, Route } from "react-router-dom";
 
// components
import EcosystemSettings from "./ecosystemSettings";
import Ecosystems from "./ecosystems";

const Settings = () => {
  return (
    <Layout>
      <Switch>
        <Route
          exact
          path="/schrockenAdmin/settings/ecosystemsettings"
          component={EcosystemSettings}
        />
        <Route
          exact
          path="/schrockenAdmin/settings/ecosystems"
          component={Ecosystems}
        />
      </Switch>
    </Layout>
  );
};

export default Settings;
