import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import StyledButton from '../common/models/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
    padding: '0.75rem',
  },
  text: {
    fontWeight: '500',
    padding: '0.5rem',
  },
  root: {
    minWidth: 275,
    padding: '0.75rem',
  },
  btns: {
    textAlign: 'center',
  },
});
const ValidationFailedCard = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      style={{
        position: 'fixed',
        // width: '100%',
        marginTop: '-6rem',
        height: '100%',
        backgroundColor: '#F2F2F2',
      }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Grid item xs={12} className={classes.header}>
            <Typography variant='h4' className={classes.text}>
              2-Step Authentication Failed
            </Typography>
            <br />
            <br />
            <Typography
              variant='body2'
              style={{ fontSize: '1rem', padding: '0.75rem' }}
            >
              You have exceeded your verification attempts limit.Please <br />
              login again to restart the process.
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid item xs={12} className={classes.btns}>
            <StyledButton
              type='submit'
              variant='contained'
              color='primary'
              size='medium'
              style={{
                padding: '0.5rem 2rem',
              }}
              component={Link}
              to='/login'
            >
              Sign In
            </StyledButton>
            <br />
            <br />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ValidationFailedCard;
