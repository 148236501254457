import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Paper,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
//files
import Search from './Search';
import TableDetails from '../common/DeliveryScheduleDetails';
import DeliveryTable from '../deliveryScheduleBatchTable';
import { useStyles } from './styles';
import {
  batchTableSource,
  getBatchQAStatus,
  newbatchTable,
} from '../../../../containers/actions/common/saslAction';
import {
  fetchBatchDetails,
  getAllDSBatchStatusSequence,
} from '../../../../containers/actions/common/saslAction';
import AddBatchButton from './AddBatchButton';
import { filterByQAStatus, searchItems } from './filterSearchAlgo';
import StatusFilter from './StatusFilter';
import { filterItems } from './filterSearchAlgo';
import {
  loadTableData,
  mainTableDataLoading,
} from '../../../../containers/actions/common/fileActions';
import RowsPerPage from '../../models/inputs/RowsPerPage';
import { getFeaturesAccess } from '../../../../containers/actions/common/accessControl';
import NotFound from '../../../../notFound/NotFound';
import featuresAndValidations from '../../models/featuresAccess/FeaturesAndValidations';
import { dsFilters } from '../../../../containers/actions/common/filtersAction';
import BackDropLoading from '../../models/BackDrop';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsNoneSharpIcon from '@material-ui/icons/NotificationsNoneSharp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const DeliveryScheduleDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { params } = props.match;

  const [noRowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, searchQueryInput] = useState('');
  const [tempSearch, tempSearchQury] = useState('');
  const [deliveryScheduleUOM, setDeliveryScheduleUOM] = useState('');
  const [uomQA, setuomQA] = useState('');
  //view by status
  const [status, selectStatus] = useState('all');
  const [qaStatus, selectQAStatus] = useState('all');
  const [coaReminder, setCoaReminder] = useState(true);
  const [currentReminder, setCurrentReminder] = useState(1);

  const dsFilterValue = useSelector((state) => state.pcmp.common.filters);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (dsFilterValue?.dsFilters && firstLoad.current === true) {
      firstLoad.current = false;
      searchQueryInput(dsFilterValue.dsFilters.searchQuery);
      selectStatus(dsFilterValue.dsFilters.status);
      setRowsPerPage(dsFilterValue.dsFilters.noRowsPerPage);
      setCurrentPage(dsFilterValue.dsFilters.currentPage);
      tempSearchQury(dsFilterValue.dsFilters.tempSearch);
      selectQAStatus(dsFilterValue.dsFilters.qaStatus);
    }
  }, [dispatch, dsFilterValue]);

  useEffect(() => {
    dispatch(
      dsFilters({
        searchQuery: searchQuery,
        status: status,
        qaStatus: qaStatus,
        noRowsPerPage: noRowsPerPage,
        currentPage: currentPage,
        tempSearch: tempSearch,
      })
    );
  }, [
    dispatch,
    searchQuery,
    status,
    qaStatus,
    noRowsPerPage,
    currentPage,
    tempSearch,
  ]);

  const screenAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.screensAccess?.data.deliverySchedule
        ?.screenAccess
  );

  const featuresAccess = useSelector(
    (state) =>
      state.pcmp.common.accessControl.featuresAccess.deliverySchedulesFeatures
  );

  const getDeliveryScheduleUOM = (uomData) => {
    setDeliveryScheduleUOM(uomData.deliveryScheduleUOM);
    setuomQA(uomData.uomQA);
  };

  useEffect(() => {
    if (screenAccess && !featuresAccess)
      dispatch(getFeaturesAccess(10, 'deliverySchedulesFeatures')); //10 is DS screen ID
    // We can pick up DS screen Id from access screensAccess.data.deliverySchedule.screenId
  }, [dispatch, screenAccess, featuresAccess]);

  useEffect(() => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(params.schrockenDeliveryScheduleID),
    };
    // dispatch(deliveryScheduleHeader(data));

    if (screenAccess) {
      dispatch(fetchBatchDetails(data));
      dispatch(getAllDSBatchStatusSequence());
      dispatch(mainTableDataLoading());
      dispatch(getBatchQAStatus());
    }
    return () => {
      if (batchTableSource) {
        batchTableSource.cancel('Api cancelled during unmount');
      }
      if (newbatchTable) {
        newbatchTable.cancel('api uncalled');
      }
    };
  }, [dispatch, params.schrockenDeliveryScheduleID, screenAccess]);

  let statusArray = [];
  let qaStatusArray = useSelector((state) => {
    if (state.pcmp.common.sasl.allQAStatus) {
      return state.pcmp.common.sasl.allQAStatus;
    } else {
      return [];
    }
  });
  statusArray = useSelector((state) => {
    return state.pcmp.common.sasl.allDSStatus;
  });

  const qualityApprovalFlowFlag = useSelector((state) => {
    return state.pcmp.common.reports.rmpmGrnFeatures?.data?.features
      .qualityApprovalEnabled;
  });

  //loading
  let loading = useSelector((state) => {
    return state.pcmp.common.sasl.mainTableLoading;
  });

  let batchData = useSelector((state) => {
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true &&
      state.pcmp.common.sasl.batchDetails.data.message !== undefined
      // && deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return [];
    }
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true
      // deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return state.pcmp.common.sasl.batchDetails.data;
    }
    if (
      state.pcmp.common.sasl.batchDetails.data &&
      state.pcmp.common.sasl.batchDetails.status === true &&
      state.pcmp.common.sasl.batchDetails.data.length === 0
      // deliveryHeader!== undefined && deliveryHeader.status === true
    ) {
      // loading = false;
      return state.pcmp.common.sasl.batchDetails.data;
    }
    return [];
  });

  const fetchDeliveryScheduleBatchDetails = () => {
    let data = {
      schrockenDeliveryScheduleID: parseInt(params.schrockenDeliveryScheduleID),
    };

    dispatch(fetchBatchDetails(data));
    // To trigger loading indicator on the table
    dispatch(loadTableData());
  };

  const handleSetRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  let coaReminderNotification = batchData.filter(
    (val) => val.sendReminderForCOAUpload === true
  );
  // let filteredDSBatch =
  //   status !== 'all'
  //     ? filterItems(batchData, 'SASLBatchStatusID', status)
  //     : batchData;
  let filteredDSBatch =
    status !== 'all' && qaStatus !== 'all'
      ? filterItems(
          filterByQAStatus(batchData, 'QAStatusID', qaStatus),
          'SASLBatchStatusID',
          status
        )
      : status === 'all' && qaStatus !== 'all'
      ? filterByQAStatus(batchData, 'QAStatusID', qaStatus)
      : status !== 'all' && qaStatus === 'all'
      ? filterItems(batchData, 'SASLBatchStatusID', status)
      : batchData;

  let showPagination = filteredDSBatch
    ? filteredDSBatch.length > 0
      ? true
      : false
    : false;

  let records = currentPage * noRowsPerPage;

  let filteredBatchData = filteredDSBatch; //yet To filter
  if (searchQuery !== '') {
    filteredBatchData = searchItems(
      filteredBatchData,
      ['vendorBatchNumber'],
      searchQuery
    );
  }

  let filteredBatch = filteredBatchData
    ? filteredBatchData.slice(records - noRowsPerPage, records)
    : [];

  let pages = filteredBatchData
    ? Math.ceil(filteredBatchData.length / noRowsPerPage)
    : null;

  let noPages = pages ? pages : 1;

  const handleSearchQuery = (event) => {
    let { value } = event.target;
    // tempSearchQury(event.target.value);
    if (value === '' || value === null) {
      tempSearchQury(value);
      searchQueryInput('');
    } else {
      tempSearchQury(value);
    }
  };

  const handleClearSearch = (event) => {
    tempSearchQury('');
    searchQueryInput('');
  };

  const submitSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    searchQueryInput(tempSearch.trim());
  };

  const redirectfunc = () => {
    if (location.state?.batchDashboard) {
      history.push(
        `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/fgBatches`,
        { batchDashboard: location.state?.batchDashboard }
      );
    } else {
      history.push(
        `/pcmp/${localStorage.collabType}/${localStorage.roleName}/${localStorage.organizationName}/${localStorage.ecosystemId}/schedule agreements`,
        { scheduleAgreements: true }
      );
    }
  };

  const handleSelectStatus = (event) => {
    selectStatus(event.target.value);
  };

  const handleSelectQAStatus = (event) => {
    selectQAStatus(event.target.value);
  };

  const handleReminderClose = () => {
    setCoaReminder(false);
  };

  const handleBack = () => {
    if (currentReminder !== 1) {
      setCurrentReminder(currentReminder - 1);
    }
  };

  const handleNext = () => {
    if (currentReminder !== coaReminderNotification.length) {
      setCurrentReminder(currentReminder + 1);
    }
  };

  if (!screenAccess) {
    return <NotFound />;
  }
  return (
    <React.Fragment>
      <BackDropLoading />
      <Grid container>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          style={{ paddingBottom: '1rem' }}
        >
          <Grid
            item
            xs={2}
            sm={
              localStorage.collabType === 'CMO' &&
              qualityApprovalFlowFlag &&
              featuresAccess?.updateFGBatch &&
              coaReminderNotification.length > 0 &&
              coaReminder
                ? 2
                : 6
            }
            md={
              localStorage.collabType === 'CMO' &&
              qualityApprovalFlowFlag &&
              featuresAccess?.updateFGBatch &&
              coaReminderNotification.length > 0 &&
              coaReminder
                ? 2
                : 6
            }
          >
            <Button
              className={classes.backBtn}
              startIcon={<ArrowBackIosIcon fontSize='small' />}
              size='small'
              onClick={redirectfunc}
            >
              Back
            </Button>
          </Grid>
          {localStorage.collabType === 'CMO' &&
            qualityApprovalFlowFlag &&
            featuresAccess?.updateFGBatch &&
            coaReminderNotification.length > 0 &&
            coaReminder && (
              <Grid item>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                  style={{
                    backgroundColor: '#c2e7ff',
                    padding: '1rem',
                    borderRadius: '14px',
                  }}
                >
                  <Paper
                    style={{
                      color: 'white',
                      backgroundColor: '#F00',
                      borderRadius: '20px',
                    }}
                  >
                    <NotificationsNoneSharpIcon />
                  </Paper>
                  <>
                    <ArrowBackIosIcon
                      page={currentReminder}
                      titleAccess='Previous'
                      onClick={handleBack}
                      color={currentReminder === 1 ? 'disabled' : 'action'}
                      style={{
                        cursor: 'pointer',
                        height: '16px',
                        paddingLeft: '5px',
                        color: 'black',
                      }}
                    />
                    <Typography variant='body1' style={{ width: 'auto' }}>
                      {currentReminder} of {coaReminderNotification.length}
                    </Typography>
                    <ArrowForwardIosIcon
                      size='small'
                      titleAccess='Next'
                      page={currentReminder}
                      onClick={handleNext}
                      color={
                        currentReminder === coaReminderNotification.length
                          ? 'disabled'
                          : 'action'
                      }
                      style={{
                        cursor: 'pointer',
                        height: '16px',
                        paddingRight: '5px',
                        color: 'black',
                      }}
                    />
                  </>
                  <Typography style={{ width: '30rem' }}>
                    {`You have received a reminder from ${
                      coaReminderNotification[currentReminder - 1]
                        ?.brandOwnerName
                    } to upload CoA for Batch ${
                      coaReminderNotification[currentReminder - 1]
                        ?.vendorBatchNumber
                    }`}
                  </Typography>
                  <CloseIcon
                    fontSize='small'
                    onClick={handleReminderClose}
                    style={{
                      cursor: 'pointer',
                      color: '#F00',
                      paddingLeft: '5px',
                    }}
                  />
                </Grid>
                {/* <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={coaReminder}
              >
                <MuiAlert
                  icon={
                    <Paper
                      style={{
                        color: 'white',
                        backgroundColor: '#F00',
                        borderRadius: '20px',
                        height: '30px',
                        width: '30px',
                      }}
                    >
                      <NotificationsNoneSharpIcon
                        style={{
                          height: '25px',
                          width: '25px',
                          padding: '2.5px',
                        }}
                      />
                    </Paper>
                  }
                  className={classes.reminderIcon}
                  elevation={6}
                  style={{ backgroundColor: '#c2e7ff', color: 'black' }}
                  variant='filled'
                  onClose={handleReminderClose}
                  // severity='success'
                  {...props}
                  action={
                    <CloseIcon
                      fontSize='small'
                      onClick={handleReminderClose}
                      style={{
                        cursor: 'pointer',
                        color: '#F00',
                        paddingLeft: '5px',
                      }}
                    />
                  }
                >
                  <Grid
                    container
                    direction='row'
                    style={{ alignItems: 'center' }}
                  >
                    <Grid item xs={2}>
                      <Grid
                        container
                        direction='row'
                        style={{ alignItems: 'center' }}
                      >
                        <Typography>
                          <ArrowBackIosIcon
                            page={currentReminder}
                            titleAccess='Previous'
                            onClick={handleBack}
                            color={
                              currentReminder === 1 ? 'disabled' : 'action'
                            }
                            style={{ cursor: 'pointer', height: '12px' }}
                          />
                          {currentReminder} of {coaReminderNotification.length}
                          <ArrowForwardIosIcon
                            size='small'
                            titleAccess='Next'
                            page={currentReminder}
                            onClick={handleNext}
                            color={
                              currentReminder === coaReminderNotification.length
                                ? 'disabled'
                                : 'action'
                            }
                            style={{ cursor: 'pointer', height: '12px' }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>
                        {`You have received a reminder from ABC to upload CoA for Batch ${
                          coaReminderNotification[currentReminder - 1]
                            ?.vendorBatchNumber
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </MuiAlert>
              </Snackbar> */}
              </Grid>
            )}

          {localStorage.collabType === 'CMO' &&
            featuresAccess?.updateFGBatch && (
              <Grid
                item
                xs={12}
                md={coaReminderNotification.length > 0 && coaReminder ? 3 : 6}
              >
                <Grid
                  container
                  className={clsx(
                    classes.dpFlx,
                    classes.flxEndSmFlxStrt,
                    classes.pdTponLr
                  )}
                >
                  <AddBatchButton
                    deliveryScheduleUOM={deliveryScheduleUOM}
                    uomQA={uomQA}
                  />
                </Grid>
              </Grid>
            )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TableDetails
            schrockenDeliveryScheduleID={params.schrockenDeliveryScheduleID}
            getUOM={getDeliveryScheduleUOM}
          />
        </Grid>

        <Grid
          container
          alignItems='center'
          className={classes.pdTp}
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Search
            searchQuery={tempSearch}
            handleSearchQuery={handleSearchQuery}
            handleClearSearch={handleClearSearch}
            submitSearch={submitSearch}
          />
          <Grid item xs={12} md={6}>
            <Grid
              container
              className={clsx(
                classes.dpFlx,
                classes.flxEndSmFlxStrt,
                classes.pdTponLr
              )}
            >
              <Typography variant='caption' className={clsx(classes.padR20)}>
                {`Last synced @ ${moment(new Date()).format(
                  'HH:mm:ss; DD MMMM, YYYY'
                )}`}
              </Typography>

              <Typography
                variant='caption'
                color='primary'
                onClick={fetchDeliveryScheduleBatchDetails}
                className={clsx(
                  classes.dpFlx,
                  classes.algnCntr,
                  classes.pointer,
                  classes.onHvrUndrline
                )}
              >
                Refresh
                <CachedIcon color='primary' style={{ fontSize: 15 }} />
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems='center' className={classes.pdTp}>
            <Grid item xs={4} sm={3}>
              <Typography variant='body2' className={classes.hgText}>
                View by Status:
              </Typography>
              <StatusFilter
                status={status}
                handleSelectStatus={handleSelectStatus}
                statusArray={statusArray}
              />
            </Grid>
            {qualityApprovalFlowFlag && (
              <Grid item xs={4} sm={3} style={{ paddingLeft: '10px' }}>
                <Typography variant='body2' className={classes.hgText}>
                  View by QA Status:
                </Typography>
                <FormControl style={{ minWidth: 120 }} fullWidth>
                  <Select
                    labelId='qa-status-filter'
                    id='qa-status-filter-id'
                    value={qaStatus}
                    onChange={handleSelectQAStatus}
                    variant='outlined'
                    classes={{
                      outlined: classes.outlined,
                    }}
                    // MenuProps={{
                    //   PaperProps: {
                    //     style: {
                    //       height: qaStatusArray.length > 1 ? '27%' : '8%',
                    //       overflowY:
                    //         qaStatusArray.length > 1 ? 'scroll' : 'hidden',
                    //     },
                    //   },
                    // }}
                  >
                    <MenuItem value='all'>All</MenuItem>
                    {qaStatusArray.map((dsStatus, index) => {
                      return (
                        <MenuItem key={index} value={dsStatus.statusID}>
                          {dsStatus.statusDisplayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <StatusFilter
                  status={qaStatus}
                  handleSelectStatus={handleSelectQAStatus}
                  statusArray={qaStatusArray}
                /> */}
              </Grid>
            )}

            <Grid
              item
              xs={4}
              sm={6}
              className={clsx(classes.searchFld, classes.flxEd)}
            >
              <RowsPerPage value={noRowsPerPage} onChange={handleSetRows} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeliveryTable
        batchData={filteredBatch}
        loading={loading}
        schrockenDeliveryScheduleID={params.schrockenDeliveryScheduleID}
      />

      {showPagination === true && (
        <Grid
          container
          alignItems='center'
          justify='center'
          style={{ paddingTop: '1rem' }}
        >
          <Pagination
            page={currentPage}
            name='currentPage'
            onChange={(event, page) => handlePageChange(event, page)}
            count={noPages}
            variant='outlined'
            shape='rounded'
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default featuresAndValidations(DeliveryScheduleDetails);
