import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
//components
import SettingsInputTextField from './SettingsInputTextField';

const useStyles = makeStyles({
  gridTop: {
    marginTop: '2rem',
  },
  headerText: {
    fontSize: '1rem',
  },
  gridBottom: {
    padding: '0.75rem 0',
  },
});

const LoginSettings = (props) => {
  const classes = useStyles();
  const { maxLoginAttempts, handleChange, error } = props;
  return (
    <Grid item xs={12} className={classes.gridTop}>
      <Typography
        variant='h6'
        className={`${classes.headerText} ${classes.gridBottom}`}
      >
        Login Settings
      </Typography>

      <Grid container alignItems='center' className={classes.gridBottom}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant='body2'> Number of Login Attempts </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0.75rem' }}>
          <SettingsInputTextField
            name='maxLoginAttempts'
            id='Settings_maxLoginAttempts'
            obj='login'
            value={maxLoginAttempts}
            minValue={1}
            maxValue={99}
            handleChange={handleChange}
            label='No. of Login Attempts'
            description='Enter Number of Login Attempts'
            error={error}
          />
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default LoginSettings;
