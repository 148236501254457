import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  link: {
    color: (props) => (props.downloadable ? 'blue' : '#000000'),
    cursor: (props) => (props.downloadable ? 'pointer' : 'none'),
    textDecoration: (props) => (props.downloadable ? 'underline' : 'none'),
    padding: '5px 0px',
  },
});
const ShowCoAFiles = (props) => {
  const classes = useStyles(props);
  const {
    files,
    clearable,
    downloadable,
    downloadFile,
    clearFile,
    id,
    variant,
    textClearControl,
    removeNoRecordsMsg,
    handleViewFile,
    coaFile,
    qualityApprovalFlowFlag,
  } = props;

  const handleFiles = (file) => {
    let fileName = file.fileName || file.name || file.customFileName;
    let pattern = /(?:\.([^.]+))?$/;
    let ext = pattern.exec(fileName)[1].toLowerCase();
    if (['pdf'].indexOf(ext) !== -1) {
      handleViewFile(file.schrockenCustomFileID);
    } else if (downloadable) {
      downloadFile(file.schrockenCustomFileID);
    }
  };
  return (
    <React.Fragment>
      {files.length > 0 ? (
        files.map((file, index) => (
          <Grid
            item
            xs={12}
            key={index}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* <div key={`${index}`}> */}
            <Typography
              variant={variant || 'body2'}
              style={{
                textDecoration:
                  qualityApprovalFlowFlag && coaFile && file?.markedForDelete
                    ? 'underline line-through'
                    : 'underline',
              }}
              className={classes.link}
              onClick={() => handleFiles(file)}
            >
              {file.fileName || file.name || file.customFileName}
            </Typography>

            {clearable ? (
              textClearControl ? (
                <Typography
                  variant='caption'
                  color='error'
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => clearFile(id, file.fileName || file.name)}
                >
                  Remove
                </Typography>
              ) : (
                <CloseIcon
                  fontSize='small'
                  onClick={() => clearFile(id, file.fileName || file.name)}
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                  }}
                />
              )
            ) : null}
            {/* </div> */}
          </Grid>
        ))
      ) : removeNoRecordsMsg ? null : (
        <Typography variant='body2'>-No Documents Uploaded-</Typography>
      )}
    </React.Fragment>
  );
};

ShowCoAFiles.propTypes = {
  id: PropTypes.string,
  files: PropTypes.array.isRequired,
  downloadable: PropTypes.bool,
  clearable: PropTypes.bool,
  downloadFile: PropTypes.func,
  clearFile: PropTypes.func,
  removeNoRecordsMsg: PropTypes.bool,
};

export default ShowCoAFiles;
