import React, { Component } from 'react';
import ErrorPage500Card from './ErrorPage500Card';
import Grid from '@material-ui/core/Grid';
import OpenPageMediaHeader from '../common/openPages/HeaderFooterwithCookies';

class ErrorPage500 extends Component {
  render() {
    return (
      <OpenPageMediaHeader>
        <Grid
          container
          justify='center'
        >
          <ErrorPage500Card />
        </Grid>
      </OpenPageMediaHeader>
    );
  }
}

export default ErrorPage500;
