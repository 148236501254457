import { SCREENS_ACCESS, FEATURES_ACCESS } from '../../actions/types';

const intialState = { screensAccess: {}, featuresAccess: {} };

export default function (state = intialState, action) {
  switch (action.type) {
    case SCREENS_ACCESS:
      return {
        ...state,
        screensAccess: action.payload,
      };
    case FEATURES_ACCESS:
      return {
        ...state,
        featuresAccess: {
          ...state.featuresAccess,
          [action.screenName]: action.payload,
        },
      };
    default:
      return state;
  }
}