import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import mosymLogo from './icons/mosymLogo.svg';
import Avatar from '@material-ui/core/Avatar';

import OrgIcon from './icons/org.png';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import SettingsIcon from '@material-ui/icons/Settings';
import {
  userProfileData,
  orgProfileData,
  userLogout,
} from '../../../../containers/actions/userActions';
import ToolTip from '../common/models/buttons/ToolTip';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 15,
  },
  flexAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pathName: {
    display: 'inline',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mosymLogo: {
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  rightList: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  accountMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonHover: {
    '&:hover': { backgroundColor: 'transparent' },
  },
}));

const Header = (props) => {
  const { handleDrawerOpen } = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  let userAvatar = useSelector((state) =>
    state.user.userDetails.profile_pic
      ? state.user.userDetails.profile_pic
      : '../../failedpath'
  );
  let userName = useSelector((state) =>
    state.user.userDetails.full_name
      ? state.user.userDetails.full_name.toUpperCase()
      : ''
  );

  let orgLogo = useSelector((state) => state.user.orgDetails.org_logo);

  useEffect(() => {
    dispatch(userProfileData());

    dispatch(orgProfileData());
  }, [dispatch]);

  let history = useHistory();
  let path = location.pathname.split('/');

  const [anchorEl, setAnchorEl] = useState(null);

  const currentPath = path[6];

  const pathName = (name) => {
    switch (name) {
      case 'dashboard':
        return 'Dashboard';
      case 'executivedashboard':
        return 'Executive Dashboard';
      case 'purchaseorders':
        return 'Purchase Orders';
      case 'lineitems':
        return 'Line Items';
      case 'fgBatches':
        return 'Batch Dashboard';
      case 'GRNReceipts':
        return 'GRN Receipts';
      case 'shortFalls':
        return 'Shortfall Reports';
      case 'schedule agreements':
        return 'Purchase Orders/Scheduling Agreements';
      case 'reports':
        return 'Audit Trail';
      case 'ecosystemusers':
        return 'Ecosystem Users';
      case 'userProfile':
        return 'My Profile';
      case 'orgprofile':
        return 'Organization Profile';
      case 'managemembers':
        return 'Ecosystem Users';
      case 'settings':
        return 'Settings';
      case 'help':
        return 'Help';
      case 'switch ecosystem':
        return 'Switch Ecosystems';
      case 'sasl':
        return 'Delivery Schedule Details';
      case 'sa-audit-reports':
        return 'SASL Reports';
      default:
        return 'Mosymphony';
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(userLogout(history));
  };
  return (
    <Toolbar>
      <Grid container alignItems='center' justify='space-between'>
        <Grid item xs={10} md={6} className={classes.flexAlign}>
          <IconButton
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={classes.menuButton}
            disableFocusRipple
            disableTouchRipple
          >
            <MenuIcon />
          </IconButton>
          <img src={mosymLogo} alt='logo' className={classes.mosymLogo} />
          <Typography
            variant='h5'
            noWrap
            color='textPrimary'
            className={classes.pathName}
          >
            {pathName(currentPath)}
          </Typography>
        </Grid>
        <Grid item xs={2} md={6} className={classes.flexEnd}>
          <Grid container alignItems='center' justify='flex-end'>
            <ToolTip title={'Help'} placement='bottom-end' arrow>
              <IconButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                classes={{
                  root: classes.buttonHover,
                }}
                className={classes.rightList}
                component={Link}
                to={`/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/help`}
              >
                <HelpIcon fill='#313645' />
              </IconButton>
            </ToolTip>

            <ToolTip title={'Settings'} placement='bottom-end' arrow>
              <IconButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                classes={{
                  root: classes.buttonHover,
                }}
                className={classes.rightList}
                component={Link}
                to={`/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/settings`}
              >
                <SettingsIcon />
              </IconButton>
            </ToolTip>

            <ToolTip title={'Switch Ecosystem'} placement='bottom-end' arrow>
              <IconButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                classes={{
                  root: classes.buttonHover,
                }}
                className={classes.rightList}
                component={Link}
                to={`/switch-ecosystem?return_url=${location.pathname}`}
              >
                {/* <OrgIcon fill='#313645' /> */}
                <img src={OrgIcon} alt='org' />
              </IconButton>
            </ToolTip>
            {orgLogo && (
              <div
                style={{ height: 64, width: 150 }}
                className={classes.rightList}
              >
                <img
                  src={orgLogo}
                  alt='org_logog'
                  style={{ height: '100%', width: '100%' }}
                />
              </div>
            )}
            <IconButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              classes={{
                root: classes.buttonHover,
              }}
              onClick={handleOpenMenu}
            >
              <Avatar alt={userName} src={userAvatar} />
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              id='account-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
            >
              <MenuItem
                component={Link}
                onClick={handleClose}
                to={`/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/userProfile`}
              >
                My Account &amp; Profile
              </MenuItem>
              <MenuItem
                className={classes.accountMenu}
                component={Link}
                onClick={handleClose}
                to={`/switch-ecosystem?return_url=${location.pathname}`}
              >
                Switch Ecosystem
              </MenuItem>
              <MenuItem
                className={classes.accountMenu}
                component={Link}
                to={`/${path[1]}/${path[2]}/${path[3]}/${path[4]}/${path[5]}/settings`}
              >
                Settings
              </MenuItem>
              <MenuItem className={classes.accountMenu}>Help</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default Header;
