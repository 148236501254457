import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import StyledButton from '../../../common/models/Button';
import TextField from '@material-ui/core/TextField';
import { isValidEmail } from '../../../common/helperFunctions/helper';
import { resetPassword } from '../../../../containers/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MainCards from '../../../common//MainCards';

const useStyles = makeStyles({
  header: {
    textAlign: 'center',
  },
  text: {
    fontWeight: '500',
    textAlign: 'center',
  },
  btns: {
    textAlign: 'center',
  },
});
const ResetPasswordCard = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [disableResetLink, setDisableResetLink] = useState(false);
  const dispatch = useDispatch();

  let resetUserPassword = useSelector((state) => state.user.resetUserPassword);
  useEffect(() => {
    setErrors({});
    if (resetUserPassword !== undefined) {
      if (resetUserPassword.status === true) {
        setEmailSent(true);
      } else if (resetUserPassword.status === false) {
        setErrors({ emailError: resetUserPassword.errorDescription });
        setDisableResetLink(false);
      }
    }
  }, [resetUserPassword, emailSent]);
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setErrors({});
  };
  const getResetLink = (event) => {
    event.preventDefault();
    if (isValidEmail(email) === true) {
      dispatch(resetPassword({ email }));
      setDisableResetLink(true);
    } else {
      setErrors({ email: 'Please enter valid email address' });
    }
  };
  return (
    <MainCards>
      <CssBaseline />

      {emailSent === false ? (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} className={classes.header}>
              <Typography
                variant='h4'
                style={{
                  fontWeight: '500',
                  textAlign: 'center',
                }}
              >
                Reset Password
              </Typography>
              <Typography style={{ fontSize: '1rem', padding: '0.75rem 0' }}>
                Enter your login email address below and we will get you sorted
                with your password
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify='center'>
            {errors.emailError && (
              <Grid item xs={12} sm={10} md={9}>
                <br />
                <Typography
                  variant='h6'
                  style={{
                    border: '1px solid #FF0000',
                    backgroundColor: '#FFF0F0',
                    opacity: '1',
                    textAlign: 'center',
                    padding: '0.8rem',
                    fontSize: '0.875rem',
                  }}
                >
                  {errors.emailError}
                </Typography>
              </Grid>
            )}
          </Grid>

          <form id='resetPassword' onSubmit={getResetLink}>
            <Grid container justify='center'>
              <Grid item xs={12} sm={10} md={9}>
                <br />
                <TextField
                  fullWidth
                  variant='outlined'
                  size='small'
                  type='email'
                  autoComplete='off'
                  name='email'
                  id='email'
                  onChange={handleChangeEmail}
                  value={email}
                  label='Email'
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>

            <Grid container justify='center'>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                style={{
                  textAlign: 'center',
                  padding: '1rem',
                }}
              >
                <br />
                <StyledButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='medium'
                  disabled={disableResetLink}
                >
                  Get Reset Link
                </StyledButton>
              </Grid>
            </Grid>
          </form>
          {/* </Grid> */}
        </React.Fragment>
      ) : (
        <Grid container style={{ boxShadow: 'none' }}>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.header}
            style={{ paddingBottom: '3rem' }}
          >
            <Typography variant='h4' className={classes.text}>
              Email Sent!
            </Typography>
            <Typography style={{ padding: '0.75rem' }}>
              Check your{' '}
              <Typography
                variant='inherit'
                style={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  display: 'inline',
                }}
              >
                {email}
              </Typography>{' '}
              inbox for instructions from us on how to reset your password.
            </Typography>
          </Grid>
          <br />
          <Grid
            item
            xs={12}
            className={classes.btns}
            style={{ paddingBottom: '1rem' }}
          >
            <StyledButton
              component={Link}
              to='/login'
              variant='contained'
              color='primary'
              size='medium'
            >
              Back to Sign In
            </StyledButton>
          </Grid>
        </Grid>
      )}
    </MainCards>
  );
};

export default ResetPasswordCard;
