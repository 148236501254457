import axios from 'axios';
import { smlUrl } from '../../../../../utils/config';

import { SCREENS_ACCESS, FEATURES_ACCESS } from '../types';

export const getScreensAccess = (data) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/getScreensAccess`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: SCREENS_ACCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getFeaturesAccess = (screenId, screenName) => (dispatch) => {
  axios
    .get(`${smlUrl}/user/getFeaturesAccess/${screenId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: FEATURES_ACCESS,
          payload: res.data.data,
          screenName,
        });
      }
    })
    .catch((err) => console.log(err));
};